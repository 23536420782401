<template>
  <div class="contact">
    <ContactHeader />
    <ContactForm />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import ContactHeader from "@/components/contact/ContactHeader.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
export default {
  components: {
    ContactHeader,
    ContactForm,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Contactez-nous",
      description: [
        {
          content:
            "Prenez contact avec Malaweb pour concrétiser vos projets digitaux. Notre équipe experte est prête à vous guider dans la réalisation de vos ambitions en ligne. Que ce soit pour discuter de vos besoins en création web, en développement d'applications, en stratégie SEO ou en marketing digital, nous sommes là pour vous aider.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/contact",
        },
      ],
    });
  },
};
</script>
