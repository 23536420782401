<template>
  <div class="container-fluid register">
    <div class="row py-1">
      <div class="col-md-3 register-left">
        <img
          src="@/assets/brand/logo-round.webp"
          alt="logo-malaweb-fox"
          class="brand_logo"
        />
        <h1 class="mb-5">Mala<span>web</span></h1>
      </div>
      <div class="col-md-9 register-right">
        <div class="tab-content container-fluid" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <h3 class="register-heading">Bienvenue</h3>
            <form @submit.prevent="submit">
              <div class="row register-form">
                <div class="col-md-6">
                  <div class="form-group mt-5">
                    <label for="lastname">Présentez vous:</label>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Nom"
                      v-model="form.lastName"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Prénom"
                      v-model="form.firstName"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="date"
                      name="birthday"
                      placeholder="Date de naissance"
                      v-model="form.birthday"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      v-model="form.email"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group row mt-5">
                    <label for="pseudo">Profile utilisateur:</label>
                    <input
                      type="text"
                      name="pseudo"
                      placeholder="Pseudo"
                      v-model="form.pseudo"
                    />
                  </div>
                  <div class="form-group row">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      v-model="form.password"
                    />
                  </div>
                  <div class="form-group row">
                    <input
                      type="password"
                      name="verifpswd"
                      placeholder="Password verification"
                      v-model="verifpswd"
                    />
                  </div>
                </div>
                <div class="container">
                  <button type="submit" class="btnRegister mt-5">
                    S'inscrire
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      form: {
        lastName: "",
        firstName: "",
        birthday: "",
        email: "",
        pseudo: "",
        password: "",
        verifpswd: "",
      },
      showError: false,
    };
  },
  methods: {
    ...mapActions(["Register"]),
    async submit() {
      try {
        await this.Register(this.form);
        this.$router.push("/home");
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  background-color: $background;
  color: $color-text;
  margin-bottom: 80px;
  & input {
    width: 100%;
    padding-left: 10px;
    margin: 5px 0px 20px 0px;
    border: 0.2px $color-primary solid;
    border-radius: 20px;
  }
  & h1 {
    color: $color-secondary;
    & span {
      color: $color-primary;
    }
    &:hover {
      color: $color-primary;
      & span {
        color: $color-secondary;
      }
    }
  }
}
.register-left {
  text-align: center;
  color: #fff;
  margin-top: 6%;
}
.register-left input {
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  margin-top: 30%;
  margin-bottom: 3%;
  cursor: pointer;
}
.register-right {
  background: $color-darker;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
  margin: 50px 0px;
}
.register-left img {
  margin-top: 15%;
  margin-bottom: 5%;
  width: 50%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
.register-left p {
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
}
.register .register-form {
  padding: 10%;
  margin-top: 10%;
}
.btnRegister {
  margin-top: 10%;
  border: 0.5px $color-primary outset;
  border-radius: 1.5rem;
  padding: 4px 8px;
  background: $color-darker;
  color: $color-text;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 0.5px $color-primary inset;
    background-color: $color-primary;
    color: $color-text;
    transform: scale(97%);
  }
}
.register .nav-tabs {
  margin-top: 3%;
  border: none;
  background: $color-darker;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}
.register .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover {
  border: none;
}
.register .nav-tabs .nav-link.active {
  width: 100px;
  color: #0062cc;
  border: 2px solid #0062cc;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: $bold-light-primary;
}
</style>
