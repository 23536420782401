<template>
  <div class="container z-depth-1 pt-5 my-5">
    <section class="text-center pb-5">
      <p class="text-center mb-4 pb-2 lead font-weight-bold">
        Ils nous font confiance
      </p>
      <div class="container">
        <div class="row-partner">
          <!--First column-->
          <a href="https://institut-taxi.fr">
            <div class="row-item">
              <img src="@/assets/partners/itti.webp" alt="Logo" />
            </div>
          </a>
          <!--/First column-->
          <!--/Second column-->
          <a href="https://nubetec.fr">
            <div class="row-item">
              <img
                src="@/assets/partners/nubetec.webp"
                alt="Logo"
                width="150"
              />
            </div>
          </a>
          <a href="https://ariane-taxis.com">
            <div class="row-item">
              <img src="@/assets/partners/ariane-taxis.webp" alt="Logo" />
            </div>
          </a>
          <a href="https://asso-team.fr">
            <div class="row-item">
              <img src="@/assets/partners/team.webp" alt="Logo" />
            </div>
          </a>
          <!--Second column-->
          <div class="row-item">
            <img src="@/assets/partners/daadi.webp" alt="Logo" />
          </div>

          <!--Third column-->
          <div class="row-item">
            <img src="@/assets/partners/chez-mouss.webp" alt="Logo" />
          </div>
          <!--/Third column-->

          <!--Fourth column-->
          <!--/Fourth column-->
          <div class="row-item">
            <img src="@/assets/partners/biaggini.webp" alt="Logo" />
          </div>
        </div>
      </div>
      <!--/Grid row-->
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutTrusted",
};
</script>

<style lang="scss" scoped>
.row-partner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  & .row-item {
    margin: 40px 20px;
    & img {
      width: 200px;
    }
  }
}
</style>
