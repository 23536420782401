<template>
  <footer class="footer font-small indigo pt-5">
    <div class="container-fluid">
      <div
        class="row d-flex text-center justify-content-center align-items-center mb-md-0 mb-4 py-3 py-md-4"
      >
        <div class="col my-3 order-2 order-md-1 footer__left">
          <SocialMedia />
        </div>

        <div
          class="col-md-4 col-12 mr-xl-5 mt-2 p-small order-1 order-md-2 footer__center"
        >
          <div class="brand">
            <BrandName />
          </div>
          <div class="d-flex justify-content-center">
            <div class="hr"></div>
          </div>
          <p class="p-3 px-lg-5 text-start">
            L'agence Malaweb spécialisée dans le développement web (<strong
              >site vitrine</strong
            >, blog, <strong>e-commerce</strong>,
            <strong>application mobile</strong>...), vous propose des solutions
            digitales adaptées aux besoins de votre entreprise. En
            <strong>Ile-de-France</strong> mais aussi en
            <strong>Bretagne</strong> dans le <strong>Morbihan</strong> et à
            <strong>Rennes</strong> ainsi que sur <strong>Metz</strong> et le
            <strong>Luxembourg</strong>, rencontrez un membre de notre équipe
            sans plus tardé.
          </p>
        </div>
        <div
          class="col-md-4 col-12 p-small order-3 footer__right d-flex justify-content-center"
        >
          <ContactCard />
        </div>
      </div>
    </div>
    <div class="container col-md-4 col-12">
      <div class="separator"></div>
    </div>
    <!--SECOND-->
    <div class="footer-second container">
      <div class="col-12 col-lg-5 mt-2 p-small">
        <div class="rdv">
          <p class="texte jaapokki">
            Les Villes où nous rencontrer<span class="fake-space">:</span>
          </p>
          <div class="hr-d"></div>
        </div>
        <p class="">
          <strong class="lieu gotham">Ile-de-France: </strong>
          <strong>Paris</strong>, <strong>Versailles</strong>,
          <strong>Sartrouville</strong>, <strong>Saint-Germain-en-Laye</strong>,
          <strong>Mantes-la-jolie</strong>, <strong>Poissy</strong>,
          <strong>Les Mureaux</strong>, <strong>Trappes</strong>,
          <strong>Boulogne-billancourt</strong>, <strong>Nanterre</strong>,
          <strong>Asnières-sur-Seine</strong>, <strong>Colombes</strong>,
          <strong>Courbevoie</strong>, <strong>Rueil-Malmaison</strong>,
          <strong>Issy-les-Moulineaux</strong>,
          <strong>Levallois-Perret</strong>, <strong>Clichy</strong>,
          <strong>Antony</strong>, <strong>Neuilly-sur-seine</strong>,
          <strong>Clamart</strong>, <strong>Gennevilliers</strong>,
          <strong>Suresnes</strong>, <strong>Montrouge</strong>,
          <strong>Meudon</strong>, <strong>Puteaux</strong>.<br />
          <strong class="lieu gotham">Bretagne: </strong>
          <strong>Rennes</strong>, <strong>Lorient</strong> et
          <strong>Vannes</strong><br />
          <strong class="lieu gotham">Moselle:</strong> <strong>Metz</strong>,
          <strong>Montigny-lès-Metz</strong>, <strong>Thionville</strong>,
          <strong>Yutz</strong>, <strong>Hayange</strong>,
          <strong>Woippy</strong> et <strong>Fameck</strong>.<br />
          <strong class="lieu gotham">Luxembourg: </strong>
          <strong>Luxembourg-ville</strong>, <strong>Esch-sur-Alzette</strong>,
          <strong>Differdange</strong>, <strong>Dudelange</strong>,
          <strong>Pétange</strong> et <strong>Bettembourg</strong>.
        </p>
      </div>
      <div class="col-12 col-lg-5 container menu-footer">
        <div class="footer__links">
          <div class="mb-4 mb-md-0">
            <h4 class="text-uppercase">WEB</h4>

            <ul class="list-unstyled mb-0">
              <li>
                <a href="/developpement-web?itemActif=site-vitrine"
                  >Création site vitrine</a
                >
              </li>
              <li>
                <a href="/developpement-web?itemActif=site-blog"
                  >Blog entreprise</a
                >
              </li>
              <li>
                <a href="/developpement-web?itemActif=e-commerce"
                  >Site e-commerce</a
                >
              </li>
              <li>
                <a href="/developpement-web?itemActif=saas">Solutions SaaS</a>
              </li>
            </ul>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="mb-4 mb-md-0">
            <h4 class="text-uppercase">MOBILE</h4>

            <ul class="list-unstyled mb-0">
              <li>
                <a href="/prix-application-mobile">Application ios</a>
              </li>
              <li>
                <a href="/application-mobile">Application android</a>
              </li>
              <li>
                <a href="/prix-pwa-mobile">PWA</a>
              </li>
              <li>
                <a href="/developpement-web?itemActif=saas">ERP/CRM</a>
              </li>
            </ul>
          </div>

          <!--Grid column-->
          <div class="mb-4 mb-md-0">
            <h4 class="text-uppercase">MARKETING</h4>

            <ul class="list-unstyled mb-0">
              <li>
                <a href="/marketing-digital" class="text-white"
                  >Création flyer</a
                >
              </li>
              <li>
                <a href="/prix-marketing-digital">Affiches publicitaires</a>
              </li>
              <li>
                <a href="/marketing-digital">Création logo</a>
              </li>
              <li>
                <a href="/prix-marketing-digital">Email professionnel</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--Grid column-->

    <div
      class="footer__copyright d-flex justify-content-center mt-5 mt-md-3 py-2"
    >
      <p class="my-auto p-small">
        <a href="/mentions">© 2020 Copyright:</a> Powered By
        <a class="px-3 pb-1 brandfoot" href="/contact">Mala<span>web</span></a>
      </p>
    </div>
  </footer>
</template>

<script>
import BrandName from "@/components/partials/BrandName.vue";
import SocialMedia from "@/components/partials/SocialMedia.vue";
import ContactCard from "@/components/partials/ContactCard.vue";
export default {
  name: "FooterBase",
  components: { BrandName, SocialMedia, ContactCard },
};
</script>

<style scoped lang="scss">
.footer {
  background-color: $color-darker;
  color: $color-secondary;
  padding-top: 10px;
  &__center {
    & .brand {
      display: flex;
      justify-content: center;
    }
    & .hr {
      width: 64%;
      height: 2px;
      background: $color-primary;
      overflow: hidden;
      margin-bottom: 5px;
      margin-top: 5px;
    }
    & p {
      font-size: 0.8em;
      padding: 10px;
      margin-bottom: 20px;
      color: rgba(230, 221, 221, 0.904);
      text-align: justify;
    }
  }
  &__right {
    color: $color-text;
    text-align: left;
    margin-top: 15px;
    & i {
      color: $color-primary;
      margin-right: 10px;
    }
    & p {
      font-size: 0.9em;
    }
    & .hr {
      width: 40%;
      height: 2px;
      background: $color-primary;
      overflow: hidden;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  &__copyright {
    background-color: $color-primary;
    color: $color-text;
    font-size: 0.7em;
    & a {
      text-decoration: none;
      color: $color-darker;
    }
    & .brandfoot {
      text-decoration: none;
      background-color: $color-darker;
      color: $color-primary;
      border: 1px $color-primary inset;
      border-radius: 40px;
      padding-top: 1.5px;
      &:hover {
        border: 1px $color-text outset;
        background: $color-primary;
        color: $color-text;
      }
      & span {
        color: $color-text;
      }
    }
  }
  & .footer-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 0px 0px 50px 20px;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    & p {
      font-size: 1em;
    }
    & .rdv {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      & .texte {
        font-size: 2em;
        margin-bottom: 0px;
      }
      & .hr-d {
        width: 80%;
        height: 0.8px;
        background-color: $color-primary;
        opacity: 0.8;
        margin: 0px 0px 20px;
        @media screen and (min-width: 992px) {
          margin: 0px 0px 20px;
          width: 100%;
        }
      }
      & .fake-space {
        margin-left: 5px;
      }
    }
    & p {
      font-size: 0.8em;
      margin-bottom: 60px;
      @media screen and (min-width: 992px) {
        margin-bottom: 0px;
      }
      & .lieu {
        font-family: "jaapokki";
        font-size: 1.2em !important;
        color: $color-primary;
      }
    }
  }
  & .separator {
    width: 60%;
    height: 0.8px;
    background-color: $color-primary;
    opacity: 0.8;
    margin: 20px auto 70px;
    @media screen and (min-width: 992px) {
      margin: 20px auto 60px;
      width: 80%;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 550px) {
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
    }
    & h4 {
      color: $color-primary;
      text-align: center;
      font-size: 1.3em;
    }
    & li {
      margin-bottom: 5px;
      text-align: center;
    }
    & a {
      font-size: 0.9em;
      color: $color-text;
      &:hover {
        color: $color-primary;
      }
    }
  }
}

.footer-icon {
  color: $color-text;
  &:hover {
    color: $color-primary;
  }
}
</style>
