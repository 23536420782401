<template>
  <header class="container-fluid about-header">
    <div class="row about-header__background">
      <h1 class="fall-down">L'Agence Web</h1>
    </div>
  </header>
  <div class="band"></div>
  <TriangleSeparator />
</template>

<script>
import TriangleSeparator from "@/components/partials/TriangleSeparator.vue";
export default {
  name: "AboutHeader",
  components: { TriangleSeparator },
};
</script>

<style lang="scss" scoped>
.about-header {
  &__background {
    background: url("@/assets/pictures/workspace.webp");
    @include background-responsive;
    //width: 100%;
    height: 60vh;
    img {
      position: relative;
    }
    & h1 {
      width: fit-content;
      margin: auto;
      text-align: center;
      color: $color-text;
      background: rgba(53, 51, 51, 0.74);
      border-radius: 50px;
      padding: 1px 20px;
    }
  }
}
.band {
  width: 100%;
  height: 5px;
  background-color: $color-darker;
}
</style>
