<template>
  <div class="container my-5">
    <!--Section: Content-->
    <section class="article-section">
      <!-- Grid row -->
      <div class="row d-flex justify-content-center">
        <!-- Grid column -->
        <div
          class="card-group col-lg-6 col-12 mb-lg-0 mb-4"
          v-for="post in posts"
          :key="post.id"
        >
          <!-- Card -->
          <div class="card hoverable m-4" @click="showPost(post.id)">
            <!-- Card image -->
            <div class="card-title d-flex justify-content-center">
              <p>{{ post.title }}</p>
            </div>
            <img
              class="card-img-top"
              :src="'http://127.0.0.1:8000' + post.img.contentUrl"
              alt="photo-article"
            />
            <!-- Card content -->
            <div class="card-body">
              <!-- Title -->
              <div class="label-card">
                <!--<div>
                  <div v-if="post.updatedAt !== '' && post.updatedAt !== null">
                    <p>modifié le:</p>
                  </div>
                  <div v-else>
                    <p>publié le:</p>
                  </div>
                </div>-->
              </div>
              <div class="card-body__header">
                <div>
                  <p class="card-category text-uppercase font-small">
                    {{ post.category.name }}
                  </p>
                </div>
                <div class="tags">
                  <div class="tag" v-for="tag in post.tags" :key="tag.id">
                    <strong>{{ tag.label }}</strong>
                  </div>
                </div>
              </div>
            </div>

            <!-- Card -->
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
      <!-- Pagination -->
      <nav aria-label="Page-nav my-5">
        <ul class="pagination justify-content-center">
          <li
            class="page-item"
            :class="{ disabled: !Hydraview || !Hydraview['hydra:previous'] }"
          >
            <a class="page-link" @click="loadPage(Hydraview['hydra:previous'])"
              >&laquo;</a
            >
          </li>
          <li
            class="page-item"
            v-for="page in getDisplayedPageNumbers()"
            :key="page"
            :id="page"
            :class="{ active: isCurrentPage(page) }"
            @click="setActivePage(page)"
          >
            <a class="page-link">{{ page }}</a>
          </li>

          <li
            class="page-item"
            :class="{ disabled: !Hydraview || !Hydraview['hydra:next'] }"
          >
            <a class="page-link" @click="loadPage(Hydraview['hydra:next'])"
              >&raquo;</a
            >
          </li>
        </ul>
      </nav>
    </section>
    <!--Section: Content-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";
export default {
  name: "ArticleSelect",
  props: {
    posts: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activePage: 1, // Page active par défaut
    };
  },
  computed: {
    formattedDate() {
      return formatDate(this.post.updatedAt);
    },
    ...mapGetters({
      Posts: "StatePosts",
      User: "StateUser",
      Post: "StatePost",
      Hydraview: "StateHydraview",
    }),
  },
  methods: {
    ...mapActions(["GetPosts", "GetPost"]),
    async showPost(id) {
      try {
        await this.GetPost(id);
        this.$router.push({
          name: "article",
          params: { slug: this.Post.slug },
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    getDisplayedPageNumbers() {
      const currentPage = this.activePage;
      const totalPages = this.getPageNumbers().length;
      const maxDisplayedPages = 5; // Nombre maximal de pages à afficher entre les boutons "Next" et "Previous"

      let startPage, endPage;
      if (totalPages <= maxDisplayedPages) {
        // Si le nombre total de pages est inférieur ou égal à maxDisplayedPages, afficher toutes les pages
        startPage = 1;
        endPage = totalPages;
      } else if (currentPage <= Math.floor(maxDisplayedPages / 2)) {
        // Si la page active est proche du début, afficher les premières maxDisplayedPages pages
        startPage = 1;
        endPage = maxDisplayedPages;
      } else if (
        currentPage >=
        totalPages - Math.floor(maxDisplayedPages / 2)
      ) {
        // Si la page active est proche de la fin, afficher les dernières maxDisplayedPages pages
        startPage = totalPages - maxDisplayedPages + 1;
        endPage = totalPages;
      } else {
        // Sinon, afficher les pages autour de la page active
        startPage = currentPage - Math.floor(maxDisplayedPages / 2);
        endPage = currentPage + Math.floor(maxDisplayedPages / 2);
      }

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, index) => startPage + index
      );
    },

    loadPage(url) {
      const page = url.charAt(url.length - 1);
      const pageNum = parseInt(page);
      this.setActivePage(pageNum);
      this.getDisplayedPageNumbers();
    },
    loadNumPage(num) {
      const url =
        "articles?order%5BpublishedAt%5D=desc&isPublished=true&page=" + num;
      this.GetPosts(url);
    },
    getPageNumbers() {
      const lastPageUrl = this.Hydraview ? this.Hydraview["hydra:last"] : null;
      if (!lastPageUrl) {
        return [];
      }
      const urlParams = new URLSearchParams(lastPageUrl.split("?")[1]);
      const lastPageParam = urlParams.get("page");
      const totalPages = lastPageParam ? parseInt(lastPageParam) : 1;
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    },
    getCurrentPageFromUrl() {
      const currentRoute = this.$route;
      const queryParams = new URLSearchParams(currentRoute.query);
      const currentPage = queryParams.get("page");
      return currentPage ? parseInt(currentPage) : 1;
    },
    setActivePage(page) {
      this.activePage = page;
      this.loadNumPage(page);
    },
    isCurrentPage(page) {
      return this.activePage === page;
    },
  },
};
</script>

<style lang="scss" scoped>
.article-section {
  margin-bottom: 200px;
}
.label-card {
  font-size: 0.6em;
  display: flex;
  justify-content: space-between;
  color: $color-text;
  @media screen and (min-width: 992px) {
    color: $color-text-grey;
  }
}
p {
  margin-bottom: 0.3px;
}
.card {
  border-radius: 10px 10px 2px 2px;
  @include hover-effect-grown;
  transition: 0.5s;
  cursor: pointer;
  background-color: $color-darker;
  @media screen and (min-width: 992px) {
    background-color: $background;
  }
  .card-title {
    background-color: $color-darker;
    color: $color-text;
    & p {
      text-align: center;
    }
    @media screen and (min-width: 992px) {
      background-color: $background;
      color: $color-text-light;
    }
    font-size: 0.9em;
    padding: 5px 10px;
    margin: 0px;
    text-align: center;
    border-radius: 10px 10px 2px 2px;
  }
  .card-img-top {
    height: 200px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: $color-darker;
    border-top: 0.5px $color-primary outset;
    color: $color-text;
    @media screen and (min-width: 992px) {
      background-color: $background;
      border: none;
    }
    padding: 10px;
    &__header {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 0.5px;
      .card-category {
        width: fit-content;
        font-size: 0.7em;
        margin-top: 3px;
        margin-right: 4px;
        margin-bottom: 5px;
        padding: 0.5px 5px;
        border: 0.5px $color-primary outset;
        border-radius: 3px 9px;
        @media screen and (min-width: 992px) {
          background-color: $color-primary;
          color: $color-text;
        }
      }
      .card-date {
        font-size: 0.5em;
        @media screen and (max-width: 992px) {
          @include text-gradient(#f1eeee, #f27316);
        }
        @media screen and (min-width: 992px) {
          color: $color-text-grey;
        }
      }
    }
    .tags {
      font-size: 0.6em;
      display: flex;
      margin-top: 3px;
      .tag {
        display: block;
        margin-right: 4px;
        padding: 0.5px 4px;
        border: 0.5px $color-primary outset;
        border-radius: 9px 3px;
        background-color: $color-lighter;
        color: #f27216d5;
        @media screen and (min-width: 992px) {
          color: $color-text-grey;
        }
      }
    }
  }
  &:hover {
    background-color: $color-darker;
    .label-card {
      color: $color-text;
    }
    .card-body {
      background-color: $color-darker;
      border-top: 1px $color-primary solid;
    }
    .card-category {
      border: 0.5px $color-primary outset;
      background-color: $color-darker;
      color: $color-text;
    }
    .tag strong {
      background-color: $color-lighter;
      color: #f27216d5;
    }
    .card-title,
    .card-date {
      @include text-gradient(#f1eeee, #f27316);
    }
    .card-title {
      & p {
        text-shadow: none;
      }
    }
  }
}
a.page-link {
  color: $color-darker;
}
.pagination {
  margin-top: 80px;
}
/* Style pour les éléments désactivés */
ul.pagination li.page-item.disabled a.page-link {
  color: #999 !important;
  pointer-events: none;
  cursor: not-allowed;
}

/* Style pour les éléments actifs */
ul.pagination li.page-item.active a.page-link {
  background-color: $color-darker;
  color: $color-primary;
  border-color: $color-primary;
}
</style>
