<template>
  <div class="hero-banner__speech col-lg-6 mr-lg-5">
    <h1 class="mt-5">Développement <span>web</span> &<br /></h1>
    <h2 class="hide">Créations de site <span>internet</span></h2>
    <p class="p-1">
      L'<strong>agence Malaweb</strong> spécialisée dans la
      <strong>création de sites internet</strong> et d'<strong
        >applications mobiles</strong
      >, a pour objectif de rendre accessible le web pour tous.
    </p>
    <a href="/about" class="btn btn-slide btn-lg border">en savoir plus</a>
  </div>
</template>

<script>
export default {
  name: "textTyping",
  mounted() {
    setTimeout(() => {
      const h2 = document.querySelector("h2");
      h2.classList.remove("hide");
    }, 3500);
  },
};
</script>

<style lang="scss" scoped>
@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink-caret {
  50% {
    border-color: transparent;
  }
}

@keyframes stop-caret {
  from {
    border-color: $color-primary;
  }
  to {
    border-color: transparent;
  }
}

h1 {
  border-right: 0.1em solid $color-primary;
  width: 16.5em;
  width: 16ch;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 3s steps(30, end), stop-caret 0.5s step-end 2.8s forwards;
}

h2 {
  border-right: 0.1em solid $color-primary;
  width: 16.5em;
  width: 18ch;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 3s steps(30, end),
    0.8s blink-caret 0.5s step-end infinite alternate;
  animation-delay: 3s;
  &.hide {
    opacity: 0;
  }
}

.hero-banner .btn {
  position: relative;
  overflow: hidden;
  z-index: 9;
}
.hero-banner .btn-slide {
  background-color: rgba(0, 0, 0, 0.1);
  color: $color-text;
}
.hero-banner .btn-slide:before {
  content: "";
  background: #f27316;
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
.hero-banner .btn-slide:hover:before {
  width: 100%;
}

.hero-banner__speech {
  @media screen and (min-width: 992px) {
    padding-left: 50px;
  }
  & h1 > span,
  h2 > span {
    color: $color-primary;
  }
  & p {
    text-align: start;
  }
  & a {
    margin: 15px 0px 20px 0px;
  }
}
</style>
