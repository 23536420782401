<template>
  <HeroBlog />
  <!-------------------------------TEST----------------------->
  <ArticleSelect :posts="Posts" />
  <!-------------------------------TEST----------------------->
</template>

<script>
import { useMeta } from "vue-meta";
import ArticleSelect from "@/components/blog/ArticleSelect.vue";
import HeroBlog from "@/components/blog/HeroBlog.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "BlogView",
  components: { ArticleSelect, HeroBlog },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Le blog",
      description: [
        {
          content:
            " Plongez dans nos articles experts pour des conseils pratiques, des tendances actuelles et des stratégies innovantes. Enrichissez votre expertise digitale et restez à la pointe de l'évolution numérique en parcourant notre blog dès aujourd'hui.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/blog",
        },
      ],
    });
  },
  created() {
    // Appel initial à GetPosts() pour récupérer tous les articles du blog
    this.$store.dispatch("GetPostsPublished");
  },

  computed: {
    ...mapGetters({
      Posts: "StatePosts",
      User: "StateUser",
      Post: "StatePost",
    }),
  },
  methods: {
    ...mapActions(["GetPostsPublished", "GetPost"]),
    async showPost(id) {
      try {
        await this.GetPost(id);
        this.$router.push({
          name: "article",
          params: { slug: this.Post.slug },
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
