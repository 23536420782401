<template>
  <router-link to="/home">
    <div class="d-flex">
      <h3 class="jaapokki">Mala<span>web</span></h3>
      <img src="@/assets/brand/logo-round.webp" alt="logo-malaweb" width="50" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: "BrandName",
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 2.4em;
  & span {
    color: $color-text;
    margin-right: 15px;
  }
  &:hover {
    color: $color-text;
    & span {
      color: $color-primary;
    }
  }
}
</style>
