<template>
  <div class="container my-5">
    <section class="text-center">
      <div class="header pb-2 mb-4">
        <h2 class="">Solutions SaaS</h2>

        <p class="text-center mb-5">
          Découvrez nos <strong>solutions SaaS</strong> (<strong
            >Software as a Service</strong
          >). <strong>ERP</strong> et <strong>CRM</strong> peuvent être déployés
          pour accroître votre efficacité et gagner en productivité.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12 mb-4">
          <div class="card pricing-card">
            <div class="pricing-card__title">
              <p class="option">ERP</p>
            </div>

            <div class="card-body">
              <div class="d-flex justify-content-center">
                <div class="breakword">
                  <p>à partir de</p>
                </div>
                <div class="head-body mb-4">
                  <p class="price">6000</p>
                  <span class="euro">€</span>
                </div>
                <div class="b-ttc">
                  <p class="ttc"><span>/</span>ttc</p>
                </div>
              </div>
              <ul>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span>DEVIS :</span> <br />Un
                    <strong
                      >système de planification des ressources
                      d'entreprise</strong
                    >
                    (<strong>ERP</strong>) est une plateforme logicielle
                    intégrée qui permet de gérer et automatiser un large
                    éventail de processus commerciaux, allant de la comptabilité
                    à la gestion des stocks en passant par les ressources
                    humaines. Il offre une vue d'ensemble complète de
                    l'entreprise, optimisant ainsi les opérations et améliorant
                    l'efficacité.
                  </p>
                  <div class="hr"></div>
                </li>
              </ul>
            </div>
            <div class="card-footer">
              <CyberBtn link="/saas-erp" />
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 mb-4">
          <div class="card pricing-card">
            <div class="pricing-card__second">
              <p class="option">CRM</p>
            </div>

            <div class="card-body">
              <div class="d-flex justify-content-center">
                <div class="breakword">
                  <p>à partir de</p>
                </div>
                <div class="head-body mb-4">
                  <p class="price">6000</p>
                  <span class="euro">€</span>
                </div>
                <div class="b-ttc">
                  <p class="ttc"><span>/</span>ttc</p>
                </div>
              </div>
              <ul>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>DEVIS :</span> <br />La
                    <strong>gestion de la relation client</strong>
                    (<strong>CRM</strong>) est une solution conçue pour
                    centraliser, organiser et analyser les interactions avec les
                    clients. Elle permet de suivre les prospects, de gérer les
                    données clients, de personnaliser les communications et
                    d'améliorer la satisfaction client. Le
                    <strong>CRM</strong> vise à renforcer les relations avec les
                    clients pour favoriser la croissance et fidéliser la
                    clientèle.
                  </p>
                  <div class="hr"></div>
                </li>
              </ul>
            </div>
            <div class="card-footer">
              <CyberBtn link="/saas-crm" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <article>
    <section class="container mt-5 mb-2">
      <div class="article-title container">
        <h3>
          Les solutions SaaS, des applications conçues pour répondre aux besoins
          de votre entreprise.
        </h3>
      </div>
      <div class="block-row">
        <div class="block-text col-lg-5 slide-left">
          <TitleColor title="Développement Web" titleColor="#f27316" />
          <p class="text-left mx-auto w-responsive">
            Les systèmes <strong>ERP</strong> centralisent et automatisent les
            processus clés au sein d'une entreprise, favorisant ainsi une
            gestion plus efficiente des ressources. Ces solutions intégrées
            permettent une visibilité accrue sur les opérations financières, la
            gestion des stocks, les achats et la chaîne d'approvisionnement. Un
            <strong>ERP</strong> facilite la coordination interne et l'analyse
            de données, offrant une vue d'ensemble essentielle pour une prise de
            décision stratégique et efficiente.
          </p>
        </div>
        <div class="col-lg-6">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left">
            <img
              src="@/assets/pictures/erp.webp"
              alt="icon-erp"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="container my-5">
      <div class="block-row reverse">
        <div class="block-text slide-right col-lg-5">
          <p>
            Les systèmes <strong>CRM</strong> sont dédiés à la gestion de la
            relation client. Ils permettent d'organiser, automatiser et
            synchroniser les interactions avec les clients et prospects. Un
            <strong>CRM</strong> centralise les informations sur les clients,
            historiques d'achats, préférences, et interactions, permettant ainsi
            de personnaliser les communications et d'améliorer la satisfaction
            client. De plus, il favorise le suivi des ventes et des
            opportunités, renforçant ainsi les relations commerciales et la
            fidélisation.
          </p>
        </div>

        <div class="col-lg-6">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left">
            <img
              src="@/assets/pictures/crm.webp"
              alt="icon-crm"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  </article>
  <CallActionService />
</template>

<script>
import { useMeta } from "vue-meta";
import CyberBtn from "@/components/partials/CyberBtn.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
export default {
  components: { CyberBtn, CallActionService },
  setup() {
    useMeta({
      title: "Solutions SaaS",
      description: [
        {
          content:
            "Améliorez l'efficacité de votre entreprise avec nos solutions SaaS (CRM/ERP). Gérez vos processus métier de manière optimale grâce à nos services de conception et de développement sur mesure.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/developpement-web?itemActif=saas",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.header {
  & h2 {
    width: 100%;
    margin: auto;
    background-color: $color-logo;
    color: $color-primary;
    border: 0.2px outset $color-primary;
    border-radius: 20px;
    padding: 4px 50px;
    margin-bottom: 20px;
  }

  & p {
    color: $color-text;
  }
}
.card {
  min-height: 100%;
  border-radius: 8px 8px 0px 0px;
}
.pricing-card {
  background: $background;
  &__title {
    background: $color-primary;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-lighter;
      text-align: center;
    }
  }
  &__second {
    background: $color-darker;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-primary;
      text-align: center;
    }
  }
  & .card-body {
    padding: 1rem 0rem;

    & .head-body {
      display: flex;
      //justify-content: center;
      align-items: flex-start;
      /*background-color: $color-darker;
      border: 0.3px outset $color-primary;
      border-radius: 10px 0px 10px 0px;
      padding: 5px 50px;
      width: fit-content;*/
      &__orange {
        /*background-color: $color-primary;
        border: 0.3px outset $color-darker;
        border-radius: 0px 10px 0px 10px;
        padding: 5px 50px;
        width: fit-content;*/
        margin: auto;
      }
    }
    & .price {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      font-size: 2em;
      font-weight: 100;
      color: $color-secondary;
      margin: 0px;
    }
    & .euro {
      font-size: 0.6em;
      line-height: -50px;
      color: $color-secondary;
    }
    & .b-ttc {
      position: relative;
      bottom: 1px;
      color: $bold-light;
      & .ttc {
        font-size: 0.8em;
        margin-bottom: 0px;
        line-height: 55px;
        margin-left: -5px;
        & span {
          font-size: 1.3em;
        }
      }
    }
    & ul {
      list-style-type: none;
      & li {
        & p {
          font-size: 1em;
          margin-bottom: 0px;
          color: $bold-light;
          & i {
            padding: 5px;
            border-radius: 100%;
            margin-right: 10px;
          }
          & span {
            color: $color-secondary;
          }
          & .iOrange {
            color: $color-lighter;
            background-color: $color-primary;
          }
          & .iBlack {
            color: $color-primary;
            background-color: $color-darker;
          }
        }
        & .hr {
          width: 80%;
          height: 1px;
          background-color: $bold-light;
          margin: 10px auto;
        }
      }
    }
  }
  & .card-footer {
    background: $color-darker;
    border: none;
    padding: 15px 0px;
  }
}
article {
  & h3 {
    text-align: center;
    color: $color-text;
    text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
    margin: 130px 10px 50px;
    font-size: 2em;
  }
  & .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 40px;
      @media screen and (min-width: 992px) {
        width: 500px;
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
}
.breakword {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 6px;
  & p {
    font-size: 0.7em;
  }
}
</style>
