<template>
  <main id="appMob">
    <NavOption navBorderColor="#800080" iconColorClass="purple" />
    <div class="header">
      <h2>APPLICATION MOBILE</h2>
      <h2>Powered by Malaweb</h2>
    </div>
    <div class="toggle-section">
      <div class="toggle-content">
        <div class="toggle-item active" id="dev-home">
          <div class="container my-5">
            <section class="text-center">
              <div class="header-card pb-2 mb-4">
                <h3 class="">Prix d'une application mobile</h3>

                <p class="text-center mb-5">
                  Découvrez comment notre expertise en
                  <strong>création d'applications mobiles</strong> maximise
                  votre <strong>visibilité en ligne</strong>.
                </p>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-12 mb-4">
                  <div class="card pricing-card">
                    <div class="pricing-card__title">
                      <p class="option">PWA</p>
                    </div>

                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <div class="breakword">
                          <p>à partir de</p>
                        </div>
                        <div class="head-body mb-4">
                          <p class="price">6000</p>
                          <span class="euro">€</span>
                        </div>
                        <div class="b-ttc">
                          <p class="ttc"><span>/</span>ttc</p>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iOrange"
                              aria-hidden="true"
                            ></i>
                            <span>TECHNOLOGIE :</span> <br />Notre approche
                            repose sur la technologie Vue.js
                            (<strong>PWA</strong>) associée à une
                            <strong>API</strong> Symfony pour créer des
                            applications performantes, offrant rapidité et
                            interactivité.
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iOrange"
                              aria-hidden="true"
                            ></i>
                            <span>MODULES :</span> <br />une conception flexible
                            et sur mesure, adaptée à vos besoins : réservations,
                            paiements sécurisés, chat en direct, frais et
                            gestion des interventions... Nos
                            <strong>applications mobiles</strong> vous offrent
                            une expérience personnalisée et évolutive.
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iOrange"
                              aria-hidden="true"
                            ></i>
                            <span>HEBERGEMENT :</span> <br />Déployez votre
                            <strong>application mobile</strong> sur un
                            <strong>serveur web</strong> sécurisé dédié,
                            garantissant la protection des données, et
                            fournissez un accès sécurisé aux utilisateurs pour
                            une utilisation fluide et protégée.
                          </p>
                          <div class="hr"></div>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <CyberBtn link="/prix-pwa-mobile" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-12 mb-4">
                  <div class="card pricing-card">
                    <div class="pricing-card__second">
                      <p class="option">IOS/ANDROÏD</p>
                    </div>

                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <div class="breakword">
                          <p>à partir de</p>
                        </div>
                        <div class="head-body mb-4">
                          <p class="price">8000</p>
                          <span class="euro">€</span>
                        </div>
                        <div class="b-ttc">
                          <p class="ttc"><span>/</span>ttc</p>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iBlack"
                              aria-hidden="true"
                            ></i>
                            <span>TECHNOLOGIE :</span> <br />React Native permet
                            de
                            <strong>développer des applications mobiles</strong>
                            à la fois pour l'<strong>App Store d'Apple</strong>
                            et <strong>Google Play</strong> à partir d'un même
                            code source permettant ainsi de limiter les coûts de
                            développement tout en offrant une expérience
                            optimale à vos utilisateurs
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iBlack"
                              aria-hidden="true"
                            ></i>
                            <span>MODULES :</span> <br />une conception flexible
                            et sur mesure, adaptée à vos besoins : réservations,
                            paiements sécurisés, chat en direct, frais et
                            gestion des interventions... Nos
                            <strong>applications mobiles</strong> vous offrent
                            une expérience personnalisée et évolutive.
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iBlack"
                              aria-hidden="true"
                            ></i>
                            <span>HEBERGEMENT :</span> <br />Hébergement sur
                            serveur dédié garantissant sécurité et
                            disponibilité, avec un accès sécurisé pour les
                            utilisateurs, offrant une expérience fluide et
                            sécurisée.
                          </p>
                          <div class="hr"></div>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <CyberBtn link="/prix-application-mobile" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <article>
            <section class="container mt-5 mb-3">
              <div class="article-title container-lg">
                <h3>
                  Créez des Applications Mobiles Performantes pour iOS et
                  Android
                </h3>
              </div>
              <div class="block-row">
                <div class="block-text col-lg-5 slide-left">
                  <TitleColor
                    title="Développement mobile"
                    titleColor="#f27316"
                  />
                  <p class="text-left mx-auto w-responsive">
                    La conception d'<strong>applications iOS</strong> et
                    <strong>Android</strong> sur mesure est l'une de nos
                    compétences clés. Nous élaborons des
                    <strong>applications mobiles</strong> adaptées, qu'elles
                    soient destinées aux stores publics pour une visibilité
                    accrue ou conçues pour un usage interne au sein
                    d'entreprises.
                  </p>
                  <ArrowBtn />
                </div>
                <div class="col-lg-6">
                  <div
                    class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left"
                  >
                    <img
                      src="@/assets/pictures/app-ios.webp"
                      alt="graphic-economy-energy"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="container mb-5">
              <div class="block-row reverse">
                <div class="block-text slide-right col-lg-5">
                  <TitleColor
                    title="Application mobile"
                    titleColor="hsl(263, 47%, 58%)"
                    bgColor="hsl(263, 47%, 58%)"
                  />
                  <p>
                    Notre objectif est de produire des
                    <strong>applications mobiles</strong> sur mesure qui
                    répondent précisément à vos exigences, tout en offrant une
                    <strong>interface utilisateur intuitive</strong> et des
                    fonctionnalités robustes pour une
                    <strong>expérience utilisateur</strong> optimale. Que ce
                    soit pour le déploiement sur les stores ou pour un usage en
                    entreprise, nos solutions sont adaptées à vos besoins
                    spécifiques.
                  </p>
                </div>

                <div class="col-lg-6">
                  <div
                    class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left"
                  >
                    <img
                      src="@/assets/pictures/app-android.webp"
                      alt="graphic-economy-energy"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </section>
          </article>
          <CallActionService />
        </div>
      </div>
    </div>
    <FooterOption btnBorderColor="purple" iconColorClass="purple" />
  </main>
</template>

<script>
import { useMeta } from "vue-meta";
import CyberBtn from "@/components/partials/CyberBtn.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";

export default {
  name: "AppMobile",
  components: { CyberBtn, CallActionService, FooterOption, NavOption },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "mobile app",
      description: [
        {
          content:
            "Développez des expériences mobiles exceptionnelles avec Malaweb. Des applications iOS/Android sur mesure et des Progressive Web Apps (PWA) innovantes. Transformez vos idées en applications interactives, accessibles sur tous les appareils. Profitez de notre expertise pour offrir à vos utilisateurs une expérience mobile immersive et engageante.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/application-mobile",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
#appMob {
  //background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
  //background: #f12711;
  //background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  background: linear-gradient(to left, #ffc0cb, #800080);
}
.header {
  text-align: center;
  line-height: 0.8;
  margin-bottom: 50px;
  margin-top: 0px;
  padding: 50px 15px 15px;
  & h2:first-of-type {
    font-weight: 200;
    color: $color-text;
  }
  & h2:last-of-type {
    color: $color-darker;
    font-size: 1.5rem;
    opacity: 88%;
  }
  @media (max-width: 400px) {
    h2 {
      font-size: 1.5rem;
    }
  }
}
.toggle-section {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: start;
  flex-wrap: wrap;
  //background: #800080;  /* fallback for old browsers */
  //background: -webkit-linear-gradient(to left, #ffc0cb, #800080);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #ffc0cb, #800080);

  & .toggle-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 337px) {
      flex-direction: row;
      justify-content: space-around;
    }
    & .avatar {
      margin: 10px 0px -10px 0px;
      & img {
        width: 40px;
        @media screen and (min-width: 992px) {
          width: 50px;
        }
      }
    }
    & .card {
      background: linear-gradient(
          to bottom,
          #323232 0%,
          #3f3f3f 40%,
          #1c1c1c 150%
        ),
        linear-gradient(
          to top,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(0, 0, 0, 0.25) 200%
        );
      background-blend-mode: multiply;
      padding: 5px;
      height: 100%;
      min-width: 140px;
      border: none;
      border-radius: 15px;
      @media screen and (min-width: 992px) {
        min-width: 160px;
      }
      /*&:hover {
        background-color: $color-secondary;
      }*/
      & .card-body {
        & .hr {
          margin: 2px auto 5px;
          width: 75%;
          height: 1px;
          background-color: $color-secondary;
        }
        & h4 {
          color: $bold-light;
          font-size: 0.9em;
          margin: auto;
          @media screen and (min-width: 992px) {
            font-size: 1em;
          }
        }
      }
    }
  }
  & .toggle-btn {
    background: linear-gradient(
        to bottom,
        #323232 0%,
        #3f3f3f 40%,
        #1c1c1c 150%
      ),
      linear-gradient(
        to top,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.25) 200%
      );
    background-blend-mode: multiply;
    border: 0.5px $color-darker outset;
    border-radius: 15px;
    cursor: pointer;
    transition: scale 0.3s;
    transition: box-shadow 0.2s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  & .toggle-btn:hover {
    color: #ffffff;
    border: 0.5px $color-darker outset;
  }

  & .toggle-btn.active {
    background-image: linear-gradient(-60deg, #1f1e1ecc 0%, #242121 100%);
    color: #f8f9fa;
    border: 0.5px $color-secondary inset;
    scale: (96%);
    box-shadow: none;
    & .card {
      & h4 {
        color: $color-primary;
      }
      & .hr {
        background-color: $color-lighter;
      }
    }
  }

  & .toggle-content {
    width: 100%;
    margin-top: 20px;
  }

  & .toggle-item {
    display: none;
  }

  & .toggle-item.active {
    display: block;
  }
}

.header-card {
  & h3 {
    width: 100%;
    margin: auto;
    background-color: $color-logo;
    color: $purple;
    border: 0.2px outset $purple;
    border-radius: 20px;
    padding: 4px 50px;
    margin-bottom: 20px;
  }

  & p {
    color: $color-text;
  }
}
.card {
  min-height: 100%;
  border-radius: 8px 8px 0px 0px;
}
.pricing-card {
  background: $background;
  &__title {
    background: $purple;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-lighter;
      text-align: center;
      margin-bottom: 0px;
    }
  }
  &__second {
    background: $color-darker;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $purple;
      text-align: center;
      margin-bottom: 0px;
    }
  }
  & .card-body {
    padding: 1rem 0rem;

    & .head-body {
      display: flex;
      //justify-content: center;
      align-items: flex-start;
      /*background-color: $color-darker;
      border: 0.3px outset $color-primary;
      border-radius: 10px 0px 10px 0px;
      padding: 5px 50px;
      width: fit-content;*/
      &__orange {
        /*background-color: $color-primary;
        border: 0.3px outset $color-darker;
        border-radius: 0px 10px 0px 10px;
        padding: 5px 50px;
        width: fit-content;*/
        margin: auto;
      }
    }
    & .price {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      font-size: 2em;
      font-weight: 100;
      color: $color-secondary;
      margin: 0px;
    }
    & .euro {
      font-size: 0.6em;
      line-height: -50px;
      color: $color-secondary;
    }
    & .b-ttc {
      position: relative;
      bottom: 1px;
      color: $bold-light;
      & .ttc {
        font-size: 0.8em;
        margin-bottom: 0px;
        line-height: 55px;
        margin-left: -5px;
        & span {
          font-size: 1.3em;
        }
      }
    }
    & ul {
      list-style-type: none;
      & li {
        & p {
          font-size: 1em;
          margin-bottom: 0px;
          color: $bold-light;
          & i {
            padding: 5px;
            border-radius: 100%;
            margin-right: 10px;
          }
          & span {
            color: $color-secondary;
          }
          & .iOrange {
            color: $color-lighter;
            background-color: $color-primary;
          }
          & .iBlack {
            color: $color-primary;
            background-color: $color-darker;
          }
        }
        & .hr {
          width: 80%;
          height: 1px;
          background-color: $bold-light;
          margin: 10px auto;
        }
      }
    }
  }
  & .card-footer {
    background: $color-darker;
    border: none;
    padding: 15px 0px;
  }
}
article {
  & h3 {
    text-align: center;
    color: $color-text;
    margin: 130px 10px 50px;
    font-size: 2em;
    text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
  }
  & .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        width: 500px;
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
}
.breakword {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 6px;
  font-size: 0.5em;
}
</style>
