<template>
  <div class="triangle"></div>
</template>

<script>
export default {
  name: "TriangleSeparator",
};
</script>

<style lang="scss" scoped>
.triangle {
  position: relative;
  background: $color-darker;
  height: 0;
}

.triangle::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 65px 65px 0 65px;
  border-color: $color-darker transparent transparent transparent;
  left: 250px;
  transform: translateX(-50%) translateY(100%);
  @media screen and (max-width: 992px) {
    left: 50%;
  }
}
</style>
