<template>
  <section class="hero-banner container-fluid">
    <div class="flex px-xl-5">
      <div
        class="hero-banner__img col-lg-6 order-lg-2 d-flex justify-content-center"
      >
        <img
          src="@/assets/brand/header.webp"
          alt="logo-mala-web"
          class="delta"
          width="500"
        />
      </div>
      <TextTyping />
    </div>
  </section>
  <TriangleSeparator />
</template>

<script>
import TriangleSeparator from "@/components/partials/TriangleSeparator.vue";
import TextTyping from "@/components/partials/TextTyping.vue";
export default {
  name: "HomeHeader",
  components: { TriangleSeparator, TextTyping },
};
</script>

<style lang="scss" scoped>
.hero-banner {
  @include background-svg;
  color: $color-text;
  padding: 40px 20px;
  @media screen and (min-width: 992px) {
    padding: 0px 0px 70px 20px;
  }
  & .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    @media screen and (min-width: 992px) {
      display: flex;
      flex-direction: row;
    }
  }
  &__img {
    @include image-fade-in(2s);
    & img {
      border-radius: 50%;
      @media screen and (max-width: 1245px) {
        width: 100%;
      }
    }
  }
}
</style>
