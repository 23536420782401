<template>
  <div id="sticky-top" class="container-fluid sticky-top">
    <div class="row menu">
      <nav class="col navbar navbar-expand-lg navbar-dark menu__nav">
        <a href="/home" class="navbar-brand brand-name d-flex">
          <img
            src="@/assets/brand/logo-round.webp"
            alt="logo web smart solution"
            width="60"
            class="mx-2"
          />
          <p class="jaapokki my-auto">
            Mala<span class="menu__span">web</span>
          </p>
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          id="buttonTogling"
          title="Togling"
        >
          <!----<span class="navbar-toggler-icon"></span>-->
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div id="navbarContent" class="collapse navbar-collapse mx-lg-5">
          <ul class="navbar nav ms-auto ul mt-3 text-justify">
            <li class="nav-item">
              <a id="home" class="nav-link scroll-link" href="/home">Accueil</a>
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li class="nav-item">
              <a id="about" class="nav-link scroll-link" href="/about"
                >Agence</a
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li class="nav-item">
              <a id="services" class="nav-link scroll-link" href="/services"
                >Services</a
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li class="nav-item">
              <a id="contact" class="nav-link scroll-link" href="/contact"
                >Contact</a
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li class="nav-item">
              <a id="blog" class="nav-link scroll-link" href="/blog"
                >Malablog</a
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>
          </ul>
          <div class="flipfone mt-4 mb-2">
            <FlipPhone />
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import FlipPhone from "@/components/partials/FlipPhone.vue";

export default {
  name: "NavBase",
  components: { FlipPhone },
  methods: {
    setActiveLink() {
      const navLinks = document.querySelectorAll(".ul a");

      navLinks.forEach((link) => {
        link.classList.remove("link-active");
        if (link.getAttribute("id") === window.location.pathname.slice(1)) {
          link.classList.add("link-active");
        }
      });
    },
  },
  mounted() {
    this.setActiveLink(); // Appeler la méthode lors du chargement de la page
  },
};
</script>

<style lang="scss" scoped>
.menu {
  padding: 10px;
  background: rgb(31, 30, 30);
  @media screen and (min-width: 992px) {
    padding: 0px;
  }
  & .brand-name {
    @media screen and (min-width: 992px) {
      margin: 0px 50px;
    }
  }
  & .brand-name:hover {
    & p {
      color: $color-text;
    }
    & span {
      color: $color-primary;
    }
  }
  & ul {
    margin-right: 30px;
  }
  &__span {
    color: $color-text;
  }
  & .nav-item {
    font-size: 0.8em;
    padding: 5px 0px;
    @media screen and (max-width: 768px) {
      margin: 2px;
    }
  }
  &__nav {
    margin-bottom: 0px;
    & a:not(.brand-name) {
      padding: 0px 5px;
      color: $color-text;
      text-decoration: none;
      &:hover {
        text-decoration: underline $color-primary;
        text-underline-offset: 4px;
      }
      &.link-active {
        color: $color-primary;
      }
    }
  }
}
#navbarContent {
  width: fit-content;
}
.navbar-toggler {
  color: $color-darker;
  border: none;
}
.phone {
  border: 1px outset $color-primary;
  border-radius: 9%;
  font-size: 0.6em;
  margin-left: 20px;
  padding: 3px;
  & a {
    color: $color-primary;
    & a:hover {
      color: $color-text;
    }
  }
}

.ul {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
  & li {
    & a {
      font-size: 2em;
      @media screen and (min-width: 992px) {
        font-size: 1.1em;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .s-100 {
    height: 100vw;
  }
}
.active {
  position: relative;
  width: 30px;
  height: 20px;
  left: 16%;
  top: -58px;
  background-color: $color-primary;
  transform: rotate(45deg);
}
.navbar-dark .navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  margin: 6px auto;
  background: #fff;
  transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.navbar-dark .navbar-toggler:hover span,
.navbar-dark .navbar-toggler:focus span {
  background: #ccc;
}

/* rotate first span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(6px, 6px);
}

/* hide second span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}

/* rotate third span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:last-of-type {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-dark .navbar-toggler[aria-expanded="false"] span {
  transform: none;
  opacity: 1;
}

.navbar-dark .navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  margin: 6px auto;
  background: #fff;
  transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}

/* rotate first span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(6px, 6px);
}

/* hide second span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}

/* rotate third span */
.navbar-dark .navbar-toggler[aria-expanded="true"] span:last-of-type {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-dark .navbar-toggler[aria-expanded="false"] span {
  transform: none;
  opacity: 1;
}
</style>
