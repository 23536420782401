<template>
  <div class="about">
    <AboutHeader />
    <AboutTech />
    <AboutService />
    <AboutTrusted />
    <AboutTeam />
    <AboutCallAction />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import AboutHeader from "@/components/about/AboutHeader.vue";
import AboutTech from "@/components/about/AboutTech.vue";
import AboutService from "@/components/about/AboutService.vue";
import AboutTrusted from "@/components/about/AboutTrusted";
import AboutTeam from "@/components/about/AboutTeam.vue";
import AboutCallAction from "@/components/about/AboutCallAction.vue";
export default {
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  components: {
    AboutHeader,
    AboutTech,
    AboutService,
    AboutTrusted,
    AboutTeam,
    AboutCallAction,
  },
  setup() {
    useMeta({
      title: "Agence digitale",
      description: [
        {
          content:
            "Malaweb, Une agence web dédiée à l'innovation digitale. Plongez dans notre histoire, notre passion pour la création web, le développement d'applications, le référencement SEO et bien plus. Rencontrez notre équipe passionnée, prête à transformer vos idées en solutions numériques de pointe.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/about",
        },
      ],
    });
  },
};
</script>
