<template>
  <div class="container pt-lg-5 my-5 z-depth-1">
    <section class="p-md-3 mx-md-5 text-center">
      <h2 class="text-center mx-auto font-weight-bold mb-5 pb-5">
        La Mala Team
      </h2>
      <div class="row">
        <div class="col-12 mb-4 fall-down">
          <div class="p-4">
            <div
              class="avatar w-100 white d-flex justify-content-center align-items-center"
            >
              <img
                src="@/assets/malateam/malaboss.gif"
                class="img-fluid rounded-circle z-depth-1"
                alt="member-team"
                width="250px"
              />
            </div>
            <div class="text-center mt-3">
              <h6 class="font-weight-bold pt-2">Le MalaBoss</h6>
              <p class="text-muted text-center">
                <small><i>Celui que l'on ne nomme pas.</i></small>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 mb-4 slide-left">
          <div class="p-4">
            <div
              class="avatar w-100 white d-flex justify-content-center align-items-center"
            >
              <img
                src="@/assets/malateam/marty.jpg"
                class="img-fluid rounded-circle z-depth-1"
                alt="member-team-martin"
                width="250px"
              />
            </div>
            <div class="text-center mt-3">
              <h6 class="font-weight-bold pt-2">Martin Aubertin</h6>
              <p class="text-muted text-center">
                <small
                  ><i>Smarty<br /></i
                ></small>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 mb-4 fall-down">
          <div class="p-4">
            <div
              class="avatar w-100 white d-flex justify-content-center align-items-center"
            >
              <img
                src="@/assets/malateam/hood.jpeg"
                alt="member-team-aurelien"
                class="img-fluid rounded-circle z-depth-1"
                width="250px"
              />
            </div>
            <div class="text-center mt-3">
              <h6 class="font-weight-bold pt-2">Aurélien Boudon</h6>
              <p class="text-muted text-center">
                <small
                  ><i>Hood<br /></i
                ></small>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 mb-4 image-fade-in">
          <div class="p-4">
            <div
              class="avatar w-100 white d-flex justify-content-center align-items-center"
            >
              <img
                src="@/assets/malateam/hicham.jpg"
                alt="member-team-hicham"
                class="img-fluid rounded-circle z-depth-1"
                width="250px"
              />
            </div>
            <div class="text-center mt-3">
              <h6 class="font-weight-bold pt-2">Hicham Fakhreddine</h6>
              <p class="text-muted text-center">
                <small
                  ><i>Soorisaw<br /></i
                ></small>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 mb-4 slide-right">
          <div class="p-4">
            <div
              class="avatar w-100 white d-flex justify-content-center align-items-center"
            >
              <img
                src="@/assets/malateam/enzo.jpg"
                alt="member-team-enzo"
                class="img-fluid rounded-circle z-depth-1"
                width="250px"
              />
            </div>
            <div class="text-center mx-auto mt-3">
              <h6 class="font-weight-bold pt-2">Enzo Franschescini</h6>
              <p class="text-muted text-center">
                <small><i>Bon gamin</i></small>
              </p>
            </div>
          </div>
        </div>
        <!---<div class="col-lg-6 col-md-4 col-sm-6 mb-4 slide-left">
          <div class="p-4">
            <div
              class="avatar w-100 white d-flex justify-content-center align-items-center"
            >
              <img
                src="https://mdbootstrap.com/img/Photos/Avatars/img%20(5).jpg"
                class="img-fluid rounded-circle z-depth-1"
                width="250px"
              />
            </div>
            <div class="text-center mt-3">
              <h6 class="font-weight-bold pt-2">Joanna Thompson</h6>
              <p class="text-muted">
                <small
                  ><i
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit.</i
                  ></small
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-4 col-sm-6 mb-4 slide-right">
          <div class="p-4">
            <div
              class="avatar w-100 white d-flex justify-content-center align-items-center"
            >
              <img
                src="https://mdbootstrap.com/img/Photos/Avatars/img%20(4).jpg"
                class="img-fluid rounded-circle z-depth-1"
                width="250px"
              />
            </div>
            <div class="text-center mt-3">
              <h6 class="font-weight-bold pt-2">Veronica Smith</h6>
              <p class="text-muted">
                <small
                  ><i
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit.</i
                  ></small
                >
              </p>
            </div>
          </div>
        </div>-->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutTeam",
};
</script>
