<template>
  <div class="container my-5">
    <section class="text-center">
      <div class="header pb-2 mb-4">
        <h2 class="">Prix site e-commerce</h2>

        <p class="text-center mb-5">
          <strong>Créer un site e-commerce</strong>, et
          <strong>générer de nouveaux revenus</strong> grâce aux
          <strong>ventes en ligne</strong>.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12 mb-4">
          <div class="card pricing-card">
            <div class="pricing-card__title">
              <p class="option">BASIC</p>
            </div>

            <div class="card-body">
              <div class="d-flex justify-content-center">
                <div class="head-body mb-4">
                  <p class="price">4500</p>
                  <span class="euro">€</span>
                </div>
                <div class="b-ttc">
                  <p class="ttc"><span>/</span>ttc</p>
                </div>
              </div>
              <ul>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span><strong>BRANDING</strong> :</span> <br />Création
                    minutieuse de l'identité visuelle de votre marque avec le
                    développement du nom et du <strong>logo</strong> pour
                    véhiculer au mieux vos valeurs et votre image de marque.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span>TECHNOLOGIE :</span> <br />Utilisation avancée de
                    <strong>WordPress</strong>, accompagné de
                    <strong>WooCommerce</strong> ou
                    <strong>Shopify</strong> pour une expérience
                    <strong>e-commerce</strong> fluide et adaptée à vos besoins
                    spécifiques.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span>MODULES</span> : <br />Intégration d'un module
                    <strong>e-shop</strong> complet incluant la zone
                    d'administration, les connexions sécurisées, les options de
                    paiement variées, les statistiques détaillées et bien
                    d'autres fonctionnalités pour une gestion optimale de
                    <strong>votre boutique en ligne</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    HEBERGEMENT : <br />En plus des frais liés au
                    <strong>serveur web</strong>, veuillez noter que
                    l'utilisation de l'une des plateformes telles que
                    <strong>Shopify</strong> ou
                    <strong>WooCommerce</strong> nécessitera des frais mensuels
                    distincts correspondants à l'abonnement spécifique choisi.
                    Ces frais peuvent varier en fonction des fonctionnalités et
                    des besoins de votre <strong>boutique en ligne</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    <strong>SEO</strong> : <br />L'<strong
                      >optimisation SEO</strong
                    >
                    est intégrée pour améliorer la
                    <strong
                      >visibilité de votre boutique en ligne sur les moteurs de
                      recherche</strong
                    >, favorisant ainsi son positionnement.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    <strong>SEA</strong> : <br />Des
                    <strong>campagnes google</strong>
                    <strong> SEA</strong> (<strong
                      >Search Engine Advertising</strong
                    >) pour
                    <strong
                      >accroître rapidement la visibilité de votre boutique en
                      ligne</strong
                    >, <strong>attirer de nouveaux clients</strong> et
                    <strong>augmenter les ventes de votre e-commerce</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
              </ul>
            </div>
            <div class="card-footer">
              <CyberBtn link="/prix-site-e-commerce-wordpress" />
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 mb-4">
          <div class="card pricing-card">
            <div class="pricing-card__second">
              <p class="option">PRO</p>
            </div>

            <div class="card-body">
              <div class="d-flex justify-content-center">
                <div class="head-body mb-4">
                  <p class="price">6500</p>
                  <span class="euro">€</span>
                </div>
                <div class="b-ttc">
                  <p class="ttc"><span>/</span>ttc</p>
                </div>
              </div>
              <ul>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span><strong>BRANDING</strong> :</span> <br />
                    De la création du nom à la réalisation du
                    <strong>logo</strong>, chaque élément est pensé pour
                    refléter votre marque avec originalité et pertinence. Notre
                    objectif est de développer une identité visuelle qui capte
                    l'essence même de votre entreprise et attire votre audience
                    cible de manière percutante.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>TECHNOLOGIE :</span> <br />La combinaison de Vue.js et
                    Symfony 6 offre une
                    <strong>expérience utilisateur</strong> fluide et sécurisée.
                    Cette alliance permet une plateforme
                    <strong>e-commerce</strong>
                    performante et évolutive.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>MODULES :</span> <br />Nous intégrons un module
                    <strong>e-commerce</strong> complet. Celui-ci comprend un
                    tableau de bord administratif intuitif, des fonctionnalités
                    sécurisées pour les utilisateurs finaux, une variété de
                    moyens de paiement et des outils d'analyse avancés pour vous
                    aider à comprendre et
                    <strong
                      >optimiser les performances de votre boutique en
                      ligne</strong
                    >.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>HEBERGEMENT :</span> <br />
                    Profitez d'une année entière d'<strong
                      >hébergement web gratuit</strong
                    >
                    sur un serveur performant. Cela vous permettra de vous
                    concentrer sur le développement de votre activité sans vous
                    soucier de la fiabilité et de la rapidité de votre
                    <strong>application web</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span><strong>SEO</strong> :</span> <br />Notre équipe
                    dédiée à l'<strong
                      >optimisation sur les moteurs de recherche</strong
                    >
                    travaillera à
                    <strong
                      >améliorer la visibilité de votre application web</strong
                    >, renforçant ainsi sa présence et sa
                    <strong
                      >position dans les résultats des moteurs de
                      recherche</strong
                    >.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span><strong>SEA</strong> :</span> <br />Des
                    <strong>campagnes publicitaires google</strong> efficaces
                    sont mises en place pour étendre votre audience. Nous visons
                    à accroître votre visibilité et à
                    <strong
                      >attirer un trafic qualifié vers votre boutique en
                      ligne</strong
                    >.
                  </p>
                  <div class="hr"></div>
                </li>
              </ul>
            </div>
            <div class="card-footer">
              <CyberBtn link="/prix-site-e-commerce" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <article>
    <section class="container mt-5 mb-2">
      <div class="article-title container-lg mb-5">
        <h3>Booster vos ventes en ligne grâce à nos solutions e-commerce.</h3>
      </div>
      <div class="block-row">
        <div class="block-text col-lg-5 slide-left">
          <p class="text-left mx-auto w-responsive">
            Un <strong>site internet</strong> dit <strong>e-commerce</strong> ou
            parfois appelé <strong>eshop</strong> est une véritable
            <strong>boutique en ligne</strong> qui vous permettra de
            <strong>générer de nouveaux revenus</strong> en touchant un public
            plus large grâce à votre
            <strong>présence sur les moteurs de recherche</strong>.
          </p>
        </div>
        <div class="col-lg-6">
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/e-shop.webp"
              alt="screen-look-like-shop"
              class="img-fluid img-mb"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="container mb-5">
      <div class="block-row reverse">
        <div class="block-text slide-right col-lg-5">
          <p>
            Malaweb vous propose plusieurs
            <strong>solutions e-commerce</strong> adapté à votre budget et à vos
            objectifs, avec une <strong>stratégie de référencement</strong> vous
            permettant d'attirer de <strong>nouveaux prospects</strong>.
            <strong>Créer sa boutique en ligne</strong> devient un véritable
            atout pour votre entreprise.
          </p>
        </div>

        <div class="col-lg-6">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left">
            <img
              src="@/assets/pictures/stat-eshop.webp"
              alt="analytic-sales"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  </article>
  <CallActionService />
</template>

<script>
import { useMeta } from "vue-meta";
import CyberBtn from "@/components/partials/CyberBtn.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
export default {
  components: { CyberBtn, CallActionService },
  setup() {
    useMeta({
      title: "Créations sites internet",
      description: [
        {
          content:
            "Optimisez votre présence en ligne avec nos services de conception e-commerce. Offrez une expérience d'achat convaincante, des solutions sécurisées et un design attractif pour maximiser vos ventes.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/developpement-web?itemActif=e-commerce",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.header {
  & h2 {
    width: 100%;
    margin: auto;
    background-color: $color-logo;
    color: $color-primary;
    border: 0.2px outset $color-primary;
    border-radius: 20px;
    padding: 4px 50px;
    margin-bottom: 20px;
  }

  & p {
    color: $color-text;
  }
}
.card {
  min-height: 100%;
  border-radius: 8px 8px 0px 0px;
}
.pricing-card {
  background: $background;
  &__title {
    background: $color-primary;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-lighter;
      text-align: center;
    }
  }
  &__second {
    background: $color-darker;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-primary;
      text-align: center;
    }
  }
  & .card-body {
    padding: 1rem 0rem;

    & .head-body {
      display: flex;
      //justify-content: center;
      align-items: flex-start;
      /*background-color: $color-darker;
      border: 0.3px outset $color-primary;
      border-radius: 10px 0px 10px 0px;
      padding: 5px 50px;
      width: fit-content;*/
      &__orange {
        /*background-color: $color-primary;
        border: 0.3px outset $color-darker;
        border-radius: 0px 10px 0px 10px;
        padding: 5px 50px;
        width: fit-content;*/
        margin: auto;
      }
    }
    & .price {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      font-size: 2em;
      font-weight: 100;
      color: $color-secondary;
      margin: 0px;
    }
    & .euro {
      font-size: 0.6em;
      line-height: -50px;
      color: $color-secondary;
    }
    & .b-ttc {
      position: relative;
      bottom: 1px;
      color: $bold-light;
      & .ttc {
        font-size: 0.8em;
        margin-bottom: 0px;
        line-height: 55px;
        margin-left: -5px;
        & span {
          font-size: 1.3em;
        }
      }
    }
    & ul {
      list-style-type: none;
      & li {
        & p {
          font-size: 1em;
          margin-bottom: 0px;
          color: $bold-light;
          & i {
            padding: 5px;
            border-radius: 100%;
            margin-right: 10px;
          }
          & span {
            color: $color-secondary;
          }
          & .iOrange {
            color: $color-lighter;
            background-color: $color-primary;
          }
          & .iBlack {
            color: $color-primary;
            background-color: $color-darker;
          }
        }
        & .hr {
          width: 80%;
          height: 1px;
          background-color: $bold-light;
          margin: 10px auto;
        }
      }
    }
  }
  & .card-footer {
    background: $color-darker;
    border: none;
    padding: 15px 0px;
  }
}
article {
  & h3 {
    text-align: center;
    color: $color-text;
    margin: 130px 10px 50px;
    font-size: 2em;
    text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
  }
  & .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 40px;
      @media screen and (min-width: 992px) {
        width: 500px;
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & .img-mb {
      margin-bottom: -30px;
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
}
</style>
