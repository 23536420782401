<template>
  <div class="container pt-5 mb-5 z-depth-1">
    <!--Section: Content-->
    <section class="about-tech">
      <!-- Section heading -->
      <h2
        class="text-center font-weight-bold mt-5 pt-5 mb-4 pb-2 image-fade-in"
      >
        Pourquoi on est si génial ?
      </h2>
      <!-- Section description -->
      <p class="text-center lead mx-auto mb-5 image-fade-in">
        On vous aide à réaliser votre <strong>projet numérique</strong> et à
        atteindre vos objectifs en élaborant une
        <strong>stratégie digitale</strong> adaptée à vos besoins.
      </p>

      <!-- Grid row -->
      <div class="row pt-4">
        <!-- Grid column -->
        <div class="col-xl-4">
          <!-- Grid row -->
          <div class="row mb-3 slide-left">
            <!-- Grid column -->
            <div class="col-2 d-flex justify-content-end">
              <i class="fa fa-2x fa-handshake deep-purple-text"></i>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-10 slide-right">
              <h3 class="font-weight-bold mb-3">Méthode agile</h3>
              <p class="">
                Nous plaçons nos partenaires au cœur du processus de
                développement, afin de coller au plus près à vos envies et
                besoins.
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
          <div class="row mb-md-0 mb-3 slide-left">
            <!-- Grid column -->
            <div class="col-2 d-flex justify-content-end">
              <i class="fa fa-2x fa-user-circle deep-purple-text"></i>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-10">
              <h3 class="font-weight-bold mb-3">Ux/Ui</h3>
              <p class="mb-md-0">
                Une <strong>expérience utilisateur</strong> unique pensée pour
                <strong>convertir vos prospects</strong> en client par le biais
                d'interfaces ergonomiques et modernes.
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-xl-4 pb-5 my-4 text-center fall-down">
          <img
            class="img-fluid"
            src="@/assets/pictures/mockup-about.webp"
            alt="smartphone"
            width="200px"
          />
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-xl-4 text-end">
          <!-- Grid row -->
          <div class="row mb-3 slide-right">
            <!-- Grid column -->
            <!-- Grid column -->
            <div class="col-10">
              <h3 class="font-weight-bold mb-3">Technologie</h3>
              <p class="text-end">
                Des <strong>développements web et mobile</strong> sur mesure
                adaptés aux besoins de votre entreprise avec des techniques
                innovantes.
              </p>
            </div>
            <div class="col-2 d-flex justify-content-start">
              <i class="fa fa-2x fa-bolt deep-purple-text"></i>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
          </div>
          <!-- Grid row -->

          <!-- Grid row -->
          <div class="row slide-right">
            <!-- Grid column -->
            <div class="col-10">
              <h3 class="font-weight-bold mb-3">SEO</h3>
              <p class="mb-0 text-end">
                Des <strong>audits SEO</strong> conçus pour comprendre les
                raisons d'un positionnement défaillant et/ou pour identifier les
                opportunités d'amélioration en matière d'optimisation.
              </p>
            </div>
            <div class="col-2 d-flex justify-content-start">
              <i class="fas fa-2x fa-line-chart deep-purple-text"></i>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
  </div>
</template>

<script>
export default {
  name: "AboutTech",
};
</script>

<style lang="scss">
.about-tech {
  padding: 0px 20px 70px;
  & i {
    color: $color-primary;
  }

  & h3 {
    color: $color-text-grey;
  }
  & p {
    color: $bold-light;
  }
}
</style>
