<template>
  <p class="btn-cross" :style="{ 'border-color': color }">{{ text }}</p>
</template>

<script>
export default {
  name: "BtnCross",
  props: ["text", "color"],
};
</script>

<style lang="scss" scoped>
.btn-cross {
  color: $bold-light;
  font-weight: 100;
  background-color: $color-lighter;
  padding: 1px 5px;
  font-size: 0.9em;
  border: 0.3px solid;
  border-radius: 10px;
  @media screen and (min-width: 1025px) {
    visibility: hidden;
  }
}
</style>
