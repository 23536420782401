<template>
  <nav
    class="container-fluid nav-option"
    :style="{ borderColor: navBorderColor }"
  >
    <a :href="navLink" :class="['anchor', iconColorClass]"> {{ navText }}</a>
  </nav>
</template>

<script>
export default {
  name: "NavOption",
  props: {
    navLink: {
      type: String,
      default: "/services", // Default white circle background color
    },
    navText: {
      type: String,
      default: "services", // Default white circle background color
    },
    navBorderColor: {
      type: String,
      default: "#f27316", // Default white circle background color
    },
    iconColorClass: {
      type: String,
      default: "orange", // Default box shadow for the circle
    },
  },
};
</script>

<style scoped lang="scss">
.nav-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & .anchor {
    margin: 10px 10px 8px;
    padding: 8px 12px;
    width: fit-content;
    border-radius: 10%;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    border: 0.1px outset;
    box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  & .cyan {
    color: hsl(180, 62%, 55%);
  }
  & .purple {
    color: hsl(263, 47%, 58%);
  }
  & .orange {
    color: $color-primary;
  }
  & .yellow {
    color: hsl(59, 91%, 65%);
  }
}
</style>
