<template>
  <main id="dev">
    <NavOption />
    <div class="header">
      <h2>DEVELOPPEMENT WEB</h2>
      <h2>Powered by Malaweb</h2>
    </div>
    <div class="toggle-section">
      <!------------------------------------------------------>
      <div class="container my-2">
        <section class="text-center dark-grey-text">
          <div class="row card-group pb-4 toggle-row">
            <div class="col-lg-3 col-6 mb-4">
              <button
                class="toggle-btn"
                data-toggle="site-vitrine"
                @click="toggleItem('site-vitrine')"
              >
                <div class="card testimonial-card">
                  <div class="card-up info-color"></div>

                  <div class="card-body">
                    <div class="hr"></div>

                    <h4>Site<br />vitrine</h4>
                  </div>
                </div>
              </button>
            </div>

            <div class="col-lg-3 col-6 mb-4">
              <button
                class="toggle-btn"
                data-toggle="site-blog"
                @click="toggleItem('site-blog')"
              >
                <div class="card testimonial-card">
                  <div class="card-up info-color"></div>

                  <div class="card-body">
                    <div class="hr"></div>

                    <h4>Site <br />Blog</h4>
                  </div>
                </div>
              </button>
            </div>

            <div class="col-lg-3 col-6 mb-4">
              <button
                class="toggle-btn"
                data-toggle="e-commerce"
                @click="toggleItem('e-commerce')"
              >
                <div class="card testimonial-card">
                  <div class="card-up blue-gradient"></div>

                  <div class="card-body">
                    <div class="hr"></div>

                    <h4>E <br />commerce</h4>
                  </div>
                </div>
              </button>
            </div>

            <div class="col-lg-3 col-6 mb-4">
              <button
                class="toggle-btn"
                data-toggle="saas"
                @click="toggleItem('saas')"
              >
                <div class="card testimonial-card">
                  <div class="card-up indigo"></div>
                  <div class="card-body">
                    <div class="hr"></div>
                    <h4>Solutions <br />SaaS</h4>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </section>
      </div>
      <!------------------------------------------------------>

      <div class="toggle-content">
        <div class="toggle-item active" id="dev-home">
          <DevHome />
        </div>
        <div class="toggle-item" id="site-vitrine">
          <SiteVitrine />
        </div>
        <div class="toggle-item" id="site-blog">
          <SiteBlog />
        </div>
        <div class="toggle-item" id="e-commerce">
          <SiteEcommerce />
        </div>
        <div class="toggle-item" id="saas">
          <SaasSolution />
        </div>
      </div>
    </div>
    <FooterOption />
  </main>
</template>

<script>
import { useMeta } from "vue-meta";
import SiteVitrine from "@/components/services/dev-web/SiteVitrine.vue";
import SiteBlog from "@/components/services/dev-web/SiteBlog.vue";
import SiteEcommerce from "@/components/services/dev-web/SiteEcommerce.vue";
import SaasSolution from "@/components/services/dev-web/SaasSolution.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";
//import CyberBtn from "@/components/partials/CyberBtn.vue";
export default {
  components: {
    SiteVitrine,
    SiteBlog,
    SiteEcommerce,
    SaasSolution,
    FooterOption,
    NavOption,
    //CyberBtn,
  },
  setup() {
    useMeta({
      title: "Développement web",
      description: [
        {
          content:
            "Découvrez notre expertise en développement web sur mesure. De la création de sites vitrine élégants à la conception de blogs informatifs et aux plateformes e-commerce convaincantes, notre agence offre des solutions personnalisées pour booster votre présence en ligne et maximiser votre impact dans le monde digital.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/developpement-web",
        },
      ],
    });
  },
  data() {
    return {
      activeItem: "site-vitrine",
    };
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
  mounted() {
    if (this.$route.query.itemActif) {
      this.activeItem = this.$route.query.itemActif;
      this.toggleItem(this.activeItem);
    } else {
      this.activeItem = "site-vitrine";
    }

    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  methods: {
    toggleItem(id) {
      this.activeItem = id;

      const toggleBtns = document.querySelectorAll(".toggle-btn");
      toggleBtns.forEach((btn) => {
        if (btn.dataset.toggle === id) {
          btn.classList.add("active");
        } else {
          btn.classList.remove("active");
        }
      });

      const toggleItems = document.querySelectorAll(".toggle-item");
      toggleItems.forEach((item) => {
        if (item.id === id) {
          item.classList.add("active");
        } else {
          item.classList.remove("active");
        }
      });

      const isMobile = /Mobi/.test(navigator.userAgent);

      if (isMobile) {
        const targetElement = document.getElementById("item-1_ou"); // Remplacez "your-element-id" par l'ID de l'élément cible
        if (targetElement) {
          const targetPosition = targetElement.offsetTop;
          window.scrollTo({
            top: targetPosition,
            behavior: "smooth",
          });
        }
      } else {
        // Faire défiler l'écran jusqu'à l'ancre spécifiée sur les ordinateurs de bureau et autres appareils
        const anchorElement = document.querySelector("#top-page");
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: "smooth" });
        }
      }

      // Annuler le défilement vers le haut sur les appareils mobiles
      /*if (/Mobi/.test(navigator.userAgent)) {
        setTimeout(() => {
          window.scrollBy({
            top: -40,
            behavior: "smooth",
          });
        }, 100);
      }*/
    },
  },
};
</script>

<style lang="scss" scoped>
#dev {
  //background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
  //background: #f12711;
  //background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  background-image: linear-gradient(to right, #f5af19, #f12711);
}
.header {
  text-align: center;
  line-height: 0.8;
  margin-bottom: 50px;
  margin-top: 0px;
  padding: 50px 15px 15px;
  & h2:first-of-type {
    font-weight: 200;
    color: $color-text;
  }
  & h2:last-of-type {
    color: $color-darker;
    font-size: 1.5rem;
    opacity: 88%;
  }
  @media (max-width: 400px) {
    h2 {
      font-size: 1.5rem;
    }
  }
}
.toggle-section {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: start;
  flex-wrap: wrap;
  //background-image: linear-gradient(to right, #f1fa70 0%, #f27316 100%);
  //background-image: linear-gradient(to right, #f83600 0%, #f9d423 100%);
  background: linear-gradient(to right, #f5af19, #f12711);

  & .toggle-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 337px) {
      flex-direction: row;
      justify-content: space-around;
    }
    & .avatar {
      margin: 10px 0px -10px 0px;
      & img {
        width: 40px;
        @media screen and (min-width: 992px) {
          width: 50px;
        }
      }
    }
    & .card {
      background: linear-gradient(
          to bottom,
          #323232 0%,
          #3f3f3f 40%,
          #1c1c1c 150%
        ),
        linear-gradient(
          to top,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(0, 0, 0, 0.25) 200%
        );
      background-blend-mode: multiply;
      padding: 5px;
      height: 100%;
      min-width: 140px;
      border: none;
      border-radius: 15px;
      @media screen and (min-width: 992px) {
        min-width: 160px;
      }
      /*&:hover {
        background-color: $color-secondary;
      }*/
      & .card-body {
        & .hr {
          margin: 2px auto 5px;
          width: 75%;
          height: 1px;
          background-color: $color-secondary;
        }
        & h4 {
          color: $bold-light;
          font-size: 0.9em;
          margin: auto;
          @media screen and (min-width: 992px) {
            font-size: 1em;
          }
        }
      }
    }
  }
  & .toggle-btn {
    background: linear-gradient(
        to bottom,
        #323232 0%,
        #3f3f3f 40%,
        #1c1c1c 150%
      ),
      linear-gradient(
        to top,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.25) 200%
      );
    background-blend-mode: multiply;
    border: 0.5px $color-darker outset;
    border-radius: 15px;
    cursor: pointer;
    transition: scale 0.3s;
    transition: box-shadow 0.2s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  & .toggle-btn:hover {
    color: #ffffff;
    border: 0.5px $color-darker outset;
  }

  & .toggle-btn.active {
    background-image: linear-gradient(-60deg, #1f1e1ecc 0%, #242121 100%);
    color: #f8f9fa;
    border: 0.5px $color-secondary inset;
    scale: (96%);
    box-shadow: none;
    & .card {
      & h4 {
        color: $color-primary;
      }
      & .hr {
        background-color: $color-lighter;
      }
    }
  }

  & .toggle-content {
    width: 100%;
    margin-top: 0px;
  }

  & .toggle-item {
    display: none;
  }

  & .toggle-item.active {
    display: block;
  }
}
</style>
