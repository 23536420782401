<template>
  <header class="container-fluid services-header px-4">
    <h1 class="fall-down">{{ town.title }}</h1>
    <div class="hr"></div>
    <h2 class="image-fade-in">
      Les étapes de développement de votre projet numérique.
    </h2>
  </header>
  <TriangleSeparator />
  <main class="container-fluid">
    <div class="container pt-5 my-5">
      <!--Section: Content-->
      <section class="dark-grey-text text-center">
        <h2
          class="font-weight-normal text-uppercase font-small grey-text mb-4 fall-down"
        >
          {{ town.town }}
        </h2>
        <!-- Section heading -->
        <h3 class="font-weight-bold black-text mb-4 pb-2 fall-down">
          services
        </h3>
        <hr class="w-header" />
        <div class="container mb-5">
          <p class="text-center mx-auto mb-5">
            Malaweb accompagne votre entreprise dans sa transition vers le monde
            <strong>numérique</strong> en offrant une gamme complète de services
            axés sur la <strong>digitalisation</strong>. Notre expertise en
            <strong>développement web</strong> assure la
            <strong>création de sites web</strong> modernes, d'<strong
              >applications mobile</strong
            >
            intuitives et de solutions
            (<strong>erp</strong>/<strong>crm</strong>) adaptées aux besoin de
            votre entreprise.
          </p>
        </div>
        <!-- Section description -->
        <p class="lead text-muted mx-auto mt-4 mt-lg-5 pt-2 pt-lg-5 my-5">
          Cliquez sur le service web qui vous intéresse, on vous explique tout.
        </p>

        <!--First row-->
        <div class="row fall-down">
          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="#developpement-web"
              @click="scrollTo('developpement-web')"
              class="card hoverable service-card orange"
            >
              <!-- Card content -->
              <div class="card-body">
                <img src="@/assets/icons/web-development.webp" alt="" />
                <h4 class="black-text mb-0">Développement web</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="/application-mobile"
              @click="scrollTo('application-mobile')"
              class="card hoverable service-card purple"
            >
              <!-- Card content -->
              <div class="card-body">
                <img src="@/assets/icons/app-mobile.webp" alt="" />
                <h4 class="black-text mb-0">App mobile</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="#referencement-google"
              @click="scrollTo('referencement-google')"
              class="card hoverable service-card cyan"
            >
              <!-- Card content -->
              <div class="card-body">
                <img src="@/assets/icons/seo.webp" alt="" />
                <h4 class="black-text mb-0">Référencement</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="#marketing-digital"
              @click="scrollTo('marketing-digital')"
              class="card hoverable service-card yellow"
            >
              <!-- Card content -->
              <div class="card-body">
                <img src="@/assets/icons/social-media.webp" alt="" />
                <h4 class="black-text mb-0">Marketing digital</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->
        </div>
        <!--First row-->
      </section>
    </div>

    <div class="container pt-5 my-5">
      <!--Section: Content-->
      <section class="dark-grey-text">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-5">
          <h2 class="text-center font-weight-bold mb-4">
            Prix d'un site internet sur {{ town.town
            }}<span class="span-ml">:</span>
          </h2>
          <p class="text-center mx-au">
            La tarification pour la
            <strong>création d'un site internet à {{ town.town }}</strong> ou
            d'une application mobile varie en fonction de multiples facteurs :
            choix technologiques, nombre de pages, modules,
            <strong>référencement organique (gratuit)</strong>, contenu
            rédactionnel, <strong>campagnes google ads</strong>, et autres
            éléments tels que la <strong>création d'un logo</strong> ou d'une
            <strong>adresse email professionnelle</strong>. Nous nous adaptons à
            vos objectifs et à votre budget pour trouver le meilleur compromis.
          </p>
        </div>
        <div class="block-row pt-lg-5">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor
              title="Trouver un nom pour son entreprise"
              titleColor="#f27316"
            />
            <p class="text-left mx-auto w-responsive">
              <strong>Trouver un nom pour votre entreprise</strong> avant de
              <strong>créer votre site internet</strong> est crucial pour
              définir votre identité visuelle. Il doit être en harmonie avec
              l'image que vous souhaitez véhiculer. Une identité visuelle bien
              définie, contribue à créer une image cohérente et mémorable pour
              votre public cible. Dans cette démarche, notre
              <strong>agence web à {{ town.town }}</strong> vous accompagne non
              seulement dans la
              <strong>création de votre site internet</strong>, mais aussi dans
              la réflexion et la recherche pour trouver le nom parfait qui
              incarnera l'essence même de votre entreprise. Cette approche
              holistique permet d'assurer une cohérence entre votre identité
              visuelle et votre présence en ligne.
            </p>
            <ArrowBtn href="/marketing-digital" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/pictures/branding.webp"
              alt="graphic-economy-energy"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->
    <section class="container my-5 pb-5">
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColor
            title="Création du logo"
            titleColor="hsl(263, 47%, 58%)"
            bgColor="hsl(263, 47%, 58%)"
          />
          <!-- Excerpt -->
          <p>
            Une fois le <strong>nom de votre entreprise trouvé</strong>,
            <strong>définir la charte graphique</strong> est la seconde étape
            (<strong>création du logo</strong>, choix des couleurs, police).
            Préparer en amont de la
            <strong>création de votre site internet</strong> l'ouverture de vos
            <strong>comptes professionnels sur les réseaux sociaux</strong>, la
            <strong>création d'adresses email professionnelles</strong> est
            essentiel pour une <strong>présence en ligne</strong> cohérente.
            Notre <strong>agence web à {{ town.town }}</strong> vous accompagne
            dans cette étape clé, en vous aidant à établir une identité visuelle
            forte et harmonieuse, assurant une transition fluide vers la
            <strong>création de votre site internet</strong> tout en maintenant
            une cohérence totale avec votre image de marque.
          </p>
          <ArrowBtn
            href="/prix-marketing-digital"
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/idea-media.webp"
              alt="graphic-economy-energy"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
    <ContactUsAction />
    <!--DEVWEB-->
    <div class="container pt-5 my-5">
      <!--Section: Content-->
      <section class="dark-grey-text">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-5">
          <h2 id="developpement-web" class="text-center font-weight-bold mb-4">
            {{ town.town }}<span class="span-ml">:</span> Créer un site internet
          </h2>
          <p class="text-center mx-auto mb-5">
            La <strong>conception d'un site web</strong> dépend de vos objectifs
            spécifiques. Un <strong>site vitrine</strong> met en avant votre
            entreprise et ses services, un
            <strong>blog professionnel</strong> permet une stratégie de
            <strong>référencement sur les moteurs de recherche</strong> plus
            efficace, tandis qu'un <strong>site e-commerce</strong> est dédié à
            la <strong>vente en ligne de produits ou services</strong>. Nous
            vous aidons à choisir le type de <strong>site internet</strong> le
            mieux adapté à vos besoins, en prenant en compte vos objectifs
            locaux à <strong>{{ town.town }}</strong> mais également sur
            l'ensemble de votre secteur d'activité et votre public cible.
          </p>
        </div>
        <div class="block-row pt-lg-3">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor title="Créer son site vitrine" titleColor="#f27316" />
            <p class="text-left mx-auto w-responsive">
              Le <strong>site vitrine</strong> de votre entreprise, présente vos
              services ou produits sans intégrer de fonctionnalités de vente.
              <strong>WordPress</strong>, souvent utilisé pour sa facilité
              d'utilisation et sa variété de thèmes adaptés aux
              <strong>sites vitrines</strong>, offre une option conviviale.
              Cependant, pour des besoins spécifiques ou des performances
              accrues, des frameworks plus avancés comme Symfony peuvent être
              envisagés. Nous vous aidons à choisir la meilleure solution, avec
              <strong>WordPress</strong> pour un
              <strong>site vitrine</strong> simple ou avec des frameworks plus
              avancés, afin de répondre précisément à vos besoins de
              <strong>création de site vitrine</strong>.
            </p>
            <ArrowBtn href="/developpement-web?itemActif=site-vitrine" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/pictures/site-wordpress-nrs.webp"
              alt="graphic-economy-energy"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->
    <section class="container my-5 pb-2">
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColor
            title="Un blog pour optimisé votre référencement gratuit (SEO)"
            titleColor="hsl(263, 47%, 58%)"
            bgColor="hsl(263, 47%, 58%)"
          />
          <!-- Excerpt -->
          <p>
            Un <strong>site internet</strong> avec un module
            <strong>blog</strong> intégré est essentiel pour
            <strong>optimiser le référencement naturel</strong> en rédigeant des
            articles ciblés autour de mots-clés spécifiques.
            <strong>WordPress</strong>, connu pour sa facilité d'utilisation,
            est couramment utilisé, mais pour des performances
            <strong>SEO</strong> avancées, des frameworks comme Symfony et
            Vue.js sont également efficaces. Nous vous aidons à
            <strong>créer un blog professionnel</strong> pour
            <strong
              >améliorer votre positionnement sur les moteurs de
              recherche</strong
            >
            et viser la <strong>première page de Google</strong>.
          </p>
          <ArrowBtn
            href="/developpement-web?itemActif=site-blog"
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/blog-seo-nubetec.webp"
              alt="graphic-economy-energy"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
    <section class="container mt-lg-5">
      <div class="block-row pt-lg-2 pb-5 mb-5">
        <!-- Grid column -->
        <div class="block-text col-lg-5 slide-left">
          <!-- Featured image -->

          <!-- Section description -->
          <TitleColor
            title="Créer sa boutique en ligne"
            titleColor="hsl(180, 62%, 55%)"
            bgColor="hsl(180, 62%, 55%)"
          />
          <p class="text-left mx-auto w-responsive">
            Un <strong>site e-commerce</strong> est crucial pour la
            <strong>vente en ligne</strong> et la
            <strong>gestion d'une boutique virtuelle</strong>.
            <strong>WordPress</strong> avec <strong>WooCommerce</strong> ou
            <strong>Shopify</strong> sont souvent privilégiés pour leur facilité
            d'utilisation et leurs fonctionnalités <strong>e-commerce</strong>.
            Cependant, pour des performances optimales, l'utilisation de
            frameworks avancés comme Vue.js et Symfony peut offrir des résultats
            supérieurs en termes de performance et de personnalisation. Nous
            vous accompagnons dans la création d'une
            <strong>boutique en ligne</strong>, avec des solutions adaptées à
            vos objectifs et votre budget.
          </p>
          <ArrowBtn
            href="/developpement-web?itemActif=e-commerce"
            whiteCircleBackground="hsl(180, 62%, 45%)"
            circleBoxShadow="0 0 2px 2px hsl(180, 62%, 45%)"
          />
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-12 col-lg-5 pt-lg-5 img-double slide-right">
          <img
            src="@/assets/pictures/e-commerce-pro.webp"
            alt="graphic-economy-energy"
            class="img-fluid"
          />
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
      <!-- Grid row -->
      <ContactUsAction />
      <!-- Grid row -->
    </section>
    <!--/DEVWEB-->
    <!--SEO/SEA-->
    <div class="container my-5">
      <!--Section: Content-->
      <section class="dark-grey-text">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-lg-5">
          <h2
            id="referencement-google"
            class="text-center font-weight-bold mb-4"
          >
            Boostez votre communication sur {{ town.town }} et sa région grâce à
            une stratégie marketing bien pensée<span class="span-ml">!</span>
          </h2>
          <p class="text-center mx-auto">
            Le <strong>référencement</strong> est une composante essentielle
            pour <strong>améliorer la visibilité en ligne</strong>. Le
            <strong>SEO</strong> (<strong>Search Engine Optimization</strong>)
            se concentre sur l'<strong>optimisation du site internet</strong>
            pour apparaître organiquement dans les résultats de recherche. En
            revanche, le <strong>SEA</strong> (<strong
              >Search Engine Advertising</strong
            >) implique l'utilisation de publicités payantes pour être
            <strong>positionné en haut des pages de résultats</strong>. Notre
            <strong>agence</strong> offre des services complets de
            <strong>référencement</strong>, combinant des
            <strong>stratégies SEO</strong> efficaces pour
            <strong>améliorer la visibilité organique</strong> et des
            <strong>campagnes</strong> <strong>SEA</strong> ciblées pour obtenir
            une visibilité immédiate.
          </p>
        </div>
        <div class="block-row pt-5">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor
              title="Amélioré la position de votre site internet sur les moteurs de recherches"
              titleColor="#f27316"
            />
            <p class="text-left mx-auto w-responsive">
              Le <strong>SEO</strong> est un ensemble de techniques visant à
              <strong>optimiser un site web</strong> pour
              <strong
                >apparaître naturellement dans les résultats de
                recherche</strong
              >. Cela inclut l'optimisation du contenu, des balises, de la
              structure du site et bien plus encore. Notre
              <strong>agence</strong> se spécialise dans la mise en place de
              <strong>stratégies SEO</strong> avancées pour
              <strong
                >améliorer le classement et la visibilité de votre site sur les
                moteurs de recherche</strong
              >, attirant ainsi un trafic qualifié.
            </p>
            <ArrowBtn href="/prix-referencement-seo" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/pictures/blog-seo.webp"
              alt="graphic-economy-energy"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->
    <section class="container my-5 pb-5">
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColor
            title="Les campagnes google Ads"
            titleColor="hsl(263, 47%, 58%)"
            bgColor="hsl(263, 47%, 58%)"
          />
          <!-- Excerpt -->
          <p>
            Le <strong>SEA</strong> consiste à utiliser des
            <strong>annonces payantes</strong> pour
            <strong>apparaître en haut des résultats de recherche</strong>. Cela
            permet d'<strong>obtenir une visibilité immédiate</strong> sur
            {{ town.town }} et sa région en ciblant spécifiquement les
            utilisateurs intéressés par vos produits ou services. Notre
            <strong>agence</strong> conçoit et gère des
            <strong>campagnes SEA</strong> efficaces pour vous aider à atteindre
            vos objectifs de <strong>marketing</strong>, que ce soit pour
            accroître la notoriété de votre marque, générer des leads ou
            <strong>augmenter les ventes en ligne</strong>.
          </p>
          <ArrowBtn
            href="/prix-referencement-sea"
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/sea-header.webp"
              alt="graphic-economy-energy"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
    <ContactUsAction />
    <!--/SEASEO-->
    <!--MARKETING-->
    <div class="container mt-5">
      <!--Section: Content-->
      <section class="dark-grey-text">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-lg-5 mt-5">
          <h2 id="marketing-digital" class="text-center font-weight-bold mb-4">
            {{ town.content }}<span class="span-ml">:</span>
          </h2>
          <p class="text-center mx-auto mb-lg-5">
            La <strong>création d'un site web</strong> implique plusieurs étapes
            dépendant des objectifs et du budget. Cette diversité rend difficile
            l'établissement d'un prix standard sans une analyse détaillée.
            Contactez-nous par téléphone ou pour un rendez-vous sur
            {{ town.town }} afin de discuter de vos besoins spécifiques.
            Ensemble, nous trouverons la solution adaptée à votre projet. Chez
            Malaweb, nous sommes prêts à transformer vos idées en une réalité en
            ligne.
          </p>
        </div>
        <div class="block-row pt-5">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor title="Agence digital Malaweb" titleColor="#f27316" />
            <p class="text-left mx-auto w-responsive">
              Malaweb est une <strong>agence web</strong> dynamique opérant
              principalement en <strong>Bretagne</strong>, en
              <strong>Île-de-France</strong> et dans le
              <strong>Grand Est</strong>. Nous offrons une gamme complète de
              services allant de la
              <strong>création de sites internet</strong> à la
              <strong>conception d'applications mobiles</strong>, d'<strong
                >ERP</strong
              >
              et de <strong>CRM</strong>. Notre expertise s'étend également au
              <strong>référencement SEO</strong> et aux
              <strong>campagnes publicitaires</strong> <strong>SEA</strong>,
              tout en incluant la <strong>création de logos</strong>,
              <strong>flyers</strong>,
              <strong>affiches publicitaires</strong> et
              <strong>adresses email professionnelles</strong>. Notre objectif
              est de fournir des <strong>solutions digitales</strong> de qualité
              à des tarifs compétitifs, offrant ainsi à nos clients une
              <strong>présence en ligne</strong> performante et impactante. Chez
              Malaweb, nous sommes déterminés à accompagner nos clients dans
              leur succès <strong>digital</strong>.
            </p>
            <ArrowBtn href="/home" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/brand/new-logo.webp"
              alt="graphic-economy-energy"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->

    <!--Section: Content-->
    <!--/Marketing-->
    <div class="container service-contact image-fade-in">
      <CallActionService />
    </div>
  </main>
</template>

<script>
import { useMeta } from "vue-meta";
import { toRef, watchEffect } from "vue";
import TriangleSeparator from "@/components/partials/TriangleSeparator.vue";
import ArrowBtn from "@/components/partials/ArrowBtn.vue";
import TitleColor from "@/components/partials/TitleColor.vue";
import ContactUsAction from "@/components/partials/ContactUsAction.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
import { mapGetters } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";

export default {
  components: {
    TriangleSeparator,
    ArrowBtn,
    TitleColor,
    ContactUsAction,
    CallActionService,
  },
  name: "TownRead",
  props: {
    town: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const townProp = toRef(props, "town");

    watchEffect(() => {
      if (townProp.value) {
        const sentenceBegin = "Rencontrez nous au ";
        const sentenceEnd =
          " pour tout vos projets de développement web (création site internet, application mobile) et marketing digital (log, flyers, visuel)";

        const metaDescription =
          sentenceBegin + townProp.value.content + sentenceEnd;

        useMeta({
          title: townProp.value.title,
          meta: [
            {
              name: "description",
              content: metaDescription,
            },
          ],
          link: [
            {
              rel: "canonical",
              href: "https://www.malaweb.fr/ma-ville/" + townProp.value.slug,
            },
          ],
        });
      }
    });

    return {
      townData: townProp, // Utiliser un nom unique pour la clé retournée
    };
  },
  computed: {
    formattedDate() {
      return formatDate(this.town.updatedAt);
    },
    ...mapGetters({
      Towns: "StateTowns",
      User: "StateUser",
      TownDataFromStore: "StateTown", // Renommer la clé pour éviter la duplication
    }),
  },
  methods: {
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth", // Défilement fluide
        });
      }
    },
  },
};
/*import { mapGetters } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";
import { useMeta } from "vue-meta";
//import { onMounted, toRef, watchEffect } from "vue";
import { toRef } from "vue";
import TriangleSeparator from "@/components/partials/TriangleSeparator.vue";
import ArrowBtn from "@/components/partials/ArrowBtn.vue";
import TitleColor from "@/components/partials/TitleColor.vue";
import ContactUsAction from "@/components/partials/ContactUsAction.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
export default {
  components: {
    TriangleSeparator,
    ArrowBtn,
    TitleColor,
    ContactUsAction,
    CallActionService,
  },
  name: "TownRead",

  setup(props) {
    const town = toRef(props, "town");
    console.log(town.value.title);
    console.log(town.value.content);
    const sentenceBegin = "Rencontrez nous au ";
    const sentenceEnd =
      " pour tout vos projets de développement web (création site internet, application mobile) et marketing digital (log, flyers, visuel)";

    let metaDescription = sentenceBegin + town.value.content + sentenceEnd;

    useMeta({
      title: town.value.title,
      description: [
        {
          content: metaDescription,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/ma-ville/" + town.value.slug,
        },
      ],
    });
  },

  props: {
    town: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      return formatDate(this.town.updatedAt);
    },
    ...mapGetters({
      Towns: "StateTowns",
      User: "StateUser",
      Town: "StateTown",
    }),
  },
  methods: {
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth", // Défilement fluide
        });
      }
    },
  },
};*/
</script>

<style lang="scss" scoped>
.services-header {
  width: 100%;
  height: fit-content;
  min-height: 60vh;
  @include background-svg;
  @include background-responsive;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  & h1 {
    color: $color-primary;
    font-size: 2.3em;
    text-align: center;
  }
  & h2 {
    color: $color-text;
    font-size: 1.5em;
    text-align: center;
  }
}
.card-service:hover {
  @include grow;
}
.service-card {
  @include thumbnail;
  height: 100%;
  padding: 15px 15px 15px;
  & .card-body {
    padding: 0px;
    & h4 {
      font-size: 1.3em;
      margin: 15px 5px 0px;
      color: $color-text-grey;
    }
  }
}
.block-row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  & video,
  img {
    width: 500px;
    max-width: 90vw;
    margin-top: 30px;
    margin-bottom: 10px;
    @media screen and (min-width: 992px) {
      width: 500px;
      margin-bottom: 0px;
      margin-top: 30px;
    }
  }
  & .img-double {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .hr {
    width: 50px;
    height: 3px;
    background-color: $background;
  }
}

.reverse {
  flex-direction: column-reverse;
  @media screen and (min-width: 992px) {
    flex-direction: row-reverse !important;
  }
}
.ink {
  width: 200px;
  height: 200px;
  background-color: #000;
  border-radius: 50%;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 150px;
    background-color: #000;
    border-radius: 50%;
    transform: rotate(45deg);
  }

  &::before {
    top: -30px;
    left: 50px;
  }

  &::after {
    bottom: -60px;
    right: 50px;
  }
}
.service-contact {
  margin-top: 100px;
  margin-bottom: 200px;
  @media screen and (min-width: 992px) {
    margin-top: 200px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & h3 {
      color: $bold-light-primary;
    }
    & a {
      background: $bold-light-primary;
      width: fit-content;
      margin: auto;
      &:hover {
        background: $color-primary;
        border-color: $color-text;
      }
    }
  }
}
.cyan {
  border-top: 1.5px solid hsl(180, 62%, 55%);
}
.purple {
  border-top: 1.5px solid hsl(263, 47%, 58%);
}
.orange {
  border-top: 1.5px solid $color-primary;
}
.yellow {
  border-top: 1.5px solid hsl(59, 91%, 65%);
}
.span-ml {
  margin-left: 4px;
}
</style>
