<template>
  <div
    class="flip flip-vertical"
    :class="{ flipped: isFlipped }"
    @mouseenter="clearIntervalFn"
    @mouseleave="startIntervalFn"
  >
    <div class="front text-center">
      <a href="tel:+33698543586"><i class="fas fa-phone"></i> Appelez-nous</a>
    </div>
    <div class="phone back text-center">
      <a href="tel:+33698543586">06 98 54 35 86</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlipPhone",
  data() {
    return {
      isFlipped: false,
      intervalId: null,
    };
  },
  methods: {
    startIntervalFn() {
      this.intervalId = setInterval(() => {
        this.isFlipped = !this.isFlipped;
      }, 5000);
    },
    clearIntervalFn() {
      clearInterval(this.intervalId);
      this.isFlipped = false;
    },
  },
  mounted() {
    this.startIntervalFn();
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
};
</script>

<style lang="scss" scoped>
.flip {
  position: relative;
  > .front,
  > .back {
    display: block;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 0.5s;
    transition-property: transform, opacity;
  }
  > .front {
    transform: rotateY(0deg);
  }
  > .back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
  }
  &:hover {
    > .front {
      transform: rotateY(180deg);
    }
    > .back {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
  &.flip-vertical {
    > .back {
      transform: rotateX(-180deg);
    }
    &:hover {
      > .front {
        transform: rotateX(180deg);
      }
      > .back {
        transform: rotateX(0deg);
      }
    }
  }
  &.flipped {
    @media screen and (max-width: 992px) {
      > .front {
        transform: rotateX(180deg);
      }
      > .back {
        opacity: 1;
        transform: rotateX(0deg);
      }
    }
  }
}

// custom
.flip {
  position: relative;
  display: inline-block;
  font-size: 0.8em;
  > .front,
  .back {
    display: block;
    background-size: cover !important;
    background-position: center !important;
    border-radius: 10px;
  }
  > .front {
    width: fit-content;
    background: $color-darker;
    padding: 4px 6px;
    border: outset 1px $color-primary;
    & a {
      color: $color-primary;
    }
  }
  > .back {
    width: inherit;
    background: $color-primary;
    padding: 4px 10px;
    border: outset 1px $color-text;
    & a {
      color: $color-text;
    }
  }
}
</style>
