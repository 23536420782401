<template>
  <div class="container-fluid pt-2 main-seo">
    <NavOption
      navLink="/referencement-google"
      navText="Retour"
      navBorderColor="cyan"
      iconColorClass="cyan"
    />
    <section class="hero-banner container">
      <div class="flex">
        <div
          class="hero-banner__img col-lg-7 order-lg-2 d-flex justify-content-center"
        >
          <img
            src="@/assets/pictures/seo-boost.webp"
            alt="logo-wordpress-circle"
            class="delta"
            width="500"
          />
        </div>
        <div class="hero-banner__speech col-lg-5 mr-lg-5">
          <h1 class="mt-5">
            Services de Référencement <span>SEO</span> pour Optimiser votre
            Visibilité en Ligne
          </h1>
          <p class="p-1">
            Notre <strong>agence web</strong>, propose des
            <strong>solutions de référencement</strong> <strong>SEO</strong>
            efficaces et personnalisées pour
            <strong>accroître la visibilité de votre site web</strong>. Nous
            sommes spécialisés dans l'optimisation des performances en ligne,
            offrant des <strong>stratégies SEO</strong> complètes et adaptées à
            vos besoins spécifiques.
          </p>
          <!---<a href="#" class="btn btn-slide btn-lg border">en savoir plus</a>-->
        </div>
      </div>
    </section>
    <div class="pt-5">
      <section class="p-md-3 container-fluid">
        <div class="row-icon pt-3">
          <div class="row-icon__cadre mb-5">
            <i class="col-3 far fa-paper-plane indigo-text"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Audit SEO</h4>
              <p class="text-muted mb-lg-0">
                Dépend de la taille de votre site web.
              </p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">SEO</h4>
              <p class="text-muted mb-lg-0">
                Compris dans le prix final de votre projet.
              </p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Optimisation SEO</h4>
              <p class="text-muted mb-lg-0">
                Entre<br />200€ & 600€ (par page)
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <article class="container-fluid">
      <div class="article-title container-lg">
        <h2>Maximisez votre Visibilité en Ligne</h2>
      </div>
      <section class="container mt-3 mt-lg-5">
        <div class="block-row pt-5">
          <div class="block-text col-lg-5 slide-left">
            <TitleColor
              title="Stratégies Personnalisées pour votre Site Web"
              titleColor="#0083b0"
            />
            <p class="text-left mx-auto w-responsive">
              Nous comprenons que chaque <strong>site internet</strong> est
              unique, tout comme les objectifs commerciaux de nos clients. Notre
              équipe dédiée conçoit des
              <strong>stratégies SEO</strong> personnalisées pour répondre aux
              besoins spécifiques de votre <strong>site web</strong>. Nous
              analysons en profondeur votre marché, votre public cible, ainsi
              que les tendances actuelles de recherche pour élaborer des
              tactiques sur mesure. Notre approche individualisée vise à
              propulser votre <strong>application web</strong> vers les
              <strong>premiers rangs des résultats de recherche</strong>,
              générant ainsi un impact durable sur votre présence en ligne.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/seo-audit.webp"
                alt="graphic-economy-energy"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Section: Content-->
      <section class="container my-5 pb-lg-5">
        <!-- Grid row -->
        <div class="block-row reverse">
          <!-- Grid column -->
          <div class="block-text slide-right col-lg-5">
            <!-- Category -->
            <!-- Post title -->
            <TitleColor
              title="Mise en place efficace des mots-clés"
              titleColor="#0083b0"
              bgColor="hsl(263, 47%, 58%)"
            />
            <!-- Excerpt -->
            <p>
              Une <strong>stratégie SEO</strong> personnalisée pour votre
              <strong>site web</strong> implique un choix méticuleux de vos
              mots-clés. Nous effectuons une analyse approfondie pour identifier
              les termes les plus pertinents dans votre secteur, optimisant
              ainsi la
              <strong
                >visibilité de votre site sur les moteurs de recherche</strong
              >. La sélection précise des mots-clés garantit une meilleure
              portée et un trafic plus qualifié pour votre plateforme en ligne,
              augmentant ainsi vos chances de conversion et de croissance.
            </p>
          </div>
          <!-- Grid column -->
          <!-- Grid column -->
          <div class="col-lg-6">
            <!-- Featured image -->
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/seo-nouveau-client.webp"
                alt="graphic-economy-energy"
                class="img-fluid"
              />
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
    </article>
    <CallActionServiceSolo
      img="seo-launcher.webp"
      h3="Référencement gratuit (SEO)"
      h3Color="#ebdcdc"
      p="Sentez vous libre de nous contacter pour toute question concernant votre
        stratégie SEO, notre équipe vous apportera les réponses adaptées à
        votre projet web."
    />
    <FooterOption btnBorderColor="cyan" iconColorClass="cyan" />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import TitleColor from "@/components/partials/TitleColor.vue";
import CallActionServiceSolo from "@/components/partials/CallActionServiceSolo.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";
export default {
  name: "SeoRef",
  components: {
    TitleColor,
    CallActionServiceSolo,
    NavOption,
    FooterOption,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Référencement gratuit",
      description: [
        {
          content:
            "Améliorez votre visibilité en ligne avec les stratégies SEO de Malaweb. Des solutions personnalisées pour optimiser votre présence sur les moteurs de recherche. Profitez de notre expertise en référencement naturel pour atteindre les meilleurs classements. Boostez votre trafic organique et maximisez votre présence digitale avec Malaweb.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/prix-referencement-seo",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.main-seo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(to left, #0083b0, #00b4db);

  & .hero-banner {
    //@include background-svg;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    color: $color-text;
    padding: 40px 20px;
    @media screen and (min-width: 992px) {
      padding: 0px 0px 70px 20px;
    }
    & .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
      }
    }
    &__img {
      @include image-fade-in(2s);
      & img {
        @media screen and (max-width: 1245px) {
          width: 100%;
        }
      }
    }

    & .hero-banner__speech .btn {
      position: relative;
      overflow: hidden;
      z-index: 9;
    }
    & .hero-banner__speech .btn-slide {
      background-color: rgba(0, 0, 0, 0.1);
      color: $color-text;
    }
    & .hero-banner__speech .btn-slide:before {
      content: "";
      background: #f27316;
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      -ms-transition: all 0.5s ease-in-out 0s;
      -o-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
    }
    & .hero-banner__speech .btn-slide:hover:before {
      width: 100%;
    }

    & .hero-banner__speech {
      @media screen and (min-width: 992px) {
        padding-left: 50px;
      }
      & h1 > span {
        color: $color-primary;
      }
      & p {
        text-align: start;
      }
      & a {
        margin: 15px 0px 20px 0px;
      }
    }
  }
  & article {
    & h2 {
      text-align: center;
      color: $color-text;
      margin: 80px 10px 50px;
      font-size: 2em;
      text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
    }
  }
  & .row-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &__cadre {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 350px;
      min-height: 100%;
      padding: 10px 20px;
      text-align: center;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
      border-radius: 59px;
      border: 0.1px outset $color-lighter;
      box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
      & i {
        color: #0083b0;
        font-size: 2em;
        border-radius: 100%;
        border: 0.5px outset #0083b0;
        padding: 18px;
      }
      & h4 {
        font-size: 1.3em;
        color: #0083b0;
      }
      & p {
        text-align: center;
        font-size: 0.9em;
      }
    }
  }
  .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
  .callAction {
    margin: 20px 0px 0px 0px;
  }
}
</style>
