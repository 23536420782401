<template>
  <div class="container p-5 z-depth-1">
    <section class="dark-grey-text text-center">
      <h1 class="jaapokki pt-5 pb-5">
        Mala<span class="menu__span">web</span>
      </h1>
      <TextHiting />
      <div class="row my-5">
        <div class="col-md-4 px-4 mb-4">
          <div class="view">
            <img
              src="@/assets/pictures/our-world.webp"
              class="img-fluid"
              alt="smaple image"
            />
          </div>
        </div>
        <div class="col-md-4 p-4 my-5 hide">
          <div class="view">
            <img
              src="@/assets/pictures/team-work.webp"
              class="img-fluid"
              alt="smaple image"
            />
          </div>
        </div>
        <div class="col-md-4 px-4 mb-4 hide">
          <div class="view">
            <img
              src="@/assets/pictures/equality.webp"
              class="img-fluid"
              alt="smaple image"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TextHiting from "@/components/partials/TextHiting.vue";
export default {
  name: "HeroBlog",
  components: { TextHiting },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 3em;
  & span {
    color: #fff;
  }
}
img {
  width: 200px;
}
.hide {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
</style>
