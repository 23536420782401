<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Malaweb` : `Malaweb`
    }}</template>
  </metainfo>

  <NavBase v-if="showNavbar" />
  <!--<NavBlog />-->
  <router-view :key="$route.fullPath" />
  <div v-if="showFooter">
    <WaveSeparator />
    <LegalBar />
    <FooterBase />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import NavBase from "@/components/NavBase.vue";
//import NavBlog from "@/components/NavBlog.vue";
import LegalBar from "@/components/plugins/LegalBar.vue";
import FooterBase from "@/components/FooterBase.vue";
import WaveSeparator from "@/components/partials/WaveSeparator.vue";

export default {
  components: { NavBase, FooterBase, LegalBar, WaveSeparator },
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "fr" },
      description: [
        {
          content:
            "Malaweb, votre partenaire digital de confiance. Nous offrons des solutions web sur mesure pour booster votre présence en ligne. Experts en conception de sites web, référencement SEO, développement d'applications et bien plus. Contactez-nous dès maintenant pour propulser votre entreprise vers le succès digital.",
        },
      ],
    });
  },
  data() {
    return {
      showNavbar: true,
      showFooter: true,
    };
  },
};
</script>

<style lang="scss"></style>
