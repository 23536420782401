<template>
  <h3 class="mb-3" :style="({ background: bgColor }, { color: titleColor })">
    {{ title }}
  </h3>
</template>

<script>
export default {
  name: "TitleColor",
  props: {
    title: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
      default: "hsl(234, 12%, 34%)",
    },
    bgColor: {
      type: String,
      default: "#f27316",
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  background-color: $color-darker;
  padding: 10px;
  border-radius: 15px 15px 0px 0px;
  text-align: start;
}

/*@media screen and (min-width: 992px) {
  h3 {
    display: inline-block;
    position: relative;
    margin-top: 50px;
    background-color: $color-darker; 
    color: $color-text; 
    z-index: 1;
    padding: 10px 50px 10px 8px;
    margin-bottom: 50px !important;
  }
  h3:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    background-color: $color-darker;
    transform: skewX(
      -16deg
    ); 
    z-index: -1;
    right: -19px;
  }
}*/
</style>
