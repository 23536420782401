<template>
  <main id="digi">
    <NavOption navBorderColor="#f8d800" iconColorClass="yellow" />
    <div class="header">
      <h2>MARKETING DIGITAL</h2>
      <h2>Powered by Malaweb</h2>
    </div>
    <div class="toggle-section">
      <div class="toggle-content">
        <div class="toggle-item active" id="dev-home">
          <div class="container my-5">
            <section class="text-center">
              <div class="header-card pb-2 mb-4">
                <h3 class="">Prix Webdesign</h3>

                <p class="text-center mb-5">
                  Explorez le pouvoir du <strong>marketing digital</strong> pour
                  affirmer une identité visuelle forte et captivante.
                </p>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-12 mb-4 slide-left">
                  <div class="card pricing-card">
                    <div class="pricing-card__title">
                      <p class="option">BRANDING</p>
                    </div>

                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <div class="head-body mb-4">
                          <p class="price">350</p>
                          <span class="euro">€</span>
                        </div>
                        <div class="b-ttc">
                          <p class="ttc"><span>/</span>ttc</p>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iOrange"
                              aria-hidden="true"
                            ></i>
                            <span>NAMING :</span> <br />Le naming consiste à
                            <strong
                              >trouver le nom idéal pour une entreprise</strong
                            >
                            ou produit.
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iOrange"
                              aria-hidden="true"
                            ></i>
                            <span><strong>LOGO</strong> :</span> <br />Nous
                            offrons à nos clients un large éventail de
                            propositions pour la
                            <strong>création de leur logo</strong>.
                          </p>
                          <div class="hr"></div>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <CyberBtn link="/prix-marketing-digital" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 mb-4 slide-right">
                  <div class="card pricing-card">
                    <div class="pricing-card__second">
                      <p class="option">FLYER</p>
                    </div>

                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <div class="head-body mb-4">
                          <p class="price">80</p>
                          <span class="euro">€</span>
                        </div>
                        <div class="b-ttc">
                          <p class="ttc"><span>/</span>ttc</p>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iBlack"
                              aria-hidden="true"
                            ></i>
                            <span>CRÉATION :</span> <br />La
                            <strong>création de flyer</strong>
                            implique la recherche du thème initial pour donner
                            naissance au premier visuel.
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iBlack"
                              aria-hidden="true"
                            ></i>
                            <span>MODIFICATION :</span> <br />Les différents
                            volets du même <strong>flyer</strong>, utilisant un
                            thème existant, rendent les déclinaisons plus
                            économiques.
                          </p>
                          <div class="hr"></div>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <CyberBtn link="/prix-marketing-digital" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-12 mb-4 slide-left">
                  <div class="card pricing-card">
                    <div class="pricing-card__title">
                      <p class="option">AFFICHE PUBLICITAIRE</p>
                    </div>

                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <div class="head-body mb-4">
                          <p class="price">250</p>
                          <span class="euro">€</span>
                        </div>
                        <div class="b-ttc">
                          <p class="ttc"><span>/</span>ttc</p>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iOrange"
                              aria-hidden="true"
                            ></i>
                            <span>CRÉATION :</span> <br />La création de visuels
                            implique la recherche du thème initial pour donner
                            naissance au premier visuel.
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iOrange"
                              aria-hidden="true"
                            ></i>
                            <span>MODIFICATION :</span> <br />Les différentes
                            <strong>affiches publicitaires</strong>, utilisant
                            un thème existant, rendent les déclinaisons plus
                            économiques.
                          </p>
                          <div class="hr"></div>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <CyberBtn link="/prix-marketing-digital" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-12 mb-4 slide-right">
                  <div class="card pricing-card">
                    <div class="pricing-card__second">
                      <p class="option">MAIL PRO</p>
                    </div>

                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <div class="breakword">
                          <p>à partir de</p>
                        </div>
                        <div class="head-body mb-4">
                          <p class="price">250</p>
                          <span class="euro">€</span>
                        </div>
                        <div class="b-ttc">
                          <p class="ttc"><span>/</span>ttc</p>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iBlack"
                              aria-hidden="true"
                            ></i>
                            <span>MP-5 :</span> <br />Disposez de 5
                            <strong>adresses mails professionnels</strong> à vie
                            (tant que vous disposez du nom de domaine chez
                            Malaweb).
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iBlack"
                              aria-hidden="true"
                            ></i>
                            <span>MP-PLUS :</span> <br />Augmentez le nombre
                            d'<strong>emails professionnels</strong> à votre
                            disposition facilement à tout moment.
                          </p>
                          <div class="hr"></div>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <CyberBtn link="/prix-marketing-digital" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <article>
            <section class="container mt-5 mb-3">
              <div class="article-title container-lg fall-down">
                <h3>
                  Optimisez l'image de votre entreprise grâce à notre Service de
                  Marketing Digital.
                </h3>
              </div>
              <div class="block-row mt-5">
                <div class="block-text col-lg-5 slide-left">
                  <p class="text-left mx-auto w-responsive">
                    Notre expertise en conception de visuels s'étend à une gamme
                    complète de créations personnalisées pour votre entreprise.
                    Du <strong>logo</strong> distinctif qui incarne l'essence de
                    votre marque, aux <strong>flyers</strong> et
                    <strong>affiches publicitaires</strong> percutants, aux
                    menus de restaurant, nous créons des supports visuels
                    variés. Malaweb donne vie à votre entreprise à travers des
                    <strong>vidéos de présentation</strong> engageantes et des
                    publications spécifiques pour les réseaux sociaux.
                  </p>
                </div>
                <div class="col-lg-5 slide-right">
                  <div
                    class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left"
                  >
                    <img
                      src="@/assets/pictures/tree-digital.webp"
                      alt="serie-of-tag-form-planet"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="container my-5">
              <div class="block-row reverse">
                <div class="block-text slide-right col-lg-5">
                  <p>
                    Chaque visuel est méticuleusement conçu pour amplifier votre
                    message, attirer l'attention de votre public cible et
                    renforcer votre présence sur tous les canaux de
                    communication. Le <strong>webdesign</strong> vous permet de
                    renforcer votre <strong>visibilité en ligne</strong>. Notre
                    approche du <strong>marketing digital</strong> vise à créer
                    une <strong>identité visuelle</strong> qui capte l'attention
                    de votre public. Grâce à l'expertise Malaweb, votre
                    entreprise communiquera visuellement de façon forte et
                    cohérente pour se démarquer de la concurrence.
                  </p>
                </div>

                <div class="col-lg-6 slide-left">
                  <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4">
                    <img
                      src="@/assets/pictures/idea-media.webp"
                      alt="box-full-stuff-icon-social-media"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </section>
          </article>
          <CallActionService />
        </div>
      </div>
    </div>
    <FooterOption btnBorderColor="yellow" iconColorClass="yellow" />
  </main>
</template>

<script>
import { useMeta } from "vue-meta";
import CyberBtn from "@/components/partials/CyberBtn.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";

export default {
  name: "MarketingDigital",
  components: { CyberBtn, CallActionService, FooterOption, NavOption },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Marketing digital",
      description: [
        {
          content:
            "Découvrez les stratégies gagnantes du marketing digital avec Malaweb. Explorez nos solutions innovantes en référencement SEO, publicités ciblées, gestion de réseaux sociaux et bien plus. Maximisez votre présence en ligne et atteignez votre public cible grâce à notre expertise en marketing digital. Transformez vos objectifs en résultats tangibles dès aujourd'hui.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/marketing-digital",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
#digi {
  //background-image: linear-gradient(135deg, #ffe53c 10%, #f8d800 100%);
  background-image: linear-gradient(
    to left,
    #f8d800,
    #f6dc00,
    #f3e102,
    #f0e506,
    #ede90b
  );
}
.header {
  text-align: center;
  line-height: 0.8;
  margin-bottom: 50px;
  margin-top: 0px;
  padding: 50px 15px 15px;
  & h2:first-of-type {
    font-weight: 200;
    color: $color-text;
  }
  & h2:last-of-type {
    color: $color-darker;
    font-size: 1.5rem;
    opacity: 88%;
  }
  @media (max-width: 400px) {
    h2 {
      font-size: 1.5rem;
    }
  }
}
.toggle-section {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: start;
  flex-wrap: wrap;
  //background-image: linear-gradient(135deg, #ffe53c 10%, #f8d800 100%);
  background-image: linear-gradient(
    to left,
    #f8d800,
    #f6dc00,
    #f3e102,
    #f0e506,
    #ede90b
  );

  & .toggle-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 337px) {
      flex-direction: row;
      justify-content: space-around;
    }
    & .avatar {
      margin: 10px 0px -10px 0px;
      & img {
        width: 40px;
        @media screen and (min-width: 992px) {
          width: 50px;
        }
      }
    }
    & .card {
      background: linear-gradient(
          to bottom,
          #323232 0%,
          #3f3f3f 40%,
          #1c1c1c 150%
        ),
        linear-gradient(
          to top,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(0, 0, 0, 0.25) 200%
        );
      background-blend-mode: multiply;
      padding: 5px;
      height: 100%;
      min-width: 140px;
      border: none;
      border-radius: 15px;
      @media screen and (min-width: 992px) {
        min-width: 160px;
      }
      /*&:hover {
        background-color: $color-secondary;
      }*/
      & .card-body {
        & .hr {
          margin: 2px auto 5px;
          width: 75%;
          height: 1px;
          background-color: $color-secondary;
        }
        & h4 {
          color: $bold-light;
          font-size: 0.9em;
          margin: auto;
          @media screen and (min-width: 992px) {
            font-size: 1em;
          }
        }
      }
    }
  }
  & .toggle-btn {
    background: linear-gradient(
        to bottom,
        #323232 0%,
        #3f3f3f 40%,
        #1c1c1c 150%
      ),
      linear-gradient(
        to top,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.25) 200%
      );
    background-blend-mode: multiply;
    border: 0.5px $color-darker outset;
    border-radius: 15px;
    cursor: pointer;
    transition: scale 0.3s;
    transition: box-shadow 0.2s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  & .toggle-btn:hover {
    color: #ffffff;
    border: 0.5px $color-darker outset;
  }

  & .toggle-btn.active {
    background-image: linear-gradient(-60deg, #1f1e1ecc 0%, #242121 100%);
    color: #f8f9fa;
    border: 0.5px $color-secondary inset;
    scale: (96%);
    box-shadow: none;
    & .card {
      & h4 {
        color: $color-primary;
      }
      & .hr {
        background-color: $color-lighter;
      }
    }
  }

  & .toggle-content {
    width: 100%;
    margin-top: 20px;
  }

  & .toggle-item {
    display: none;
  }

  & .toggle-item.active {
    display: block;
  }
}

.header-card {
  & h3 {
    width: 100%;
    margin: auto;
    background-color: $color-logo;
    color: #f8d800;
    border: 0.2px outset #f8d800;
    border-radius: 20px;
    padding: 4px 50px;
    margin-bottom: 20px;
  }

  & p {
    color: $color-text-grey;
  }
}
.card {
  min-height: 100%;
  border-radius: 8px 8px 0px 0px;
}
.pricing-card {
  background: $background;
  &__title {
    background: #f8d800;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-darker;
      text-align: center;
      margin-bottom: 0px;
    }
  }
  &__second {
    background: $color-darker;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: #f8d800;
      text-align: center;
      margin-bottom: 0px;
    }
  }
  & .card-body {
    padding: 1rem 0rem;

    & .head-body {
      display: flex;
      //justify-content: center;
      align-items: flex-start;
      /*background-color: $color-darker;
      border: 0.3px outset $color-primary;
      border-radius: 10px 0px 10px 0px;
      padding: 5px 50px;
      width: fit-content;*/
      &__orange {
        /*background-color: $color-primary;
        border: 0.3px outset $color-darker;
        border-radius: 0px 10px 0px 10px;
        padding: 5px 50px;
        width: fit-content;*/
        margin: auto;
      }
    }
    & .price {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      font-size: 2em;
      font-weight: 100;
      color: $color-secondary;
      margin: 0px;
    }
    & .euro {
      font-size: 0.6em;
      line-height: -50px;
      color: $color-secondary;
    }
    & .b-ttc {
      position: relative;
      bottom: 1px;
      color: $bold-light;
      & .ttc {
        font-size: 0.8em;
        margin-bottom: 0px;
        line-height: 55px;
        margin-left: -5px;
        & span {
          font-size: 1.3em;
        }
      }
    }
    & ul {
      list-style-type: none;
      & li {
        & p {
          font-size: 1em;
          margin-bottom: 0px;
          color: $bold-light;
          & i {
            padding: 5px;
            border-radius: 100%;
            margin-right: 10px;
          }
          & span {
            color: $color-secondary;
          }
          & .iOrange {
            color: $color-lighter;
            background-color: $color-primary;
          }
          & .iBlack {
            color: $color-primary;
            background-color: $color-darker;
          }
        }
        & .hr {
          width: 80%;
          height: 1px;
          background-color: $bold-light;
          margin: 10px auto;
        }
      }
    }
  }
  & .card-footer {
    background: $color-darker;
    border: none;
    padding: 15px 0px;
  }
}
article {
  & h3 {
    text-align: center;
    color: $color-text;
    text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
    margin: 130px 10px 50px;
    font-size: 2em;
  }
  & .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        width: 500px;
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text-grey;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
}
.breakword {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 6px;
  & p {
    font-size: 0.7em;
  }
}
</style>
