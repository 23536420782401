<template>
  <div class="container my-5">
    <section class="text-center">
      <div class="header pb-2 mb-4">
        <h2 class="">Prix d'un blog</h2>

        <p class="text-center mb-5">
          Munissez votre <strong>site internet</strong> d'un module
          <strong>blog</strong> et
          <strong>améliorez votre référencement organique</strong> sur les
          <strong>moteurs de recherche</strong>.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12 mb-4">
          <div class="card pricing-card">
            <div class="pricing-card__title">
              <p class="option">BASIC</p>
            </div>

            <div class="card-body">
              <div class="d-flex justify-content-center">
                <div class="head-body mb-4">
                  <p class="price">2500</p>
                  <span class="euro">€</span>
                </div>
                <div class="b-ttc">
                  <p class="ttc"><span>/</span>ttc</p>
                </div>
              </div>
              <ul>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span><strong>BRANDING</strong> :</span> <br />Identité
                    visuelle exclusive
                    (<strong>naming</strong>/<strong>logo</strong>) pour
                    renforcer la reconnaissance de votre marque.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span>TECHNOLOGIE :</span> <br /><strong>WordPress</strong>
                    est une solution robuste et polyvalente pour la
                    <strong>création de sites web</strong>. Cette option vous
                    offre l'accès à cette technologie renommée pour sa
                    convivialité, sa flexibilité et sa facilité de gestion.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span>PAGE :</span> <br />Comprises dans cette offre trois
                    pages essentielles pour <strong>votre site web</strong> +
                    deux pages <strong>blog</strong>
                    (liste articles/ lecture articles).
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span>FORMULAIRE :</span> <br />Création d'un formulaire de
                    contact, un outil indispensable pour permettre à vos
                    visiteurs de vous contacter directement depuis votre
                    <strong>site web</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    MODULES : <br />Un module <strong>blog</strong> avec une
                    zone d'administration conviviale. Vous pourrez ainsi créer,
                    modifier, supprimer et publier vos articles facilement,
                    garantissant ainsi un contenu toujours frais et attractif
                    pour vos visiteurs. D'autres modules peuvent être
                    personnalisés selon vos besoins spécifiques pour enrichir
                    les fonctionnalités de votre <strong>site web</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    HEBERGEMENT : <br />L'<strong
                      >hébergement sur notre serveur web</strong
                    >
                    assure des performances optimales pour votre site. Avec un
                    coût mensuel, il garantit une qualité de serveur fiable,
                    assurant ainsi la disponibilité et la rapidité de votre
                    <strong>site web</strong> pour vos visiteurs.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    <strong>SEO</strong> : <br />Inclus l'<strong
                      >optimisation SEO pour les moteurs de recherche</strong
                    >. Une visibilité accrue sur les moteurs de recherche est
                    essentielle pour attirer un trafic qualifié vers votre
                    <strong>site web</strong>, plus la rédaction d'un article
                    pour votre <strong>blog</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    <strong>SEA</strong> : <br />Le
                    <strong>référencement google payant</strong>
                    (<strong>SEA</strong>) peut être ajouté à votre offre pour
                    améliorer davantage la
                    <strong
                      >visibilité de votre site web sur les moteurs de
                      recherche</strong
                    >
                    via des <strong>annonces payantes</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    SOCIAL MEDIA : <br />La conception graphique des bannières
                    et avatars de vos comptes sur les réseaux sociaux tels que
                    Facebook, Instagram et LinkedIn, n'est pas compris dans
                    cette offre.
                  </p>
                  <div class="hr"></div>
                </li>
              </ul>
            </div>
            <div class="card-footer">
              <CyberBtn link="/prix-site-blog-wordpress" />
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 mb-4">
          <div class="card pricing-card">
            <div class="pricing-card__second">
              <p class="option">PRO</p>
            </div>

            <div class="card-body">
              <div class="d-flex justify-content-center">
                <div class="head-body mb-4">
                  <p class="price">4400</p>
                  <span class="euro">€</span>
                </div>
                <div class="b-ttc">
                  <p class="ttc"><span>/</span>ttc</p>
                </div>
              </div>
              <ul>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span><strong>BRANDING</strong> :</span> <br />Notre équipe
                    se dédie à la création minutieuse de l'identité visuelle de
                    votre marque, incluant le nom et le <strong>logo</strong>,
                    afin de refléter au mieux votre essence et vos valeurs.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>TECHNOLOGIE :</span> <br />Notre choix technologique
                    repose sur VueJS pour la partie front-end, assurant ainsi
                    une <strong>interface utilisateur</strong> interactive et
                    fluide. En parallèle, Symfony 6 assure la robustesse et la
                    gestion performante des données côté back-end, offrant ainsi
                    un <strong>blog</strong>
                    dynamique et efficace.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>PAGE :</span> <br />Bénéficiez de quatre pages
                    principales pour votre <strong>blog</strong> : Accueil, À
                    propos, Services, Contact, ainsi qu'une section dédiée à la
                    lecture des articles et une page individuelle pour chaque
                    article. Chaque page est élaborée pour captiver vos
                    visiteurs et
                    <strong>améliorer votre référencement organique</strong>
                    (<strong>référencement google gratuit</strong>).
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>FORMULAIRE :</span> <br />Un formulaire de contact et
                    un call-to-action stratégiquement placés pour stimuler
                    l'interaction et favoriser l'engagement des visiteurs sur
                    votre <strong>site web</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>MODULES :</span> <br />Bénéficiez d'un module
                    <strong>blog</strong>
                    avec une zone d'administration intuitive pour la gestion
                    fluide des articles. Une personnalisation sur mesure est
                    également envisageable pour répondre à des besoins
                    spécifiques.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>HEBERGEMENT :</span> <br />Bénéficiez d'une
                    infrastructure d'<strong>hébergement web</strong> dédiée à
                    votre <strong>blog</strong>, avec une année d'hébergement
                    serveur offerte. Cette option garantit un environnement
                    serveur fiable et évolutif pour maintenir une présence en
                    ligne dynamique et engageante.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span><strong>SEO</strong> :</span> <br />L'<strong
                      >optimisation SEO</strong
                    >
                    est intégrée pour les quatre pages principales, et inclut
                    également la rédaction d'un article pour
                    <strong
                      >améliorer votre référencement sur les moteurs de
                      recherche</strong
                    >.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span><strong>SEA</strong> :</span> <br />Nous offrons le
                    paramétrage de votre <strong>campagne google </strong>
                    <strong>SEA</strong> pour
                    <strong
                      >accroître la visibilité de votre site internet</strong
                    >
                    dès sa mise en ligne, vous permettant ainsi d'atteindre
                    rapidement votre public cible.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>SOCIAL MEDIA :</span> <br />En plus de la conception
                    graphique des bannières et avatars, nous vous accompagnons
                    dans l'ouverture de comptes sur les réseaux sociaux tels que
                    Facebook, Instagram et LinkedIn.
                  </p>
                  <div class="hr"></div>
                </li>
              </ul>
            </div>
            <div class="card-footer">
              <CyberBtn link="/prix-site-blog" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <article>
    <section class="container mt-5 mb-2">
      <div class="article-title container">
        <h3>
          Une image d'expert et un meilleur positionnement sur les moteurs de
          recherche.
        </h3>
      </div>
      <div class="block-row">
        <div class="block-text col-lg-5 slide-left">
          <p class="text-left mx-auto w-responsive">
            L'intérêt premier de disposer d'un module <strong>blog</strong> pour
            <strong>son site internet</strong> et qu'il s'inscrit dans une
            véritable stratégie <strong>SEO</strong> à long terme, vous
            permettant de <strong>positionner votre site web</strong> dans les
            <strong>premiers résultats gratuits Google</strong> (<strong
              >référencement organique</strong
            >).
          </p>
          <ArrowBtn />
        </div>
        <div class="col-lg-6">
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/blog-seo.webp"
              alt="graphic-statistic-sea"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="container mb-5">
      <div class="block-row reverse">
        <div class="block-text slide-right col-lg-5">
          <p>
            Cet outil permet également de tenir informer vos clients des
            éventuels nouveautés liées à votre entreprise, en plus d'<strong
              >affirmer votre image d'expert</strong
            >
            dans votre secteur professionnel via la
            <strong>rédaction d'articles</strong> pertinent et ciblé répondant
            aux attentes de votre public.
          </p>
        </div>

        <div class="col-lg-6 mb-3 mb-lg-0">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left">
            <img
              src="@/assets/pictures/seo-blog.webp"
              alt="graphic-statistic-web"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  </article>
  <CallActionService />
</template>

<script>
import { useMeta } from "vue-meta";
import CyberBtn from "@/components/partials/CyberBtn.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
export default {
  components: { CyberBtn, CallActionService },
  setup() {
    useMeta({
      title: "Créations sites internet",
      description: [
        {
          content:
            "Explorez notre savoir-faire en création de blogs performants. Profitez de contenus de qualité et d'une interface intuitive pour partager vos idées et rester en tête dans votre domaine.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/developpement-web?itemActif=site-blog",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.header {
  & h2 {
    width: 100%;
    margin: auto;
    background-color: $color-logo;
    color: $color-primary;
    border: 0.2px outset $color-primary;
    border-radius: 20px;
    padding: 4px 50px;
    margin-bottom: 20px;
  }

  & p {
    color: $color-text;
  }
}
.card {
  min-height: 100%;
  border-radius: 8px 8px 0px 0px;
}
.pricing-card {
  background: $background;
  &__title {
    background: $color-primary;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-lighter;
      text-align: center;
    }
  }
  &__second {
    background: $color-darker;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-primary;
      text-align: center;
    }
  }
  & .card-body {
    padding: 1rem 0rem;

    & .head-body {
      display: flex;
      //justify-content: center;
      align-items: flex-start;
      /*background-color: $color-darker;
      border: 0.3px outset $color-primary;
      border-radius: 10px 0px 10px 0px;
      padding: 5px 50px;
      width: fit-content;*/
      &__orange {
        /*background-color: $color-primary;
        border: 0.3px outset $color-darker;
        border-radius: 0px 10px 0px 10px;
        padding: 5px 50px;
        width: fit-content;*/
        margin: auto;
      }
    }
    & .price {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      font-size: 2em;
      font-weight: 100;
      color: $color-secondary;
      margin: 0px;
    }
    & .euro {
      font-size: 0.6em;
      line-height: -50px;
      color: $color-secondary;
    }
    & .b-ttc {
      position: relative;
      bottom: 1px;
      color: $bold-light;
      & .ttc {
        font-size: 0.8em;
        margin-bottom: 0px;
        line-height: 55px;
        margin-left: -5px;
        & span {
          font-size: 1.3em;
        }
      }
    }
    & ul {
      list-style-type: none;
      & li {
        & p {
          font-size: 1em;
          margin-bottom: 0px;
          color: $bold-light;
          & i {
            padding: 5px;
            border-radius: 100%;
            margin-right: 10px;
          }
          & span {
            color: $color-secondary;
          }
          & .iOrange {
            color: $color-lighter;
            background-color: $color-primary;
          }
          & .iBlack {
            color: $color-primary;
            background-color: $color-darker;
          }
        }
        & .hr {
          width: 80%;
          height: 1px;
          background-color: $bold-light;
          margin: 10px auto;
        }
      }
    }
  }
  & .card-footer {
    background: $color-darker;
    border: none;
    padding: 15px 0px;
  }
}
article {
  & h3 {
    text-align: center;
    color: $color-text;
    margin: 130px 10px 50px;
    font-size: 2em;
    text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
  }
  & .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        width: 500px;
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
}
</style>
