<template>
  <header class="container-fluid services-header px-4">
    <h1 class="fall-down">Les services web</h1>
    <div class="hr"></div>
    <h2 class="image-fade-in">
      une question sur le prix d'un site internet ? d'une application web ?
    </h2>
  </header>
  <TriangleSeparator />
</template>

<script>
import TriangleSeparator from "@/components/partials/TriangleSeparator.vue";
export default {
  name: "ServicesHeader",
  components: { TriangleSeparator },
};
</script>

<style lang="scss">
.services-header {
  width: 100%;
  height: 50vh;
  @include background-svg;
  @include background-responsive;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & h1 {
    color: $color-primary;
    font-size: 3em;
  }
  & h2 {
    color: $color-text;
    font-size: 1.5em;
  }
}
</style>
