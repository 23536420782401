<template>
  <div class="container-fluid pt-2 main-vitrine">
    <NavOption
      navLink="/developpement-web?itemActif=site-blog"
      navText="Retour"
    />
    <section class="hero-banner container">
      <div class="flex">
        <div
          class="hero-banner__img col-lg-7 order-lg-2 d-flex justify-content-center"
        >
          <img
            src="@/assets/pictures/blog-pro.webp"
            alt="draw-dashboard-seo-statistic"
            class="delta"
            width="500"
          />
        </div>
        <div class="hero-banner__speech col-lg-5 mr-lg-5">
          <h1 class="mt-5">
            Blogs Pro Nouvelle Génération: Puissance SEO de
            <span class="vue">Vue.js</span> et
            <span class="php">Symfony</span>
          </h1>
          <p class="p-1">
            Notre expertise technique combinée permet de
            <strong>créer des blogs</strong> évolutifs, offrant une
            <strong>expérience utilisateur</strong> exceptionnelle et une
            facilité de gestion pour des contenus riches et dynamiques.
          </p>
          <!---<a href="#" class="btn btn-slide btn-lg border">en savoir plus</a>-->
        </div>
      </div>
    </section>
    <div class="pt-5">
      <section class="p-md-3 container-fluid">
        <div class="row-icon pt-3">
          <div class="row-icon__cadre mb-5">
            <i class="col-3 far fa-paper-plane indigo-text"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Prix</h4>
              <p class="text-muted mb-lg-0">À partir de 3500€</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Technologie</h4>
              <p class="text-muted mb-lg-0">Vue.Js + Symfony</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Hébergement</h4>
              <p class="text-muted mb-lg-0">Entre<br />52€ & 80€ (par mois)</p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <article class="container-fluid">
      <div class="article-title container-lg">
        <h2>
          Création blogs : performances SEO maximales avec Vue.js et Symfony6
        </h2>
      </div>
      <section class="container mt-3 mt-lg-5">
        <div class="block-row pt-5">
          <div class="block-text col-lg-5 slide-left">
            <TitleColor
              title="Optimiser son référencement SEO"
              titleColor="#f27316"
            />
            <p class="text-left mx-auto w-responsive">
              La fusion de Vue.js pour le front-end et de Symfony 6 pour
              l'<strong>API</strong> back-end dans nos
              <strong>blogs professionnels</strong> ouvre une nouvelle ère pour
              l'<strong>optimisation SEO</strong>. L'intégration de ces
              technologies puissantes offre une flexibilité inégalée pour gérer
              un contenu dynamique. Cette synergie permet une évolutivité sans
              pareille, permettant l'ajout régulier de contenu pour un
              <strong>SEO</strong> efficace et durable.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/blog-seo-itti.webp"
                alt="mockup-pc-blog"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Section: Content-->
      <section class="container my-5 pb-lg-5">
        <!-- Grid row -->
        <div class="block-row reverse">
          <!-- Grid column -->
          <div class="block-text slide-right col-lg-5">
            <!-- Category -->
            <!-- Post title -->
            <TitleColor
              title="Une stratégie SEO évoluée "
              titleColor="#f27316"
              bgColor="hsl(263, 47%, 58%)"
            />
            <!-- Excerpt -->
            <p>
              Notre expertise technique associée à la structure des frameworks
              Vue.js et Symfony 6 favorise une
              <strong>approche stratégique en SEO</strong>. La capacité à cibler
              efficacement une variété de <strong>mots-clés (google)</strong>,
              des
              <strong>opportunités à court traîne aux longues traînes</strong>,
              renforce la visibilité et la pertinence de votre contenu. Cette
              stratégie proactive assure une position solide dans les
              <strong>résultats des moteurs de recherche</strong>, stimulant la
              <strong>croissance organique de votre audience cible</strong>.
            </p>
          </div>
          <!-- Grid column -->
          <!-- Grid column -->
          <div class="col-lg-6">
            <!-- Featured image -->
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/blog-seo-nubetec.webp"
                alt="pc-screen-site-web"
                class="img-fluid"
              />
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
    </article>
    <CallActionServiceSolo
      img="blog-pro-seo.webp"
      h3="Réalisons ensemble votre projet Numérique"
      webServiceValue="Création blog professionnel"
      h3Color="#ebdcdc"
      p="Sentez vous libre de nous contacter pour toute question concernant la création d'un blog professionnel, notre équipe vous apportera les réponses adaptées à
        votre projet web."
    />
    <FooterOption />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import TitleColor from "@/components/partials/TitleColor.vue";
import CallActionServiceSolo from "@/components/partials/CallActionServiceSolo.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";
export default {
  name: "BlogPro",
  components: {
    TitleColor,
    CallActionServiceSolo,
    NavOption,
    FooterOption,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Créer un blog",
      description: [
        {
          content:
            "Explorez notre blog dynamique, propulsé par Vue.js et Symfony 6. Découvrez des articles SEO-friendly offrant des insights précieux sur le développement web moderne, les bonnes pratiques SEO et les dernières tendances technologiques.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/prix-site-blog",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.main-vitrine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100%;
  background: #f12711;
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  background: linear-gradient(to right, #f5af19, #f12711);

  & .hero-banner {
    //@include background-svg;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    color: $color-text;
    padding: 40px 20px;
    @media screen and (min-width: 992px) {
      padding: 0px 0px 70px 20px;
    }
    & .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
      }
    }
    &__img {
      @include image-fade-in(2s);
      & img {
        @media screen and (max-width: 1245px) {
          width: 100%;
        }
      }
    }

    & .hero-banner__speech .btn {
      position: relative;
      overflow: hidden;
      z-index: 9;
    }
    & .hero-banner__speech .btn-slide {
      background-color: rgba(0, 0, 0, 0.1);
      color: $color-text;
    }
    & .hero-banner__speech .btn-slide:before {
      content: "";
      background: #f27316;
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      -ms-transition: all 0.5s ease-in-out 0s;
      -o-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
    }
    & .hero-banner__speech .btn-slide:hover:before {
      width: 100%;
    }

    & .hero-banner__speech {
      @media screen and (min-width: 992px) {
        padding-left: 50px;
      }
      & h1 {
        & .vue {
          color: #42c288;
        }
        & .php {
          color: $color-darker;
        }
      }
      & p {
        text-align: start;
      }
      & a {
        margin: 15px 0px 20px 0px;
      }
    }
  }
  & .row-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &__cadre {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;
      min-height: 100%;
      padding: 10px 20px;
      text-align: center;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
      border-radius: 59px;
      border: 0.1px outset $color-lighter;
      box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
      & i {
        color: $color-primary;
        font-size: 2em;
        border-radius: 100%;
        border: 0.5px outset $color-primary;
        padding: 18px;
      }
      & h4 {
        font-size: 1.3em;
        color: $color-primary;
      }
      & p {
        text-align: center;
        font-size: 0.9em;
      }
    }
  }
  & article {
    & h2 {
      text-align: center;
      color: $color-text;
      margin: 80px 10px 50px;
      font-size: 2em;
      text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
    }
  }
  .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
  .callAction {
    margin: 20px 0px 0px 0px;
  }
}
</style>
