<template>
  <div class="container my-2 my-lg-3 p-3 p-lg-5">
    <!--Section: Content-->
    <div class="expert">
      <!-- Section heading -->
      <h2 class="text-center mb-4 pb-2 fall-down">
        Notre expertise à vos côtés.
      </h2>
      <!-- Section description -->
      <p class="text-center mx-auto mb-5 image-fade-in">
        Spécialisés dans le <strong>développement web</strong>, nous choisirons
        la technologie la plus adaptée à votre
        <strong>projet digital</strong> afin de minimiser les coûts et les
        <strong>délais de développement</strong> pour votre entreprise.
      </p>

      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-lg-5 text-lg-left mb-5 slide-left">
          <img
            class="img-fluid"
            src="@/assets/pictures/digitalisation.webp"
            alt="drawing-people-work-together"
          />
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-7">
          <!-- Grid row -->
          <div class="row mb-3 expert__block">
            <!-- Grid column -->
            <div class="col-1">
              <i class="fas fa-share"></i>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-xl-10 col-md-11 col-10 expert__card slide-right">
              <h3 class="font-weight-bold mb-3">Sécurité</h3>
              <p class="">
                Chez Malaweb, peu importe l'ampleur de votre
                <strong>projet numérique</strong> ou la taille de votre
                entreprise, <strong>sécuriser votre application web</strong> ou
                votre <strong>site Internet</strong> est notre priorité.
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->

          <!-- Grid row -->
          <div class="row mb-3 expert__block">
            <!-- Grid column -->
            <div class="col-1">
              <i class="fas fa-share"></i>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-xl-10 col-md-11 col-10 expert__card slide-right">
              <h3 class="font-weight-bold mb-3">Technologie</h3>
              <p class="">
                Travailler avec nous c'est l'assurance de voir son
                <strong>projet digital</strong> développer avec des technologies
                modernes et dont l'efficacité n'est plus à prouver. (Vuejs,
                Symfony, React Native, <strong>WordPress</strong>...).
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->

          <!--Grid row-->
          <div class="row expert__block">
            <!-- Grid column -->
            <div class="col-1">
              <i class="fas fa-share"></i>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-xl-10 col-md-11 col-10 expert__card slide-right">
              <h3 class="mb-3">Suivi de projet</h3>
              <p>
                Loin d'être un simple prestataire qui réalise la tâche pour
                laquelle elle a été rémunérée, l'agence malaweb s'inscrit comme
                un véritable partenaire de vos
                <strong>projets numériques</strong>.
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!--Grid row-->
        </div>
        <!--Grid column-->
      </div>
      <!-- Grid row -->
    </div>
    <!--Section: Content-->
  </div>
</template>

<script>
export default {
  name: "HomeService",
};
</script>

<style lang="scss" scoped>
.expert {
  margin: 0px 0px 100px;
  & img {
    @include image-slide-in-left(2s);
  }
  & p {
    color: $bold-light;
  }
  & i {
    color: $color-primary;
    margin-right: 5px;
  }
  &__block:hover {
    @include grow;
    cursor: pointer;
    & i {
      background: $color-darker;
      border: 0.1px $color-primary inset;
      border-radius: 50%;
      padding: 5px;
    }
  }
  &__card {
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    box-shadow: 0px 30px 40px -20px $color-text-grey;
    & h3 {
      font-size: 1.3em;
    }
    & p {
      color: $bold-light;
      font-size: 1em;
    }
    &:hover {
      & h3 {
        color: $color-primary;
      }
    }
  }
}
</style>
