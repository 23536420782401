<template>
  <section class="my-5 p-3 p-lg-5 text-center home-about">
    <div class="home-about__header fall-down">
      <h2 class="text-center font-weight-bold mb-4 pb-2">L'Agence Web</h2>
      <p class="text-center text-muted w-responsive mx-lg-3 mb-5">
        Malaweb c'est l'association d'experts du
        <strong>développement web et mobile</strong>, engagés au quotidien à vos
        côtés dans la réussite de votre <strong>projet digital</strong>.
      </p>
    </div>
    <div class="row">
      <div class="col-md-12 mb-4 image-fade-in">
        <div class="card card-image home-about__hero">
          <div
            class="text-white text-center rgba-black-strong py-5 px-4 px-md-5 rounded office"
          >
            <div class="home-about__text-hero">
              <p class="text-center m-auto pb-5">
                <i class="fas fa-heart"></i>
                <strong> Made with love</strong>
                <br />By<br />
                <strong class="jaapokki">Malaweb</strong>
              </p>
              <p class="pb-3 text-center">
                Travailler avec nous c'est la garantie d'avoir une équipe dédiée
                à 100 % à votre <strong>projet numérique</strong> !
              </p>
              <a href="/about" class="btn btn-secondary btn-rounded btn-md mt-5"
                ><i class="fas fa-clone left"></i> Découvrir</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-5 my-5 z-depth-1">
      <!--block2-->
      <div class="container">
        <!--Section: Content-->
        <div class="mb-5 home-about__twin-card">
          <!-- Grid row -->
          <div class="row">
            <div class="col-12">
              <div class="card z-depth-2">
                <div class="card-body p-0">
                  <div class="row mx-0 home-about__first">
                    <div
                      class="col-lg-6 black rounded-left py-5 px-md-5 slide-left"
                    >
                      <h3 class="ml-3 mb-4 pb-2">Nos avantages</h3>
                      <ul class="fa-ul mb-0">
                        <li class="mb-2">
                          <span class="fa-li"><i class="fas fa-check"></i></span
                          ><strong>Coût de développement réduit</strong>
                        </li>
                        <li class="mb-2">
                          <span class="fa-li"><i class="fas fa-check"></i></span
                          >Disponibilité au quotidien
                        </li>
                        <li class="mb-2">
                          <span class="fa-li"><i class="fas fa-check"></i></span
                          >Mobilité sur toute la France
                        </li>
                        <li class="mb-2">
                          <span class="fa-li"><i class="fas fa-check"></i></span
                          >Une équipe dédiée à votre projet
                        </li>
                        <li>
                          <span class="fa-li"><i class="fas fa-check"></i></span
                          >La satisfaction de nos clients
                        </li>
                      </ul>
                    </div>
                    <div
                      class="home-about__second col-lg-6 red accent-3 rounded-right py-5 px-md-5 slide-right"
                    >
                      <h3 class="ml-3 mb-4 pb-2">Vos avantages</h3>
                      <ul class="fa-ul mb-0">
                        <li class="mb-2">
                          <span class="fa-li"><i class="fas fa-check"></i></span
                          ><strong>Gagner en visibilité</strong>
                        </li>
                        <li class="mb-2">
                          <span class="fa-li"><i class="fas fa-check"></i></span
                          >Générer de nouveaux revenus
                        </li>
                        <li class="mb-2">
                          <span class="fa-li"><i class="fas fa-check"></i></span
                          >Élargir votre champ d'action
                        </li>
                        <li class="mb-2">
                          <span class="fa-li"><i class="fas fa-check"></i></span
                          >Affirmer votre image
                        </li>
                        <li>
                          <span class="fa-li"><i class="fas fa-check"></i></span
                          >Des coûts maîtrisés
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Grid row -->
        </div>
        <!--Section: Content-->
      </div>

      <!--block3-->
      <div class="p-md-3 mx-md-5">
        <div
          class="row m-lg-5 d-flex justify-content-between align-items-center"
        >
          <div class="col-lg-6 mb-4 slide-left">
            <h3 class="mb-3">Logiciel SaaS</h3>
            <p class="pt-3">
              Vous souhaitez <strong>créer un logiciel</strong> afin de
              faciliter le quotidien de votre entreprise (<strong>CRM</strong>,
              <strong>ERP</strong>...) ? Nous vous proposons des solutions
              permettant de gérer divers aspects de votre entreprise (stocks,
              ressources humaines, relation client, prospection...). Parlons-en
              ensemble afin que l'on puisse comprendre la logique de votre
              métier et trouver la solution la plus adaptée à vos équipes dans
              leur quotidien.
            </p>
          </div>
          <div
            class="col-lg-6 col-lg-4 d-flex justify-content-center mb-md-0 mb-5 slide-right"
          >
            <img
              src="@/assets/pictures/mobile_app.webp"
              alt="developpement"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="row pt-3 home-about__features">
          <div class="col-lg-3 col-md-6 mb-5 slide-left">
            <img
              src="@/assets/icons/erp.webp"
              alt="erp"
              class="mb-3"
              width="70px"
            />
            <h4>ERP</h4>
            <p class="mb-lg-0">
              Centralisez les données de votre entreprise et partagez les entre
              vos équipes sur le terrain.
            </p>
          </div>
          <div class="col-lg-3 col-md-6 mb-5 slide-in-left">
            <img
              src="@/assets/icons/crm.webp"
              alt="erp"
              class="mb-3"
              width="70px"
            />
            <h4>CRM</h4>
            <p class="mb-lg-0">
              Gérez efficacement votre <strong>relation client</strong> et votre
              <strong>marketing</strong> en temps réel.
            </p>
          </div>
          <div class="col-lg-3 col-md-6 mb-5 slide-in-right">
            <img
              src="@/assets/icons/intranet.webp"
              alt="network"
              class="mb-3"
              width="70px"
            />
            <h4>Intranet</h4>
            <p class="mb-md-0">
              Partagez des informations au sein de votre entreprise sur votre
              réseau privé interne.
            </p>
          </div>
          <div class="col-lg-3 col-md-6 mb-5 slide-right">
            <img
              src="@/assets/icons/extranet.webp"
              alt="cloud-network"
              class="mb-3"
              width="70px"
            />
            <h4>Extranet</h4>
            <p class="mb-md-0">
              Offrez une interface sécurisée à vos différents partenaires et
              clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeAbout",
};
</script>

<style lang="scss" scoped>
.home-about {
  &__header {
  }
  &__hero {
    & .office {
      background: url("@/assets/pictures/workspace.webp");
      @include background-responsive;
    }
  }
  &__text-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & p:first-child {
      font-size: 1em;
      & i {
        color: rgb(197, 6, 38);
        font-size: 1.5em;
      }
      & strong {
        &:nth-child(2) {
          font-size: 1em;
          margin-right: 20px;
        }
        &:last-child {
          font-size: 1.4em;
          background: rgba(53, 51, 51, 0.479);
          border-radius: 50px;
          padding: 3px 20px;
          line-height: 50px;
        }
      }
    }
    & p:nth-child(2) {
      width: fit-content;
      font-size: 1.1em;
      color: #fff;
      background: rgba(53, 51, 51, 0.479);
      margin: auto;
      padding: 10px 20px;
      border-radius: 20px;
      text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
    }
    & a {
      background: $bold-light-primary;
      width: fit-content;
      margin: auto;
      &:hover {
        background: $color-primary;
        border-color: $color-text;
      }
    }
  }
  &__twin-card {
    color: $color-text;
    & i {
      color: green;
    }
  }
  &__first {
    background: $color-darker;
  }
  &__second {
    background: $color-primary;
  }
  &__features {
    display: flex;
    justify-content: space-around;
    color: $color-text;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & div {
      margin-top: 40px;
      padding: 10px 15px;
    }
    & div:nth-child(2),
    div:nth-child(4) {
      background: rgba(31, 30, 30, 0.74);
    }
    & div:nth-child(1),
    div:nth-child(3) {
      background: #f27216cc;
    }
    & div:nth-child(1) {
      border-radius: 20px 0px 0px 20px;
    }
    & div:nth-child(4) {
      border-radius: 0px 20px 20px 0px;
    }
    & p {
      font-size: 1em;
      text-align: center;
    }
  }
}
</style>
