<template>
  <div class="container my-5">
    <section class="text-center">
      <div class="header pb-2 mb-4">
        <h2 class="">Prix site vitrine</h2>

        <p class="text-center mb-5">
          Avec la <strong>création d'un site vitrine</strong>,
          <strong>gagnez en visibilité</strong> et
          <strong>affirmez votre image</strong> de marque sur le web.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12 mb-4">
          <div class="card pricing-card">
            <div class="pricing-card__title">
              <p class="option">BASIC</p>
            </div>

            <div class="card-body">
              <div class="d-flex justify-content-center">
                <div class="head-body mb-4">
                  <p class="price">1450</p>
                  <span class="euro">€</span>
                </div>
                <div class="b-ttc">
                  <p class="ttc"><span>/</span>ttc</p>
                </div>
              </div>
              <ul>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span>BRANDING :</span> <br /><strong
                      >Identité visuelle</strong
                    >
                    exclusive (<strong>naming</strong>/<strong>logo</strong>)
                    pour renforcer la reconnaissance de votre marque.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span>TECHNOLOGIE :</span> <br /><strong>WordPress</strong>
                    est une solution robuste et polyvalente pour la
                    <strong>création de sites web</strong>. Cette option vous
                    offre l'accès à cette technologie renommée pour sa
                    convivialité, sa flexibilité et sa facilité de gestion.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span>PAGE :</span> <br />Comprises dans cette offre, trois
                    pages essentielles pour votre <strong>site web</strong> : la
                    page d'accueil, une pour présenter vos services et une
                    dédiée aux informations de contact.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iOrange" aria-hidden="true"></i>
                    <span>FORMULAIRE :</span> <br />Création d'un formulaire de
                    contact, un outil indispensable pour permettre à vos
                    visiteurs de vous contacter directement depuis votre
                    <strong>site web</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    MODULES : <br />Cette option propose des modules
                    additionnels en dehors de l'offre standard. Ces modules
                    peuvent être personnalisés selon vos besoins spécifiques
                    pour enrichir les fonctionnalités de
                    <strong>votre site web</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    HEBERGEMENT : <br />L'<strong
                      >hébergement sur notre serveur web</strong
                    >
                    assure des performances optimales pour
                    <strong>votre site internet</strong>. Il garantit une
                    qualité de serveur fiable, assurant ainsi la disponibilité
                    et la rapidité de votre <strong>site vitrine</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    SEO : <br />Inclus l'<strong
                      >optimisation SEO pour les moteurs de recherche</strong
                    >. Une
                    <strong
                      >visibilité accrue sur les moteurs de recherche</strong
                    >
                    est essentielle pour attirer un trafic qualifié vers votre
                    <strong>site web</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-close px-2 iOrange" aria-hidden="true"></i>
                    SEA : <br />Le
                    <strong>référencement payant</strong> (<strong>SEA</strong>)
                    peut être ajouté à votre offre pour améliorer davantage la
                    visibilité de votre <strong>site web</strong> sur les
                    <strong>moteurs de recherche</strong> via des
                    <strong>annonces payantes</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
              </ul>
            </div>
            <div class="card-footer">
              <CyberBtn link="/prix-site-vitrine-wordpress" />
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 mb-4">
          <div class="card pricing-card">
            <div class="pricing-card__second">
              <p class="option">PRO</p>
            </div>

            <div class="card-body">
              <div class="d-flex justify-content-center">
                <div class="head-body mb-4">
                  <p class="price">2400</p>
                  <span class="euro">€</span>
                </div>
                <div class="b-ttc">
                  <p class="ttc"><span>/</span>ttc</p>
                </div>
              </div>
              <ul>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>BRANDING :</span> <br />La
                    <strong>création d'une identité visuelle</strong>
                    distinctive, combinant design et <strong>logo</strong> sur
                    mesure, renforce la notoriété et la reconnaissance de votre
                    marque.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>TECHNOLOGIE :</span> <br />Basé sur le puissant
                    framework VueJS, votre <strong>site web</strong> bénéficie
                    d'une interactivité avancée, offrant une
                    <strong>expérience utilisateur</strong>
                    fluide et moderne.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>PAGE :</span> <br />Présentez votre entreprise de
                    manière complète avec quatre pages essentielles : Accueil, À
                    propos, Services et Contact. Chaque section est
                    soigneusement conçue pour capturer l'essence de votre
                    activité.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>FORMULAIRE :</span> <br />Le formulaire de contact et
                    un call-to-action intégrés favorisent l'interaction des
                    visiteurs. Ces éléments essentiels stimulent l'engagement et
                    facilitent la prise de contact avec vos
                    <strong>prospects</strong>
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>MODULES :</span> <br />Offrant des fonctionnalités sur
                    mesure, cette option vous permet d'ajouter des modules
                    spécifiques adaptés à vos besoins, renforçant ainsi la
                    flexibilité et la personnalisation de votre
                    <strong>site internet</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>HEBERGEMENT :</span> <br />Bénéficiez d'un an
                    d'<strong>hébergement web gratuit</strong>, garantissant des
                    performances optimales et une fiabilité accrue. Votre
                    <strong>site internet</strong> reste toujours disponible,
                    offrant une <strong>expérience utilisateur fluide</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>SEO :</span> <br />L'<strong>optimisation SEO</strong>
                    complète pour les quatre pages de votre
                    <strong>site web </strong>
                    <strong
                      >améliore sa visibilité sur les moteurs de
                      recherche</strong
                    >, maximisant ainsi son potentiel d'audience et de
                    <strong>clients potentiels</strong>.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>SEA :</span> <br />Avec le paramétrage offert pour une
                    <strong>campagne publicitaire google</strong>, augmentez la
                    portée et la visibilité de votre
                    <strong>site internet</strong> auprès d'un public ciblé,
                    accroissant ainsi son impact et ses conversions.
                  </p>
                  <div class="hr"></div>
                </li>
                <li>
                  <p>
                    <i class="fa fa-check iBlack" aria-hidden="true"></i>
                    <span>SOCIAL MEDIA :</span> <br />Profitez d'une présence
                    étendue grâce à la création de comptes sur les réseaux
                    sociaux (FB, Insta, LinkedIn...), renforçant ainsi votre
                    visibilité et votre connexion avec votre audience.
                  </p>
                  <div class="hr"></div>
                </li>
              </ul>
            </div>
            <div class="card-footer">
              <CyberBtn link="/prix-site-vitrine" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <article>
    <section class="container mt-5 mb-3">
      <div class="article-title container-lg">
        <h3>Créez Votre Site Vitrine pour Affirmer Votre Présence en Ligne</h3>
      </div>
      <div class="block-row">
        <div class="block-text col-lg-5 slide-left">
          <p class="text-left mx-auto w-responsive">
            La conception d'un <strong>site vitrine</strong> est une étape
            cruciale pour toute entreprise cherchant à se démarquer sur le web.
            Notre expertise en création de <strong>sites web</strong> vous
            garantit un <strong>site vitrine</strong> professionnel,
            parfaitement adapté à vos besoins et véhiculant une image forte et
            affirmée. Votre présence sur le web via un
            <strong>site internet</strong> est un gage de crédibilité aux yeux
            de vos <strong>clients potentiels</strong>.
          </p>
          <ArrowBtn />
        </div>
        <div class="col-lg-6">
          <div class="view overlay rounded z-depth-2 mb-lg-0 my-2 slide-left">
            <img
              src="@/assets/pictures/buildTeam.webp"
              alt="team-building-site-web"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="container my-5">
      <div class="block-row reverse">
        <div class="block-text slide-right col-lg-5">
          <p>
            C'est également une opportunité sans pareille pour promouvoir vos
            services auprès d'une audience plus vaste. Un
            <strong>site vitrine</strong> bien conçu renforce la confiance de
            vos visiteurs et offre une
            <strong>vitrine numérique</strong> professionnelle, facilitant ainsi
            la découverte de vos offres par un public élargi. Cette plateforme
            digitale devient alors un outil puissant pour
            <strong>attirer de nouveaux clients</strong> et consolider vos
            relations avec votre clientèle existante.
          </p>
          <ArrowBtn
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>

        <div class="col-lg-6">
          <div class="view overlay rounded z-depth-2 mb-lg-0 my-2 slide-left">
            <img
              src="@/assets/pictures/dev-web.webp"
              alt="build-site-internet"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  </article>
  <CallActionService linkBtnFooter="/prix-site-vitrine" />
</template>

<script>
import { useMeta } from "vue-meta";
import CyberBtn from "@/components/partials/CyberBtn.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
export default {
  components: { CyberBtn, CallActionService },
  setup() {
    useMeta({
      title: "Développement web",
      description: [
        {
          content:
            "Découvrez notre expertise en développement web sur mesure. De la création de sites vitrine élégants à la conception de blogs informatifs et aux plateformes e-commerce convaincantes, notre agence offre des solutions personnalisées pour booster votre présence en ligne et maximiser votre impact dans le monde digital.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/developpement-web",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.header {
  & h2 {
    width: 100%;
    margin: auto;
    background-color: $color-logo;
    color: $color-primary;
    border: 0.2px outset $color-primary;
    border-radius: 20px;
    padding: 4px 50px;
    margin-bottom: 20px;
  }

  & p {
    color: $color-text;
  }
}
.card {
  min-height: 100%;
  border-radius: 8px 8px 0px 0px;
}
.pricing-card {
  background: $background;
  &__title {
    background: $color-primary;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-lighter;
      text-align: center;
    }
  }
  &__second {
    background: $color-darker;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-primary;
      text-align: center;
    }
  }
  & .card-body {
    padding: 1rem 0rem;

    & .head-body {
      display: flex;
      //justify-content: center;
      align-items: flex-start;
      /*background-color: $color-darker;
      border: 0.3px outset $color-primary;
      border-radius: 10px 0px 10px 0px;
      padding: 5px 50px;
      width: fit-content;*/
      &__orange {
        /*background-color: $color-primary;
        border: 0.3px outset $color-darker;
        border-radius: 0px 10px 0px 10px;
        padding: 5px 50px;
        width: fit-content;*/
        margin: auto;
      }
    }
    & .price {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      font-size: 2em;
      font-weight: 100;
      color: $color-secondary;
      margin: 0px;
    }
    & .euro {
      font-size: 0.6em;
      line-height: -50px;
      color: $color-secondary;
    }
    & .b-ttc {
      position: relative;
      bottom: 1px;
      color: $bold-light;
      & .ttc {
        font-size: 0.8em;
        margin-bottom: 0px;
        line-height: 55px;
        margin-left: -5px;
        & span {
          font-size: 1.3em;
        }
      }
    }
    & ul {
      list-style-type: none;
      & li {
        & p {
          font-size: 1em;
          margin-bottom: 0px;
          color: $bold-light;
          & i {
            padding: 5px;
            border-radius: 100%;
            margin-right: 10px;
          }
          & span {
            color: $color-secondary;
          }
          & .iOrange {
            color: $color-lighter;
            background-color: $color-primary;
          }
          & .iBlack {
            color: $color-primary;
            background-color: $color-darker;
          }
        }
        & .hr {
          width: 80%;
          height: 1px;
          background-color: $bold-light;
          margin: 10px auto;
        }
      }
    }
  }
  & .card-footer {
    background: $color-darker;
    border: none;
    padding: 15px 0px;
  }
}
article {
  & h3 {
    text-align: center;
    color: $color-text;
    margin: 100px 10px 50px;
    font-size: 2em;
    text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
  }
  & .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 40px;
      @media screen and (min-width: 992px) {
        width: 500px;
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
}
</style>
