<template>
  <div class="container-fluid">
    <div class="wrapper">
      <div class="typing-demo">Le web comme jamais !</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "textHiting",
};
</script>

<style lang="scss" scoped>
.wrapper {
  /*This part is important for centering*/
  display: grid;
  place-items: center;
}

.typing-demo {
  width: 22ch;
  animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 1.2em;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
</style>
