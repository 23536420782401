import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import MentionLegal from "@/views/MentionLegal.vue";
import HomeView from "@/views/HomeView.vue";
import AboutView from "@/views/AboutView.vue";
import ServicesView from "@/views/ServicesView.vue";
import DevWebView from "@/views/DevWebView.vue";
import VitrineWordpress from "@/views/vitrine/VitrineWordpress";
import VitrinePro from "@/views/vitrine/VitrinePro";
import BlogWordpress from "@/views/blog/BlogWordpress";
import BlogPro from "@/views/blog/BlogPro";
import EcommerceWordpress from "@/views/e-commerce/EcommerceWordpress";
import EcommercePro from "@/views/e-commerce/EcommercePro";
import ErpSaas from "@/views/saas/ErpSaas";
import CrmSaas from "@/views/saas/CrmSaas";
import AppMobileView from "@/views/AppMobileView.vue";
import PwaMobile from "@/views/mobile/PwaMobile";
import IosAndroid from "@/views/mobile/IosAndroid";
import SeoSeaView from "@/views/SeoSeaView.vue";
import SeoRef from "@/views/referencement/SeoRef";
import SeaRef from "@/views/referencement/SeaRef";
import MarketingDigitalView from "@/views/MarketingDigitalView.vue";
import MarketingServices from "@/views/marketing/MarketingServices.vue";
import BlogView from "@/views/BlogView.vue";
import ContactView from "@/views/ContactView.vue";
import RegisterView from "@/views/RegisterView.vue";
import LoginView from "@/views/LoginView.vue";
import PostView from "@/views/PostView.vue";
import TownView from "@/views/TownView.vue";
import CreationTownView from "@/views/CreationTownView.vue";
import ProfileView from "@/views/ProfileView.vue";
import EditPostView from "@/views/EditPostView.vue";
import CreatePostView from "@/views/CreatePostView.vue";
import CreateTownView from "@/views/CreateTownView.vue";
import DashBoard from "@/views/malazone/DashBoard.vue";
import MalaTowns from "@/views/malazone/MalaTowns.vue";
import MalaUsers from "@/views/malazone/MalaUsers.vue";
import MalaCats from "@/views/malazone/MalaCats.vue";
import MalaTags from "@/views/malazone/MalaTags.vue";
import MalaPosts from "@/views/malazone/MalaPosts.vue";
import MalaMedias from "@/views/malazone/MalaMedias.vue";

const routes = [
  {
    path: "/mentions",
    name: "mentions",
    component: MentionLegal,
  },

  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/home",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/services",
    name: "services",
    component: ServicesView,
  },
  {
    path: "/developpement-web",
    name: "developpement-web",
    component: DevWebView,
  },
  {
    path: "/prix-site-vitrine-wordpress",
    name: "VitrineWordpress",
    component: VitrineWordpress,
  },
  {
    path: "/prix-site-vitrine",
    name: "VitrinePro",
    component: VitrinePro,
  },
  {
    path: "/prix-site-blog-wordpress",
    name: "BlogWordpress",
    component: BlogWordpress,
  },
  {
    path: "/prix-site-blog",
    name: "BlogPro",
    component: BlogPro,
  },
  {
    path: "/prix-site-e-commerce-wordpress",
    name: "EcommerceWordpress",
    component: EcommerceWordpress,
  },
  {
    path: "/prix-site-e-commerce",
    name: "EcommercePro",
    component: EcommercePro,
  },
  {
    path: "/saas-erp",
    name: "ErpSaas",
    component: ErpSaas,
  },
  {
    path: "/saas-crm",
    name: "CrmSaas",
    component: CrmSaas,
  },
  {
    path: "/application-mobile",
    name: "application-mobile",
    component: AppMobileView,
  },
  {
    path: "/prix-pwa-mobile",
    name: "PwaMobile",
    component: PwaMobile,
  },
  {
    path: "/prix-application-mobile",
    name: "IosAndroid",
    component: IosAndroid,
  },
  {
    path: "/referencement-google",
    name: "referencement-google",
    component: SeoSeaView,
  },
  {
    path: "/prix-referencement-seo",
    name: "SeoRef",
    component: SeoRef,
  },
  {
    path: "/prix-referencement-sea",
    name: "SeaRef",
    component: SeaRef,
  },
  {
    path: "/marketing-digital",
    name: "marketing-digital",
    component: MarketingDigitalView,
  },
  {
    path: "/prix-marketing-digital",
    name: "MarketingServices",
    component: MarketingServices,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/update/:slug",
    name: "edit",
    component: EditPostView,
  },
  {
    path: "/new-article",
    name: "create",
    component: CreatePostView,
  },
  {
    path: "/new-town/",
    name: "UploadTown",
    component: CreateTownView,
  },
  {
    path: "/article/:slug",
    name: "article",
    component: PostView,
  },
  {
    path: "/ma-ville/:slug",
    name: "ma-ville",
    component: TownView,
  },
  {
    path: "/creation-site-internet/:town/:id",
    name: "CreationTownView",
    component: CreationTownView,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
    meta: { guest: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { guest: true },
  },
  {
    path: "/profile/:pseudo",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/malazone",
    name: "malazone",
    component: DashBoard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/users",
    name: "mala-users",
    component: MalaUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/villes",
    name: "mala-towns",
    component: MalaTowns,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/posts",
    name: "mala-posts",
    component: MalaPosts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/categories",
    name: "mala-cats",
    component: MalaCats,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/tags",
    name: "mala-tags",
    component: MalaTags,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/medias",
    name: "mala-medias",
    component: MalaMedias,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 });
    });
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/home");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
