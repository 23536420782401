<template>
  <section class="editPost">
    <div class="text-center pb-5">
      <form class="formPost" @submit.prevent="submitPost">
        <div class="formPost__header container">
          <label for="town">Ville cible :</label>
          <input type="text" id="town" name="town" v-model="form.town" />
        </div>
        <div class="formPost__header container">
          <label for="title">Titre de l'article :</label>
          <input type="text" id="title" name="title" v-model="form.title" />
        </div>
        <div class="formPost__header container">
          <textarea
            id="meeting"
            name="meeting"
            class="form-control md-textarea"
            rows="3"
            pattern="^[^/<>\n]*$"
            maxlength="450"
            required
            placeholder="Lieu de rencontre"
            v-model="form.meeting"
          ></textarea>
        </div>
        <!--<textarea id="content" name="content" v-model="Post.content"> </textarea>-->
        <button type="submit" class="edit_btn">Enregistrer la ville</button>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UploadTown",
  components: {},
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      form: {
        town: "",
        title: "",
        meeting: "",
      },
    };
  },
  methods: {
    ...mapActions(["CreateTown"]),
    async submitPost() {
      try {
        const data = {
          town: this.form.town,
          title: this.form.title,
          content: this.form.meeting,
        };
        await this.CreateTown(data).then(() => {
          // Redirection ici
          this.$router.push("/malazone");
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hr {
  width: 75%;
  height: 0.5px;
  margin: 100px auto;
  background-color: $color-primary;
}
.editPost {
  margin-bottom: 100px;
}
.formPost {
  &__header {
    display: flex;
    flex-direction: column;
    margin: 50px auto;
    & label {
      text-align: left;
      font-size: 1.9em;
      position: relative;
      width: fit-content;
      left: 10%;
      color: $bold-light-primary;
    }
    & input {
      width: 80%;
      margin: auto;
      padding: 10px 20px;
      border: 0.1px $color-primary solid;
      border-radius: 20px;
      background-color: $color-darker;
      color: $color-primary;
      text-align: center;
    }
  }
}
.edit_btn {
  width: fit-content;
  background: $color-primary !important;
  color: $color-text !important;
  border: 0.3px $color-text outset;
  border-radius: 30px;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
  margin: 60px 10px;
  &:hover {
    background: $color-darker !important;
    color: $color-primary !important;
    border: 0.3px $color-text inset;
    transform: scale(95%);
  }
}
</style>
