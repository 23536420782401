<template>
  <header class="contact-header p-4">
    <h1 class="fall-down">Nous contacter</h1>
    <p class="image-fade-in">
      une question sur le <strong>prix d'un site internet ?</strong> d'une
      <strong>application web</strong><span class="fake-space">?</span
      ><br />N'hésitez pas on répond à toutes vos questions.
    </p>
  </header>
  <TriangleSeparator />
</template>

<script>
import TriangleSeparator from "@/components/partials/TriangleSeparator.vue";
export default {
  name: "ContactHeader",
  components: { TriangleSeparator },
};
</script>

<style lang="scss" scoped>
.contact-header {
  height: 50vh;
  @include background-svg;
  @include background-responsive;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & h1 {
    color: $color-primary;
    font-size: 3em;
    text-align: center;
  }
  & p {
    color: $color-text;
    font-size: 1.3em;
    text-align: center;
    & .fake-space {
      margin-left: 8px;
    }
  }
}
</style>
