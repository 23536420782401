<template>
  <div
    class="social_media d-flex flex-column justify-content-between align-items-center pt-2 mb-4 mb-md-4"
  >
    <div class="follow">
      <p class="jaapokki mt-md-3 mt-2 center-text">Follow us</p>
    </div>
    <div>
      <a
        href="https://www.facebook.com/Malaweb.fb"
        aria-label="link to facebook"
        class="footer-icon"
      >
        <i class="fab fa-facebook-f fa-lg white-text mx-2"> </i>
      </a>
      <a
        href="https://www.instagram.com/malaweb.fr?igsh=OGQ5ZDc2ODk2ZA=="
        aria-label="link to instagram"
        class="footer-icon"
      >
        <i class="fab fa-instagram fa-lg white-text mx-2"> </i>
      </a>
      <a
        href="https://wa.me/0765721135"
        aria-label="link to whatsapp"
        class="footer-icon"
      >
        <i class="fab fa-whatsapp fa-lg white-text mx-2"> </i>
      </a>
    </div>

    <div></div>

    <div>
      <a
        href="https://www.snapchat.com/add/malaweb.fr?share_id=OJIZNm3XtZ8&locale=fr-FR"
        aria-label="link to snapchat"
        class="footer-icon"
      >
        <i class="fab fa-snapchat-ghost white-text fa-lg mx-2"></i>
      </a>
      <a
        href="https://www.linkedin.com/company/malaweb"
        aria-label="link to linkedin"
        class="footer-icon"
      >
        <i class="fab fa-linkedin-in fa-lg white-text mx-2"> </i>
      </a>

      <a
        href="https://www.pinterest.fr/MalawebFrance/"
        aria-label="link to pinterest"
        class="footer-icon"
      >
        <i class="fab fa-pinterest fa-lg white-text mx-2"> </i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialMedia",
};
</script>

<style lang="scss" scoped>
.follow {
  & p {
    font-size: 1.25rem;
  }
  & .hr {
    width: 100%;
    height: 2px;
    background: $color-primary;
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 5px;
  }
}
.social_media {
  & div {
    margin: 5px;
  }
  & i:not(.fa-facebook-f) {
    width: 37px;
    color: $color-primary;
    background: rgba($color: $color-secondary, $alpha: 0.3);
    padding: 9px;
    border-radius: 10px;
  }
  & .fa-facebook-f {
    width: 37px;
    color: $color-primary;
    background: rgba($color: $color-secondary, $alpha: 0.3);
    padding: 9px 11px;
    border-radius: 10px;
    &:hover {
      background: #3b5998;
      color: $color-text;
    }
  }
  & .fa-instagram:hover {
    background: #d6249f;
    background: radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 40%,
      #d6249f 60%,
      #285aeb 95%
    );
    color: $color-text;
  }
  & .fa-twitter:hover {
    background: #1da1f2;
    color: $color-text;
  }
  & .fa-whatsapp:hover {
    background-color: #25d366;
    color: $color-text;
  }
  & .fa-snapchat-ghost:hover {
    background: #fffb00bd;
    color: $color-text;
  }
  & .fa-linkedin-in:hover {
    background: #007bb5;
    color: $color-text;
  }
  & .fa-pinterest:hover {
    background: #bd081c;
    color: $color-text;
  }
}
</style>
