<template>
  <div class="mentions-legales container my-5 py-5">
    <h2 class="mb-4">Mentions légales :</h2>
    <p>
      Le site internet malaweb.fr est édité par la société Malaweb,dont le siège
      social ce situe à Les Mureaux, 78130, France.
    </p>
    <p>Adresse de contact : contact@malaweb.fr</p>
    <p>
      Le site internet a été réalisé par l'agence web Malaweb, située à Les
      Mureaux (78), France.
    </p>
    <p>Hébergement :</p>
    <p>
      OVH, 2 rue Kellermann - 59100 Roubaix - France, immatriculée au Registre
      du Commerce et des Sociétés de Lille Métropole sous le numéro 424 761 419
      00045.
    </p>
    <h3 class="mt-5 mb-4">Protection des données personnelles :</h3>
    <p>
      Malaweb s'engage à respecter la législation française et européenne en
      matière de protection des données personnelles, notamment le Règlement
      Général sur la Protection des Données (RGPD).
    </p>
    <p>
      Les données personnelles collectées par malaweb sont celles que vous
      fournissez lors de vos correspondance sur le site malaweb.fr, demande de
      devis, formulaire de contact (nom, prénom, adresse e-mail, numéro de
      téléphone).
    </p>
    <p>
      Ces données sont conservées pendant la durée nécessaire à la gestion de
      votre demande. Malaweb ne partage vos données personnelles avec aucun
      partenaire .
    </p>
    <p>
      Malaweb utilise Google Analytics pour mesurer le trafic sur son site
      internet, mais ne partage jamais les informations ni ne les utilise à des
      fins publicitaires.
    </p>
    <p>
      Vous disposez d'un droit d'accès, de rectification, de suppression et de
      portabilité de vos données personnelles. Vous pouvez exercer ces droits en
      contactant Malaweb à l'adresse de contact indiquée ci-dessus.
    </p>
    <h3 class="mt-5 mb-4">Copyright :</h3>
    <p>
      Copyright © Malaweb. Tous droits réservés. Toute reproduction totale ou
      partielle du contenu de ce site, par quelque moyen que ce soit, sans
      autorisation préalable de Malaweb est interdite et constituerait une
      contrefaçon sanctionnée par les articles L335-2 et suivants du Code de la
      Propriété Intellectuelle.
    </p>
    <h3 class="mt-5 mb-4">Loi applicable et juridiction compétente :</h3>
    <p>
      Les présentes mentions légales sont régies par la loi française. Tout
      litige relatif à l'interprétation ou à l'exécution des présentes mentions
      légales sera de la compétence exclusive des tribunaux français.
    </p>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
export default {
  name: "MentionLegale",
  setup() {
    useMeta({
      title: "Mentions Légales",
      description: [
        {
          content:
            "Malaweb s'engage à respecter la législation française et européenne en matière de protection des données personnelles, notamment le Règlement Général sur la Protection des Données (RGPD).",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: $color-primary;
}
</style>
