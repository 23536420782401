<template>
  <div class="container-fluid pt-2 main-mobile">
    <NavOption
      navLink="/marketing-digital"
      navText="Retour"
      navBorderColor="#f8d800"
      iconColorClass="yellow"
    />
    <section class="hero-banner container">
      <div class="flex">
        <div
          class="hero-banner__img col-lg-7 order-lg-2 d-flex justify-content-center"
        >
          <img
            src="@/assets/pictures/tag-world.webp"
            alt="logo-wordpress-circle"
            class="delta"
            width="500"
          />
        </div>
        <div class="hero-banner__speech col-lg-5 mr-lg-5">
          <h1 class="mt-5">
            <span>Stratégies Digitales</span> Complètes pour Votre Entreprise
          </h1>
          <p class="p-1">
            Découvrez nos services complets en
            <strong>marketing digital</strong>, incluant la
            <strong>création de logo</strong>, le <strong>branding</strong>, la
            <strong>conception de flyers</strong>,
            <strong>affiches publicitaires</strong>, et la configuration de vos
            <strong>emails professionnels</strong>. Nous vous accompagnons dans
            la construction de votre image de marque et dans la diffusion
            efficace de vos messages publicitaires, pour maximiser votre impact
            et votre présence en ligne.
          </p>
          <!---<a href="#" class="btn btn-slide btn-lg border">en savoir plus</a>-->
        </div>
      </div>
    </section>
    <div class="pt-5">
      <section class="p-md-3 container-fluid">
        <div class="row-icon pt-3">
          <div class="row-icon__cadre mb-5">
            <i class="col-3 far fa-paper-plane indigo-text"></i>
            <div class="col-9">
              <h4 class="font-weight-bold">Branding</h4>
              <p class="text-muted mb-lg-0">Entre<br />350€ & 800€</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold">Flyers</h4>
              <p class="text-muted mb-lg-0">
                Entre<br />50€ & 80€ (par visuel)
              </p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold">Affiches</h4>
              <p class="text-muted mb-lg-0">Entre<br />250€ & 450€</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold">Emails pro</h4>
              <p class="text-muted mb-lg-0">250€<br />(5 adresses mails)</p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <article class="container-fluid">
      <div class="article-title container-lg">
        <h2>
          Créez son site vitrine et augmenter la visibilité de votre entreprise
        </h2>
      </div>
      <section class="container mt-3 mt-lg-5">
        <div class="block-row pt-5">
          <div class="block-text col-lg-5 slide-left">
            <TitleColor
              title="Créer une identité visuelle unique"
              titleColor="#f8d800"
            />
            <p class="text-left mx-auto w-responsive">
              Notre processus de branding commence par une exploration
              approfondie de votre entreprise, de sa mission et de ses valeurs.
              Nous travaillons en étroite collaboration avec vous pour
              comprendre vos objectifs, votre public cible et votre
              positionnement sur le marché. En nous appuyant sur ces
              informations, notre équipe de créatifs expérimentés s'engage à
              développer un naming percutant et un
              <strong>logo</strong> distinctif qui capturent l'essence même de
              votre marque. Nous combinons créativité, expertise et recherche
              approfondie pour concevoir une
              <strong>identité visuelle</strong> mémorable qui reflète
              fidèlement l'unicité et les aspirations de votre entreprise.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/branding.webp"
                alt="brain-with-a-light-for-idea"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Section: Content-->
      <section class="container my-5">
        <!-- Grid row -->
        <div class="block-row reverse">
          <!-- Grid column -->
          <div class="block-text slide-right col-lg-5">
            <!-- Category -->
            <!-- Post title -->
            <TitleColor
              title="Créations de Flyers"
              titleColor="#f8d800"
              bgColor="hsl(263, 47%, 58%)"
            />
            <!-- Excerpt -->
            <p>
              Nos services de <strong>conception de flyers</strong> sont conçus
              pour donner vie à votre message de manière visuellement
              captivante. Avec une approche centrée sur vos besoins spécifiques,
              nous créons des <strong>flyers</strong> innovants et accrocheurs
              qui transmettent efficacement votre message et attirent
              l'attention de votre public cible. Que ce soit pour une promotion,
              un événement spécial ou une
              <strong>campagne de marketing</strong>, notre équipe créative
              s'efforce de <strong>créer des flyers</strong> distinctifs,
              alliant conception graphique de qualité et contenu engageant. Nous
              mettons l'accent sur l'équilibre entre l'aspect visuel et l'impact
              du message, garantissant ainsi que vos <strong>flyers</strong>
              captivent et incitent à l'action, renforçant ainsi la notoriété et
              la visibilité de votre marque.
            </p>
          </div>
          <!-- Grid column -->
          <!-- Grid column -->
          <div class="col-lg-6">
            <!-- Featured image -->
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/flyer-meuble.webp"
                alt="flyer-for-furniture-shop"
                class="img-fluid"
              />
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <section class="container mt-3 my-lg-5">
        <div class="block-row">
          <div class="block-text col-lg-5 slide-left">
            <TitleColor
              title="Création d'Affiches Publicitaires"
              titleColor="#f8d800"
            />
            <p class="text-left mx-auto w-responsive">
              Notre service de
              <strong>conception d'affiches publicitaires</strong> se distingue
              par sa créativité et son souci du détail. Nos designers
              spécialisés accordent une importance capitale à chaque projet pour
              garantir des <strong>affiches publicitaires</strong> uniques et
              mémorables. Chaque élément graphique est minutieusement choisi
              pour refléter l'identité visuelle de votre marque et transmettre
              efficacement votre message promotionnel. Notre approche est axée
              sur la conception d'affiches qui captent l'attention, incitent à
              l'action et laissent une empreinte durable dans l'esprit de votre
              audience cible.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/affiche-publicitaire.webp"
                alt="advertising-poster"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Section: Content-->
      <section class="container mb-5">
        <!-- Grid row -->
        <div class="block-row reverse">
          <!-- Grid column -->
          <div class="block-text slide-right col-lg-5">
            <!-- Category -->
            <!-- Post title -->
            <TitleColor
              title="Emails professionnels"
              titleColor="#f8d800"
              bgColor="hsl(263, 47%, 58%)"
            />
            <!-- Excerpt -->
            <p>
              Notre service de
              <strong>création d'adresses e-mail professionnelles</strong> est
              une passerelle essentielle vers une communication plus crédible
              pour votre entreprise. En fournissant des adresses
              <strong>e-mail pro</strong> personnalisées à votre domaine, nous
              vous aidons à instaurer une image de professionnalisme et de
              fiabilité auprès de vos clients. Disposer d'<strong
                >une adresse e-mail professionnelle</strong
              >
              dédiée renforce votre crédibilité, accroît la confiance de vos
              partenaires commerciaux. Avoir une adresse
              <strong>e-mail professionnelle</strong> est un élément
              incontournable pour bâtir une identité forte et pour assurer une
              communication professionnelle et efficace.
            </p>
          </div>
          <!-- Grid column -->
          <!-- Grid column -->
          <div class="col-lg-6">
            <!-- Featured image -->
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/email-pro.webp"
                alt="graphic-economy-energy"
                class="orange-envelope"
              />
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
    </article>
    <CallActionServiceSolo
      img="marketing-service.webp"
      h3="Réalisons ensemble votre projet Numérique"
      webServiceValue="Marketing Digital"
      h3Color="#ebdcdc"
      p="Sentez vous libre de nous contacter pour toute question concernant votre
        projet numérique, notre équipe vous apportera les réponses adaptées à
        votre projet web."
    />
    <FooterOption btnBorderColor="#f8d800" iconColorClass="yellow" />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import TitleColor from "@/components/partials/TitleColor.vue";
import CallActionServiceSolo from "@/components/partials/CallActionServiceSolo.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";
export default {
  name: "MarketingServices",
  components: {
    TitleColor,
    CallActionServiceSolo,
    NavOption,
    FooterOption,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Créations Flyers & visuels",
      description: [
        {
          content:
            "Du design de flyers, d'affiches percutantes à la conception de logos accrocheurs, explorez nos services de création visuelle. Notre équipe experte vous offre des solutions sur mesure pour promouvoir votre marque de manière impactante.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/prix-marketing-digital",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.main-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100%;
  background-image: linear-gradient(
    to left,
    #f8d800,
    #f6dc00,
    #f3e102,
    #f0e506,
    #ede90b
  );

  & .hero-banner {
    //@include background-svg;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    color: $color-text;
    padding: 40px 20px;
    @media screen and (min-width: 992px) {
      padding: 0px 0px 70px 20px;
    }
    & .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
      }
    }
    &__img {
      @include image-fade-in(2s);
      & img {
        @media screen and (max-width: 1245px) {
          width: 100%;
        }
      }
    }

    & .hero-banner__speech .btn {
      position: relative;
      overflow: hidden;
      z-index: 9;
    }
    & .hero-banner__speech .btn-slide {
      background-color: rgba(0, 0, 0, 0.1);
      color: $color-text;
    }
    & .hero-banner__speech .btn-slide:before {
      content: "";
      background: #f27316;
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      -ms-transition: all 0.5s ease-in-out 0s;
      -o-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
    }
    & .hero-banner__speech .btn-slide:hover:before {
      width: 100%;
    }

    & .hero-banner__speech {
      color: $color-text-grey;
      @media screen and (min-width: 992px) {
        padding-left: 50px;
      }
      & h1 {
        color: $color-darker;
      }
      & p {
        text-align: start;
      }
      & a {
        margin: 15px 0px 20px 0px;
      }
    }
  }
  & article {
    & h2 {
      text-align: center;
      color: $color-text;
      text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
      margin: 80px 10px 20px;
      font-size: 2em;
      @media screen and (min-width: 992px) {
        margin: 80px 10px 50px;
      }
    }
  }
  & .row-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &__cadre {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 350px;
      min-height: 100%;
      padding: 10px 20px;
      text-align: center;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
      border-radius: 59px;
      border: 0.1px outset $color-lighter;
      box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
      & i {
        color: #f8d800;
        font-size: 2em;
        border-radius: 100%;
        border: 0.5px outset #f8d800;
        padding: 18px;
      }
      & h4 {
        font-size: 1.3em;
        color: #ede90b;
      }
      & p {
        text-align: center;
        font-size: 0.9em;
      }
    }
  }
  .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 30px;
      @media screen and (min-width: 992px) {
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text-grey;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
  .callAction {
    margin: 20px 0px 0px 0px;
  }
}
</style>
