<template>
  <h3 class="mb-3" :style="({ background: bgColor }, { color: titleColor })">
    {{ title }}
  </h3>
</template>

<script>
export default {
  name: "TitleColored",
  props: {
    title: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
      default: "hsl(234, 12%, 34%)",
    },
    bgColor: {
      type: String,
      default: "#f27316",
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  display: inline-block;
  position: relative;
  margin-top: 50px;
  padding: 10px 5px 10px 5px; /* Espacement interne pour le contenu du titre */
  background-color: $color-darker; /* Couleur de fond de la bannière */
  color: $color-text; /* Couleur du texte */
  z-index: 1;
  @media screen and (min-width: 992px) {
    padding: 10px 50px 10px 8px;
    margin-bottom: 50px !important;
  }
  @media screen and (max-width: 330px) {
    padding: 5px 10px 5px 5px;
  }
}
h3:before {
  content: "";
  position: absolute;
  top: 0;
  right: -10px; /* Ajustez cette valeur pour contrôler l'angle de la diagonale */
  bottom: 0;
  width: 50%;
  background-color: $color-darker;
  transform: skewX(
    -16deg
  ); /* Ajustez cet angle pour contrôler l'inclinaison de la diagonale */
  z-index: -1;
  @media screen and (min-width: 992px) {
    right: -19px;
  }
}
</style>
