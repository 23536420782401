<template>
  <!--Section: Content-->
  <section class="container-fluid px-md-5 p-5 call-action">
    <!--Grid row-->
    <div class="row d-flex text-center justify-content-center">
      <!--Grid column-->
      <div class="col-xl-8 col-md-10">
        <div class="call-action__img">
          <img
            src="@/assets/brand/fox-sigle.webp"
            alt="logo"
            width="100"
            class="mb-1"
          />
        </div>
        <h3 class="jaapokki mb-3">Malaweb</h3>
        <p>
          Besoin d'un renseignement ? Laissez-nous votre numéro de téléphone,
          nous vous recontacterons pour répondre à vos questions.
        </p>
        <form ref="formData" @submit.prevent="sendEmail">
          <div class="input-group mb-4 pb-3 call-action__form">
            <input
              type="hidden"
              name="reason"
              value="Demande d'appel"
              required
            />
            <input
              type="tel"
              id="form-phone"
              placeholder="Votre Téléphone"
              name="form-phone"
              required
              pattern="^(\+?\d{1,3}[- ]?)?\d{10}$"
              class="form-control"
            />
            <div>
              <button
                class="btn btn-md m-0 px-3 py-2 z-depth-0 waves-effect"
                type="submit"
                id="button-addon2"
                title="addon2"
              >
                Envoyer
              </button>
            </div>
            <span v-if="this.isCaptchaVisible">
              <vue-hcaptcha
                sitekey="b18d3121-ac4a-4cf4-a0bc-f8f255dbcc43"
                @verify="onVerify"
                @error="onError"
              ></vue-hcaptcha>
            </span>
          </div>
        </form>
        <span v-if="this.modalConfirm">
          <ConfirmModal
            @closeModalConfirm="closeModalConfirm"
            title="Merci pour votre confiance !"
            message="Vous allez être recontactés dans les plus brefs délais"
            btnExit="À bientôt"
          />
        </span>
      </div>
      <!--Grid column-->
    </div>
    <!--Grid row-->
  </section>
  <!--Section: Content-->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  name: "CallAction",
  components: { VueHcaptcha, ConfirmModal },
  data() {
    return {
      isCaptchaVerified: false,
      isCaptchaVisible: false,
      modalConfirm: false,
      showOrder: false,
    };
  },
  computed: {
    ...mapGetters({
      SendingEmail: "StateSendingEmail",
      EmailSent: "StateEmailSent",
      EmailError: "StateEmailError",
    }),
  },
  methods: {
    ...mapActions(["SendCallMeMail"]),
    sendEmail() {
      if (this.isCaptchaVerified) {
        this.SendCallMeMail(this.$refs.formData);
        this.modalConfirm = true;
        this.isCaptchaVerified = false;
        this.isCaptchaVisible = false;
        this.$refs.formData.reset();
      } else {
        this.isCaptchaVisible = true;
      }
    },
    onVerify(token, eKey) {
      this.isCaptchaVerified = true;
      console.log("Verified: ", { token, eKey });
      console.log(this.isCaptchaVerified);
    },
    onError: (err) => {
      console.log("Error", err);
    },
    closeModalConfirm() {
      this.modalConfirm = false;
      this.showOrder = false;
    },
    showModalOrder() {
      this.showOrder = true;
    },
    closeModalOrder() {
      this.$emit("closeModalOrder");
    },
  },
};
</script>

<style lang="scss" scoped>
.call-action {
  background: $color-primary;
  color: $color-text;
  @include background-svg;
  @include background-responsive;
  & img {
    background: $color-primary;
    border-radius: 50%;
    padding: 2px;
  }
  & p {
    text-align: center;
  }
  &__form {
    width: 50%;
    margin: auto;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
    & input:focus {
      outline-color: orangered !important;
    }
    & button {
      color: $color-text;
      background: $color-primary;
    }
  }
}
</style>
