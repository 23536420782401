<template>
  <section class="profil">
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-4">
          <div class="card mb-4">
            <div class="card-body card-body__left text-center">
              <div class="card-img">
                <img
                  :src="
                    'https://api-blog.malaweb.fr' + Profile.avatar.contentUrl
                  "
                  alt="avatar"
                  class="rounded-circle img-fluid"
                />
              </div>
              <p class="text-muted text-center mb-5">{{ Profile.pseudo }}</p>
              <div class="d-flex justify-content-center mt-2">
                <form @submit.prevent="submitAvatar(Profile.id)">
                  <input
                    type="file"
                    id="file"
                    name="file"
                    ref="file"
                    accept="image/png, image/jpeg"
                    class="add-img"
                  />
                  <div class="d-flex justify-content-center my-5">
                    <button type="submit" class="btn btn-valid">Changer</button>
                    <span v-if="Profile.avatar.id !== 1">
                      <button
                        type="button"
                        @click="removeAvatar"
                        class="btn btn-delete ms-1"
                      >
                        Supprimer
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="card mb-4">
            <div class="card-body card-body__right">
              <span v-if="isLoggedIn && Profile.id == User.id && userEdit">
                <form @submit.prevent="update(Profile)">
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="text-muted mb-0">Email</p>
                    </div>
                    <div class="col-sm-9">
                      <input
                        type="email"
                        name="email"
                        v-model="Profile.email"
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="text-muted mb-0">Pseudo</p>
                    </div>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        name="pseudo"
                        v-model="Profile.pseudo"
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-center my-4">
                    <button type="submit" class="btn btn-valid my-5">
                      Enregistrer
                    </button>
                  </div>
                </form>
              </span>
              <span v-else>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="text-muted mb-0">Email</p>
                  </div>
                  <div class="col-sm-9">
                    <p class="mb-0">{{ Profile.email }}</p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-3">
                    <p class="text-muted mb-0">Pseudo</p>
                  </div>
                  <div class="col-sm-9">
                    <p class="mb-0">{{ Profile.pseudo }}</p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-3">
                    <p class="text-muted mb-0 text-start">
                      Changer mot de passe
                    </p>
                  </div>
                  <div class="col-sm-9">
                    <button
                      @click="openModalUpdatePassword"
                      class="btn btn-pwd"
                    >
                      Password
                    </button>
                  </div>
                </div>
                <span v-if="this.modalUpdatePassword">
                  <ModalUpdatePassword
                    @closeModalUpdatePassword="closeModalUpdatePassword"
                    @updatePassword="updatePassword"
                    :idUser="Profile.id"
                  />
                </span>
              </span>
              <div class="d-flex justify-content-center my-5 pt-3">
                <button @click="edit" class="btn btn-valid">EDIT</button>
                <button @click="remove(Profile.id)" class="btn btn-delete ms-1">
                  DELETE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container addPost mb-5">
    <button type="submit" @click="createPost">Nouvelle article +</button>
    <button type="submit" @click="createTown">Ville +</button>
  </div>
  <span v-if="this.userPostsPublished.length">
    <section class="article-section container-fluid mt-5">
      <h2 class="text-left">Vos articles publiés</h2>
      <AuthorArticleSelect :posts="this.userPostsPublished" />
    </section>
  </span>
  <span v-if="this.userPostsNotPublished.length">
    <section class="article-section container-fluid mt-5">
      <h2 class="text-left">Vos articles non publiés</h2>
      <AuthorArticleSelect :posts="this.userPostsNotPublished" />
    </section>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AuthorArticleSelect from "@/components/blog/AuthorArticleSelect.vue";
import ModalUpdatePassword from "@/components/modals/ModalUpdatePassword.vue";
export default {
  name: "ProfileView",
  components: { AuthorArticleSelect, ModalUpdatePassword },
  mounted: function () {
    // a function to call getposts action
    this.userPostsPublished = this.Profile.articles.filter(
      (post) => post.isPublished === true
    );
    this.userPostsNotPublished = this.Profile.articles.filter(
      (post) => post.isPublished === false
    );
  },
  data() {
    return {
      file: "",
      userEdit: false,
      showError: false,
      userPostsPublished: [],
      userPostsNotPublished: [],
      modalUpdatePassword: false,
    };
  },
  computed: {
    ...mapGetters({
      Profile: "StateProfile",
      User: "StateUser",
      Post: "StatePost",
    }),
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    ...mapActions([
      "GetProfile",
      "UpDateProfile",
      "DeleteProfile",
      "CreateAvatar",
      "DeleteAvatar",
      "GetPost",
    ]),
    async edit() {
      if (!this.userEdit) {
        this.userEdit = true;
      } else {
        this.userEdit = false;
      }
      return this.userEdit;
    },
    async update(profile) {
      try {
        await this.UpDateProfile(profile).then(this.GetProfile(profile.id));
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async submitAvatar(profileId) {
      try {
        this.file = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("profileId", profileId);
        await this.CreateAvatar(formData);
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async removeAvatar() {
      try {
        await this.DeleteAvatar();
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async remove(profileId) {
      try {
        await this.DeleteProfile(profileId).then(
          this.$store.dispatch("LogOut")
        );
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async createTown() {
      try {
        this.$router.push({
          name: "UploadTown",
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async createPost() {
      try {
        this.$router.push({
          name: "create",
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async editPost(id) {
      try {
        await this.GetPost(id);
        this.$router.push({
          name: "editPost",
          params: { slug: this.Post.slug },
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    openModalUpdatePassword() {
      this.modalUpdatePassword = true;
    },
    closeModalUpdatePassword() {
      this.modalUpdatePassword = false;
    },
    updatePassword() {
      this.modalUpdatePassword = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.profil {
  & input {
    border: 0.1px $color-primary outset;
    border-radius: 10px;
    padding: 3px 8px;
    color: white;
  }
  & .card {
    & .card-body {
      &__right {
        border-radius: 10px;
        border: 0.1px $color-darker outset;
        color: $color-text-grey;
      }
      &__left {
        background-color: $color-darker;
        border: 0.1px $color-primary outset;
        padding-bottom: 0px;
        border-radius: 10px;
      }
      & .add-img {
        font-size: 0.8em;
      }
      & .btn {
        color: $color-text-light;
        transition: all 0.2 ease-in-out;
        &:hover,
        :focus {
          transform: scale(96%);
        }
      }
      & .btn-pwd {
        background-color: rgba(4, 7, 163, 0.877);
        color: $color-text-light;
      }
      & .btn-valid {
        background-color: rgba(0, 128, 0, 0.877);
      }
      & .btn-delete {
        background-color: rgba(240, 10, 10, 0.877);
      }
      & .card-img {
        display: flex;
        justify-content: center;
        padding: 3px;
        & img {
          border: 0.1px $color-primary outset;
          border-radius: 50%;
          width: 150px;
          height: 150px;
          padding: 10px;
          background-color: $color-primary;
        }
      }
    }
  }
}
.addPost {
  & button {
    width: fit-content;
    background: $color-darker !important;
    color: $color-primary !important;
    border: 0.3px $color-primary outset;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 5px 10px;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: $color-primary !important;
      color: $color-text !important;
      border: 0.3px $color-text inset;
      transform: scale(95%);
    }
  }
}
.article-section {
  & h2 {
    background-color: $color-primary;
    color: $color-darker;
    padding: 5px 10px;
    padding-left: 20px;
    width: fit-content;
    border-radius: 0px 10px 10px 0px;
  }
}
</style>
