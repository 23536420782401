<template>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center">
      <div class="card user_card">
        <div class="d-flex justify-content-center">
          <div class="brand_logo_container">
            <img
              src="@/assets/brand/logo-round.webp"
              alt="logo-malaweb-fox"
              class="brand_logo"
            />
            <h1 class="text-center mt-1">Mala<span>web</span></h1>
          </div>
        </div>
        <div class="d-flex justify-content-center form_container">
          <form @submit.prevent="submit">
            <div class="input-group mb-3">
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fas fa-user"></i>
                </span>
              </div>
              <input
                type="email"
                name="email"
                id="inputEmail"
                placeholder="Email"
                class="form-control"
                autocomplete="email"
                required
                autofocus
                v-model="form.email"
              />
            </div>
            <div class="input-group mb-2">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-key"></i></span>
              </div>

              <input
                type="password"
                name="password"
                id="inputPassword"
                placeholder="Password"
                class="form-control"
                autocomplete="current-password"
                required
                v-model="form.password"
              />
            </div>

            <!--<div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customControlInline"
                />
                <label class="custom-control-label" for="customControlInline"
                  >Remember me</label
                >
              </div>
            </div>-->

            <div class="mt-2 mb-5 form-group text-white">
              <div class="d-flex justify-content-around links">
                Don't have an account ?
                <a href="/register" class="linkLog linkin">S'inscrire</a>
              </div>
            </div>

            <div class="d-flex justify-content-center mt-3 login_container">
              <button class="btn btn-default login_btn" type="submit">
                Connexion
              </button>
            </div>
            <div class="d-flex justify-content-center links">
              <a href="#" class="mb-5 mt-3 linkLog lost"
                >Mot de passe perdu ?</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "loginView",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showError: false,
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const User = new FormData();
      User.append("email", this.form.email);
      User.append("password", this.form.password);
      try {
        await this.LogIn(User);
        this.$router.push("/malazone");
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrap {
  padding: 150px auto;
}
.user_card {
  /*height: 400px;*/
  width: 380px;
  margin-top: 100px;
  margin-bottom: 30vh;
  background: $color-darker;
  /*position:relative;
			top: 15%;
			left: 3%;*/

  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.brand_logo_container {
  position: relative;
  height: 170px;
  width: 170px;
  top: -80px;
  border-radius: 50%;
  background: $background;
  /*padding: 5px;*/
  text-align: center;
  & h1 {
    font-size: 2em;
    color: $color-text;
    & span {
      color: $color-primary;
    }
  }
}
.brand_logo {
  height: 170px;
  width: 170px;
  padding-right: 5px;
}
.form_container {
  margin-top: 20px;
}
.login_btn {
  width: 70%;
  background: $color-primary !important;
  color: $color-text !important;
  border: 0.3px $color-text outset;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: $color-primary !important;
    color: $color-text !important;
    border: 0.3px $color-text inset;
    transform: scale(95%);
  }
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
input:focus {
  box-shadow: none; /* Changer la couleur de l'outline */
}
.input-group-text {
  background: $color-primary !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
  height: 100%;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c0392b !important;
}

.linkLog {
  &:hover {
    color: white;
  }
}
.linkin {
  color: $color-primary;
}
.lost {
  color: $color-text;
}
</style>
