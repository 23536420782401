<template>
  <div class="container px-4 mb-5 pb-5 z-depth-1 about-call">
    <!--Section: Content-->
    <section class="px-md-5 mx-md-5 text-center text-lg-left dark-grey-text">
      <h3 class="font-weight-bold fall-down">Notre philosophie</h3>

      <p class="text-muted mb-5 fall-down">
        Une équipe pluridisciplinaire consacré à 100% au développement de votre
        <strong>projet numérique</strong>, à l'écoute des besoins de votre
        entreprise et de vos clients afin de leur offrir une
        <strong>expérience utilisateur</strong> unique.
      </p>

      <!--Grid row-->
      <div class="row my-5 py-5">
        <!--Grid column-->
        <div class="col-12 col-lg-6 mb-lg-0 mb-4 slide-left">
          <img
            src="@/assets/pictures/interface-ui.webp"
            class="img-fluid"
            alt="interface"
          />
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-12 col-lg-6 mb-lg-0 mb-lg-0 mb-2 slide-right">
          <h4 class="my-4">Plus de renseignements ?</h4>

          <p class="text-muted mb-5">
            Vous avez une question sur le
            <strong>prix d'un site internet</strong> ? Sur le
            <strong>coût de développement d'une application mobile</strong> ?
            Consultez notre section services ou contactez-nous afin d'en
            discuter.
          </p>
          <div class="text-center text-lg-start">
            <a class="btn btn-md waves-effect" href="/services" role="button"
              >Services</a
            >
            <a class="btn btn-md" href="/contact" role="button"
              >Contact <i class="fa fa-magic ml-1"></i
            ></a>
          </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </section>
    <!--Section: Content-->
  </div>
</template>

<script>
export default {
  name: "AboutCallAction",
};
</script>

<style lang="scss" scoped>
.about-call {
  & h4 {
    color: $bold-light-primary;
  }
  & a {
    color: $color-text;
    border: 0.5px inset $color-text;
    border-radius: 5px;
  }
  & a:nth-child(1) {
    background-color: $color-primary;
    &:hover {
      background-color: $color-darker;
      color: $color-primary;
    }
  }
  & a:nth-child(2) {
    background-color: $color-darker;
    &:hover {
      background-color: $color-darker;
      color: $color-primary;
    }
  }
}
</style>
