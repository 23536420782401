<template>
  <div class="container-fluid pt-2 main-vitrine">
    <NavOption
      navLink="/developpement-web?itemActif=e-commerce"
      navText="Retour"
    />
    <section class="hero-banner container">
      <div class="flex">
        <div
          class="hero-banner__img col-lg-7 order-lg-2 d-flex justify-content-center"
        >
          <img
            src="@/assets/pictures/ecommerce-workflow.webp"
            alt="parkour-customer-on-eshop"
            class="delta"
            width="500"
          />
        </div>
        <div class="hero-banner__speech col-lg-5 mr-lg-5">
          <h1 class="mt-5">
            Choisissez la solution idéale pour votre
            <span>Boutique en Ligne</span>
          </h1>
          <p class="p-1">
            Avec <strong>Shopify</strong> et <strong>WooCommerce</strong>,
            explorez deux approches uniques pour
            <strong>créer et gérer une boutique en ligne</strong> dynamique. Ces
            plateformes offrent des fonctionnalités distinctes, chacune adaptée
            à des besoins spécifiques en matière de <strong>e-commerce</strong>.
          </p>
          <!---<a href="#" class="btn btn-slide btn-lg border">en savoir plus</a>-->
        </div>
      </div>
    </section>
    <div class="pt-5">
      <section class="p-md-3 container-fluid">
        <div class="row-icon pt-3">
          <div class="row-icon__cadre mb-5">
            <i class="col-3 far fa-paper-plane indigo-text"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Prix</h4>
              <p class="text-muted mb-lg-0">À partir de 4500€</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Technologie</h4>
              <p class="text-muted mb-lg-0">WordPress/CMS</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Hébergement</h4>
              <p class="text-muted mb-lg-0">
                À définir selon volume application
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <article class="container-fluid">
      <div class="article-title container-lg">
        <h2>
          E-commerce des solutions clé en main, idéales pour une mise en place
          rapide et une gestion facile.
        </h2>
      </div>
      <section class="container mt-3 mt-lg-5">
        <div class="block-row pt-5">
          <div class="block-text col-lg-5 slide-left">
            <TitleColor
              title="Shopify une plateforme de e-commerce"
              titleColor="#f27316"
            />
            <p class="text-left mx-auto w-responsive">
              <strong>Shopify</strong> est une plateforme de
              <strong>e-commerce</strong> hébergée et conviviale, offrant une
              approche clé en main pour la
              <strong>création de boutiques en ligne</strong>. Cette solution
              est réputée pour sa facilité d'utilisation, ses thèmes
              professionnels prêts à l'emploi et son service client réactif.
              Elle convient parfaitement aux nouveaux
              <strong>commerçants en ligne</strong> grâce à son processus de
              configuration simple et intuitive. Les utilisateurs peuvent
              accéder à une gamme variée d'applications pour étendre les
              fonctionnalités de leur boutique, bien que certaines de ces
              applications puissent entraîner des coûts supplémentaires.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/shopify.webp"
                alt="mockup-pc-shopify"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Section: Content-->
      <section class="container my-5 pb-lg-5">
        <!-- Grid row -->
        <div class="block-row reverse">
          <!-- Grid column -->
          <div class="block-text slide-right col-lg-5">
            <!-- Category -->
            <!-- Post title -->
            <TitleColor
              title="WooCommerce l'extension WordPress"
              titleColor="#f27316"
              bgColor="hsl(263, 47%, 58%)"
            />
            <!-- Excerpt -->
            <p>
              <strong>WooCommerce</strong> est une extension open-source
              <strong>e-commerce</strong> pour les
              <strong>sites WordPress</strong>. Cette solution offre une
              flexibilité et une personnalisation supérieure, idéale pour les
              utilisateurs qui préfèrent avoir un plus grand contrôle sur leur
              <strong>boutique en ligne</strong>.
              <strong>WooCommerce</strong> s'intègre parfaitement à l'écosystème
              <strong>WordPress</strong>, permettant aux utilisateurs de
              profiter des avantages d'un <strong>CMS</strong> puissant pour
              gérer leur contenu et leur <strong>boutique en ligne</strong>.
              Bien qu'elle nécessite un peu plus de travail technique pour la
              configuration initiale, <strong>WooCommerce</strong> offre une
              évolutivité accrue et une gamme étendue de fonctionnalités grâce à
              une multitude d'extensions disponibles.
            </p>
            <ArrowBtn
              whiteCircleBackground="hsl(263, 47%, 58%)"
              circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
            />
          </div>
          <!-- Grid column -->
          <!-- Grid column -->
          <div class="col-lg-6">
            <!-- Featured image -->
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/woo-commerce.webp"
                alt="mockup-pc-woocommerce"
                class="img-fluid"
              />
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
    </article>
    <CallActionServiceSolo
      img="sales-ecommerce.webp"
      h3="Créer un e-commerce"
      webServiceValue="Création e-commerce professionnel"
      h3Color="#ebdcdc"
      p="Sentez vous libre de nous contacter pour toute question concernant la création d'un site e-commerce, notre équipe vous apportera les réponses adaptées à
        votre projet web."
    />
    <FooterOption />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import TitleColor from "@/components/partials/TitleColor.vue";
import CallActionServiceSolo from "@/components/partials/CallActionServiceSolo.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";

export default {
  name: "EcommerceWordpress",
  components: {
    TitleColor,
    CallActionServiceSolo,
    NavOption,
    FooterOption,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "e-commerce",
      description: [
        {
          content:
            "Explorez nos solutions de développement web pour les sites e-commerce avec WooCommerce et Shopify. Maximisez la puissance de ces plateformes renommées pour créer des expériences d'achat en ligne conviviales et performantes.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/prix-site-e-commerce-wordpress",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.main-vitrine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100%;
  background: #f12711;
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  background: linear-gradient(to right, #f5af19, #f12711);

  & .hero-banner {
    //@include background-svg;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    color: $color-text;
    padding: 40px 20px;
    @media screen and (min-width: 992px) {
      padding: 0px 0px 70px 20px;
    }
    & .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
      }
    }
    &__img {
      @include image-fade-in(2s);
      & img {
        @media screen and (max-width: 1245px) {
          width: 100%;
        }
      }
    }

    & .hero-banner__speech .btn {
      position: relative;
      overflow: hidden;
      z-index: 9;
    }
    & .hero-banner__speech .btn-slide {
      background-color: rgba(0, 0, 0, 0.1);
      color: $color-text;
    }
    & .hero-banner__speech .btn-slide:before {
      content: "";
      background: #f27316;
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      -ms-transition: all 0.5s ease-in-out 0s;
      -o-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
    }
    & .hero-banner__speech .btn-slide:hover:before {
      width: 100%;
    }

    & .hero-banner__speech {
      @media screen and (min-width: 992px) {
        padding-left: 50px;
      }
      & h1 > span {
        color: $color-darker;
      }
      & p {
        text-align: start;
      }
      & a {
        margin: 15px 0px 20px 0px;
      }
    }
  }
  & .row-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &__cadre {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;
      min-height: 100%;
      padding: 10px 20px;
      text-align: center;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
      border-radius: 59px;
      border: 0.1px outset $color-lighter;
      box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
      & i {
        color: $color-primary;
        font-size: 2em;
        border-radius: 100%;
        border: 0.5px outset $color-primary;
        padding: 18px;
      }
      & h4 {
        font-size: 1.3em;
        color: $color-primary;
      }
      & p {
        text-align: center;
        font-size: 0.9em;
      }
    }
  }
  & article {
    & h2 {
      text-align: center;
      color: $color-text;
      text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
      margin: 80px 10px 50px;
      font-size: 2em;
      @media screen and (min-width: 992px) {
        margin: 80px 0px 50px;
      }
    }
  }
  .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        margin-bottom: 0px;
        margin-top: 20px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
  .callAction {
    margin: 20px 0px 0px 0px;
  }
}
</style>
