<template>
  <svg
    class="editorial"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28 "
    preserveAspectRatio="none"
  >
    <defs>
      <path
        id="gentle-wave"
        d="M-160 44c30 0 
    58-18 88-18s
    58 18 88 18 
    58-18 88-18 
    58 18 88 18
    v44h-352z"
      />
    </defs>
    <g class="parallax1">
      <use
        xlink:href="#gentle-wave"
        x="50"
        y="3"
        fill="rgba(242, 114, 24, 0.35)"
      />
    </g>
    <g class="parallax2">
      <use
        xlink:href="#gentle-wave"
        x="50"
        y="0"
        fill="rgba(242, 114, 24, 0.64)"
      />
    </g>
    <g class="parallax3">
      <use xlink:href="#gentle-wave" x="50" y="9" fill="#f27316" />
    </g>
    <g class="parallax4">
      <use xlink:href="#gentle-wave" x="50" y="6" fill="#1F1E1E" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "WaveSeparator",
};
</script>

<style lang="scss" scoped>
.editorial {
  display: block;
  width: 100%;
  height: 60px;
  max-height: 60px;
  margin: 0;
  z-index: 5;
  bottom: 10;
}

.parallax1 > use {
  animation: move-forever1 10s linear infinite;
  &:nth-child(1) {
    animation-delay: -8s;
  }
}
.parallax2 > use {
  animation: move-forever2 8s linear infinite;
  &:nth-child(1) {
    animation-delay: -8s;
  }
}
.parallax3 > use {
  animation: move-forever3 6s linear infinite;
  &:nth-child(1) {
    animation-delay: -8s;
  }
}
.parallax4 > use {
  animation: move-forever4 4s linear infinite;
  &:nth-child(1) {
    animation-delay: -8s;
  }
}
@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever3 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@keyframes move-forever4 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
</style>
