<template>
  <div class="container-fluid pt-2 main-mobile">
    <NavOption
      navLink="/application-mobile"
      navText="Retour"
      navBorderColor="#800080"
      iconColorClass="purple"
    />
    <section class="hero-banner container">
      <div class="flex">
        <div
          class="hero-banner__img col-lg-6 order-lg-2 d-flex justify-content-center"
        >
          <img
            src="@/assets/pictures/pwa-team.webp"
            alt="team-building-application-mobile"
            class="delta"
            width="500"
          />
        </div>
        <div class="hero-banner__speech col-lg-6 mr-lg-5">
          <h1 class="mt-5">
            Expérience Utilisateur Révolutionnaire grâce aux Applications
            Mobiles Progressives <span>(PWA)</span>
          </h1>
          <p class="p-1">
            Les <strong>PWA</strong> combinent Vue.js et Symfony pour
            <strong>créer des applications</strong>
            rapides, accessibles et compatibles avec divers appareils. Cette
            technologie révolutionne l'expérience mobile traditionnelle en
            offrant une accessibilité optimale et une performance supérieure.
          </p>
          <!---<a href="#" class="btn btn-slide btn-lg border">en savoir plus</a>-->
        </div>
      </div>
    </section>
    <div class="pt-5">
      <section class="p-md-3 container-fluid">
        <div class="row-icon pt-3">
          <div class="row-icon__cadre mb-5">
            <i class="col-3 far fa-paper-plane indigo-text"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Prix</h4>
              <p class="text-muted mb-lg-0">À partir de 6000€</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Technologie</h4>
              <p class="text-muted mb-lg-0">Vuejs + Symfony</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Hébergement</h4>
              <p class="text-muted mb-lg-0">
                À définir selon le type d'application
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <article class="container-fluid">
      <div class="article-title container-lg">
        <h2>
          VueJS et Symfony: Une Alliance Puissante pour des PWA Performantes.
        </h2>
      </div>
      <section class="container mt-3 mt-lg-5">
        <div class="block-row pt-5">
          <div class="block-text col-lg-5 slide-left">
            <TitleColor
              title="PWA : Expérience Utilisateur sans Faille"
              titleColor="#ffc0cb"
            />
            <p class="text-left mx-auto w-responsive">
              Les <strong>Progressive Web Apps</strong> (<strong>PWA</strong>)
              révolutionnent l'<strong>expérience utilisateur</strong> sur le
              web. En combinant la puissance de Vue.js pour le front-end et
              Symfony pour l'<strong>API</strong> back-end, nous créons des
              <strong>applications web</strong> offrant une navigation fluide et
              rapide. Les <strong>PWA</strong>
              permettent un accès instantané et réactif à vos services, offrant
              une expérience similaire à celle d'une application native. Avec
              notre approche axée sur Vue.js et Symfony, nous garantissons des
              performances optimales, une accessibilité accrue et une
              compatibilité multiplateforme pour votre public.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/responsive-mobile.webp"
                alt="mockup-application"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Section: Content-->
      <section class="container my-5 pb-lg-5">
        <!-- Grid row -->
        <div class="block-row reverse">
          <!-- Grid column -->
          <div class="block-text slide-right col-lg-5">
            <!-- Category -->
            <!-- Post title -->
            <TitleColor
              title="Accessibilité et Performance Optimalisées"
              titleColor="#ffc0cb"
              bgColor="hsl(263, 47%, 58%)"
            />
            <!-- Excerpt -->
            <p>
              Les <strong>Progressive Web Apps</strong> (<strong>PWA</strong>)
              basées sur Vue.js et Symfony offrent des avantages indéniables.
              Notre approche combinant ces technologies de pointe permet de
              <strong>créer des applications web</strong> hautement
              performantes, accessibles depuis n'importe quel navigateur. Grâce
              à l'efficacité de Vue.js et à la robustesse de Symfony, nous
              garantissons une <strong>expérience utilisateur</strong> optimale,
              une rapidité de chargement et une réactivité hors pair. Nos
              <strong>PWA</strong>
              sont conçues pour s'adapter à divers appareils, offrant ainsi une
              portabilité et une expérience homogène sur tous les supports.
            </p>
          </div>
          <!-- Grid column -->
          <!-- Grid column -->
          <div class="col-lg-6">
            <!-- Featured image -->
            <div class="view overlay rounded z-depth-2 mb-lg-0 mb-2 slide-left">
              <img
                src="@/assets/pictures/mobile-pwa-select.webp"
                alt="graphic-on-screen-mobile"
                class="img-fluid"
              />
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
    </article>
    <CallActionServiceSolo
      img="team-pwa.webp"
      h3="Découvrez nos PWA (progressive web app)"
      webServiceValue="Développement d'une PWA"
      h3Color="#ebdcdc"
      p="Sentez vous libre de nous contacter pour toute question concernant le développement d'une PWA, notre équipe vous apportera les réponses adaptées à
        votre projet web."
    />
    <FooterOption btnBorderColor="purple" iconColorClass="purple" />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import TitleColor from "@/components/partials/TitleColor.vue";
import CallActionServiceSolo from "@/components/partials/CallActionServiceSolo.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";
export default {
  name: "SeaRef",
  components: {
    TitleColor,
    CallActionServiceSolo,
    NavOption,
    FooterOption,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "ERP & CRM",
      description: [
        {
          content:
            "Optimisez votre gestion avec des outils ERP/CRM sur mesure chez Malaweb. Exploitez les avantages des Progressive Web Apps pour une accessibilité sans installation. Modernisez votre entreprise avec des solutions adaptées à vos besoins.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/prix-pwa-mobile",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.main-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(to left, #ffc0cb, #800080);

  & .hero-banner {
    //@include background-svg;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    color: $color-text;
    padding: 40px 20px;
    @media screen and (min-width: 992px) {
      padding: 0px 0px 70px 20px;
    }
    & .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
      }
    }
    &__img {
      @include image-fade-in(2s);
      & img {
        @media screen and (max-width: 1245px) {
          width: 100%;
        }
      }
    }

    & .hero-banner__speech .btn {
      position: relative;
      overflow: hidden;
      z-index: 9;
    }
    & .hero-banner__speech .btn-slide {
      background-color: rgba(0, 0, 0, 0.1);
      color: $color-text;
    }
    & .hero-banner__speech .btn-slide:before {
      content: "";
      background: #f27316;
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      -ms-transition: all 0.5s ease-in-out 0s;
      -o-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
    }
    & .hero-banner__speech .btn-slide:hover:before {
      width: 100%;
    }

    & .hero-banner__speech {
      @media screen and (min-width: 992px) {
        padding-left: 50px;
      }
      & h1 > span {
        color: $color-darker;
      }
      & p {
        text-align: start;
      }
      & a {
        margin: 15px 0px 20px 0px;
      }
    }
  }
  & article {
    & h2 {
      text-align: center;
      color: $color-text;
      margin: 80px 10px 50px;
      font-size: 2em;
      text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
    }
  }
  & .row-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &__cadre {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;
      min-height: 100%;
      padding: 10px 20px;
      text-align: center;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
      border-radius: 59px;
      border: 0.1px outset $color-lighter;
      box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
      & i {
        color: #ffc0cb;
        font-size: 2em;
        border-radius: 100%;
        border: 0.5px outset #ffc0cb;
        padding: 18px;
      }
      & h4 {
        font-size: 1.3em;
        color: #ffc0cb;
      }
      & p {
        text-align: center;
        font-size: 0.9em;
      }
    }
  }
  .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
  .callAction {
    margin: 20px 0px 0px 0px;
  }
}
</style>
