<template>
  <div class="container-fluid pt-2 main-seo">
    <NavOption
      navLink="/referencement-google"
      navText="Retour"
      navBorderColor="cyan"
      iconColorClass="cyan"
    />
    <section class="hero-banner container">
      <div class="flex">
        <div
          class="hero-banner__img col-lg-7 order-lg-2 d-flex justify-content-center"
        >
          <img
            src="@/assets/pictures/sea-nouveau-client.webp"
            alt="logo-wordpress-circle"
            class="delta"
            width="500"
          />
        </div>
        <div class="hero-banner__speech col-lg-5 mr-lg-5">
          <h1 class="mt-5">
            Maximisez votre visibilité grâce à<br />
            <span>google ads</span>
          </h1>
          <p class="p-1">
            <strong>Boostez votre présence en ligne</strong> avec notre Service
            <strong>SEA</strong> (<strong>Search Engine Advertising</strong>).
            Atteignez vos objectifs publicitaires en ciblant efficacement votre
            audience avec des annonces pertinentes.
          </p>
          <!---<a href="#" class="btn btn-slide btn-lg border">en savoir plus</a>-->
        </div>
      </div>
    </section>
    <div class="pt-5">
      <section class="p-md-3 container-fluid">
        <div class="row-icon pt-3">
          <div class="row-icon__cadre mb-5">
            <i class="col-3 far fa-paper-plane indigo-text"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Paramétrage</h4>
              <p class="text-muted mb-lg-0">
                Paramétrage de votre campagne google selon vos objectifs.
              </p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Utilisation</h4>
              <p class="text-muted mb-lg-0">
                200€/mois<br />Comprend le paramétrage et le suivi (hors frais
                google)
              </p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Suivi</h4>
              <p class="text-muted mb-lg-0">
                Optimisation et rapports des résultats de votre campagne google
                ads.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <article class="container-fluid">
      <div class="article-title container-lg">
        <h2>Campagnes Publicitaires Ciblées</h2>
      </div>
      <section class="container mt-3 mt-lg-5">
        <div class="block-row pt-5">
          <div class="block-text col-lg-5 slide-left">
            <TitleColor
              title="Des annonces adaptées à votre audience"
              titleColor="#00b4db"
            />
            <p class="text-left mx-auto w-responsive">
              Notre approche méticuleuse du <strong>SEA</strong> commence par
              une compréhension approfondie de votre public cible. Nous
              élaborons des annonces sur mesure, créatives et pertinentes, qui
              captent l'attention de votre audience spécifique. Chaque élément
              de nos <strong>campagnes publicitaires google</strong> est
              minutieusement conçu pour résonner avec vos prospects et clients
              potentiels, maximisant ainsi la pertinence de vos
              <strong>annonces google</strong>
              et augmentant les chances de conversion. Notre objectif est de
              vous aider à atteindre et à influencer efficacement votre audience
              idéale.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/sea-header.webp"
                alt="graphic-economy-energy"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Section: Content-->
      <section class="container my-5 pb-lg-5">
        <!-- Grid row -->
        <div class="block-row reverse">
          <!-- Grid column -->
          <div class="block-text slide-right col-lg-5">
            <!-- Category -->
            <!-- Post title -->
            <TitleColor
              title="Suivi et Optimisation Continus"
              titleColor="#00b4db"
              bgColor="hsl(263, 47%, 58%)"
            />
            <!-- Excerpt -->
            <p>
              Notre engagement envers votre
              <strong>campagne google ads</strong> ne s'arrête pas à sa
              création. Nous assurons une surveillance et une analyse continues
              des performances de vos annonces. Cette démarche nous permet
              d'identifier les tendances, de comprendre les comportements des
              utilisateurs et d'apporter des ajustements stratégiques. Grâce à
              un suivi minutieux et à des ajustements réguliers, nous optimisons
              constamment vos <strong>campagnes google ads</strong> pour
              garantir des résultats optimaux et un retour sur investissement
              maximal. Notre objectif est de maintenir vos
              <strong>annonces google</strong> pertinentes et efficaces,
              assurant ainsi une visibilité continue et des performances
              durables
            </p>
          </div>
          <!-- Grid column -->
          <!-- Grid column -->
          <div class="col-lg-6">
            <!-- Featured image -->
            <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left mb-3">
              <img
                src="@/assets/pictures/sea-analyse.webp"
                alt="graphic-economy-energy"
                class="img-fluid"
              />
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
    </article>
    <CallActionServiceSolo
      img="google-add.webp"
      h3="Campagnes google ads"
      webServiceValue="Campagne google (sea)"
      h3Color="#ebdcdc"
      p="Sentez vous libre de nous contacter pour toute question concernant votre
        stratégie SEA, notre équipe vous apportera les réponses adaptées à
        votre projet web."
    />
    <FooterOption btnBorderColor="cyan" iconColorClass="cyan" />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import TitleColor from "@/components/partials/TitleColor.vue";
import CallActionServiceSolo from "@/components/partials/CallActionServiceSolo.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";
export default {
  name: "SeaRef",
  components: {
    TitleColor,
    CallActionServiceSolo,
    NavOption,
    FooterOption,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Campagne google ads",
      description: [
        {
          content:
            "Découvrez les avantages du SEA avec Malaweb. Des campagnes publicitaires ciblées, une gestion efficace des annonces et un ROI (Return On Investment) optimal. Optimisez votre présence en ligne et atteignez votre public cible grâce à nos stratégies avancées en Search Engine Advertising. Boostez votre visibilité et augmentez vos conversions avec Malaweb dès aujourd'hui.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/prix-referencement-sea",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.main-seo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(to left, #0083b0, #00b4db);

  & .hero-banner {
    //@include background-svg;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    color: $color-text;
    padding: 40px 20px;
    @media screen and (min-width: 992px) {
      padding: 0px 0px 70px 20px;
    }
    & .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
      }
    }
    &__img {
      @include image-fade-in(2s);
      & img {
        @media screen and (max-width: 1245px) {
          width: 100%;
        }
      }
    }

    & .hero-banner__speech .btn {
      position: relative;
      overflow: hidden;
      z-index: 9;
    }
    & .hero-banner__speech .btn-slide {
      background-color: rgba(0, 0, 0, 0.1);
      color: $color-text;
    }
    & .hero-banner__speech .btn-slide:before {
      content: "";
      background: #f27316;
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      -ms-transition: all 0.5s ease-in-out 0s;
      -o-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
    }
    & .hero-banner__speech .btn-slide:hover:before {
      width: 100%;
    }

    & .hero-banner__speech {
      @media screen and (min-width: 992px) {
        padding-left: 50px;
      }
      & h1 > span {
        color: $color-darker;
      }
      & p {
        text-align: start;
      }
      & a {
        margin: 15px 0px 20px 0px;
      }
    }
  }
  & .row-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &__cadre {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;
      min-height: 100%;
      padding: 10px 20px;
      text-align: center;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
      border-radius: 59px;
      border: 0.1px outset $color-lighter;
      box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
      & i {
        color: #00b4db;
        font-size: 2em;
        border-radius: 100%;
        border: 0.5px outset #00b4db;
        padding: 18px;
      }
      & h4 {
        font-size: 1.3em;
        color: #00b4db;
      }
      & p {
        text-align: center;
        font-size: 0.9em;
      }
    }
  }
  & article {
    & h2 {
      text-align: center;
      color: $color-text;
      text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
      margin: 80px 10px 50px;
      font-size: 2em;
    }
  }
  .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
  .callAction {
    margin: 20px 0px 0px 0px;
  }
}
</style>
