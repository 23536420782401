<template>
  <div class="container home-contact image-fade-in">
    <div class="row mb-5">
      <div class="col-md-4 mx-auto">
        <div class="view mb-4 pb-2">
          <img
            src="@/assets/pictures/world-web.webp"
            class="img-fluid"
            alt="map-world"
          />
        </div>
      </div>
    </div>
    <div class="home-contact__text">
      <h3 class="text-center mb-4 pb-2">Le monde vous attend !</h3>

      <p class="text-center mx-auto mb-4 pb-2">
        Soyez visible sur les
        <strong>moteurs de recherche et gagnez en visibilité</strong>, ne ratez
        plus l'occasion de vous faire connaître de vos
        <strong>nouveaux clients</strong>.
      </p>

      <router-link to="/contact" class="btn btn-secondary btn-rounded btn-md"
        ><i class="fas fa-clone left"></i>
        <strong> Créez votre site web</strong> sans plus attendre.</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContact",
};
</script>

<style lang="scss" scoped>
.home-contact {
  margin-bottom: 200px;
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & h3 {
      color: $bold-light-primary;
    }
    & a {
      background: $bold-light-primary;
      width: fit-content;
      margin: auto;
      box-shadow: 0px 30px 40px -20px $color-text-grey;
      &:hover {
        background: $color-primary;
        border-color: $color-text;
      }
    }
  }
}
</style>
