<template>
  <CreationRead v-if="Town" :town="Town" :key="townKey" />
</template>

<script>
import CreationRead from "@/components/plugins/CreationRead.vue";
import { mapGetters, mapActions } from "vuex";
//import { useMeta } from "vue-meta";
export default {
  name: "CreationTownView",
  components: { CreationRead },
  created() {
    const id = this.$route.params.id;
    this.$store.dispatch("GetTown", id);
  },

  data() {
    return {
      form: {
        content: "",
        article: "",
      },
      formVisible: false,
      showError: false,
      townKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      Token: "StateToken",
      User: "StateUser",
      Town: "StateTown",
    }),
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  watch: {
    Town: {
      handler(newVal, oldVal) {
        // Vérifie si Town a effectivement changé
        if (newVal && newVal !== oldVal) {
          this.townKey += 1; // Change la clé pour forcer le re-rendu de TownRead
        }
      },
      deep: true, // Assure que les changements profonds sont détectés
    },
  },
  methods: {
    ...mapActions(["GetTowns", "GetTown"]),
  },
};
</script>
