<template>
  <div class="services">
    <ServicesHeader />
    <ServicesToggle />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import ServicesHeader from "@/components/services/ServicesHeader.vue";
import ServicesToggle from "@/components/services/ServicesToggle.vue";
export default {
  components: { ServicesHeader, ServicesToggle },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Conception web",
      description: [
        {
          content:
            "Découvrez Malaweb, votre partenaire digital pour la création de sites web, le développement d'applications mobiles, l'optimisation SEO et le marketing digital. Avec notre expertise en SEA, nous vous offrons des solutions personnalisées pour propulser votre présence en ligne vers le succès.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/services",
        },
      ],
    });
  },
};
</script>
