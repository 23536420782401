<template>
  <div class="container z-depth-1 my-5 p-lg-5">
    <!-- Section -->
    <section class="about-service">
      <h2 class="font-weight-bold text-center dark-grey-text pb-2">
        Nos Services
      </h2>
      <hr class="w-header my-4" />
      <p class="lead text-center text-muted pt-2 mb-5">
        Des technologies adaptées aux besoins de votre entreprise et à votre
        budget
      </p>

      <div class="row d-flex justify-content-center px-3">
        <div class="col-md-6 col-xl-4 slide-left">
          <h3 class="font-weight-normal">
            <a class="dark-grey-text" href="#">Wordpress</a>
          </h3>
          <p class="small grey-text">CMS, site vitrine</p>
        </div>

        <div class="col-md-6 slide-right">
          <p class="text-muted mb-5 pb-2">
            Pour les petites entreprises ou auto-entrepreneurs, qui souhaitent
            disposer d'un <strong>site internet pas cher</strong> afin de donner
            de la crédibilité à leur entreprise et
            <strong>gagner en visibilité</strong>.
          </p>
        </div>

        <div class="col-md-6 col-xl-4 slide-left">
          <h3 class="font-weight-normal">
            <a class="dark-grey-text" href="#">Web Développement</a>
          </h3>
          <p class="small grey-text">PHP, Symfony, Vuejs</p>
        </div>

        <div class="col-md-6 slide-right">
          <p class="text-muted mb-5 pb-2">
            Des <strong>solutions web</strong> pensées à grande échelle afin
            d'assurer la maintenabilité et la scalabilité future de votre projet
            en offrant une
            <strong>expérience utilisateur fluide et ergonomique</strong>.
          </p>
        </div>

        <div class="col-md-6 col-xl-4 slide-left">
          <h3 class="font-weight-normal">
            <a class="dark-grey-text" href="#">Mobile App</a>
          </h3>
          <p class="small grey-text">React native, Android, iOS</p>
        </div>

        <div class="col-md-6 slide-right">
          <p class="text-muted mb-5 pb-2">
            Pour <strong>déployer une application native</strong> sur les
            plateformes <strong>ios</strong> et <strong>Android</strong> avec un
            code source unique afin de
            <strong>limiter les coûts de développement</strong> et facilité le
            développement de vos <strong>applications mobiles</strong>.
          </p>
        </div>
      </div>
    </section>
    <!-- Section -->
  </div>
</template>

<script>
export default {
  name: "AboutService",
};
</script>

<style lang="scss" scoped>
.about-service {
  a {
    color: $color-primary;
  }
}
</style>
