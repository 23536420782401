<template>
  <section class="crossed-card">
    <div class="header fall-down">
      <h2>Le digital à votre service</h2>
      <h2>Powered by Malaweb</h2>
      <p>
        Nous vous aidons à développer des
        <strong>solutions numériques</strong> adaptées à votre entreprise et
        établirons à vos côtés une <strong>stratégie digitale</strong> pour
        votre marque.
      </p>
    </div>
    <div class="row1-container image-fade-in">
      <a href="/referencement-google" class="box box-down cyan">
        <h3>Référencement</h3>
        <p>
          <strong>SEO</strong> (<strong>référencement naturel</strong>) :
          processus d'<strong
            >optimisation des sites Web pour les moteurs de recherche</strong
          >.
        </p>
        <div>
          <BtnCross text="En savoir plus >" color="hsl(180, 62%, 55%)" />
          <img src="@/assets/icons/seo.webp" alt="icon-seo" />
        </div>
      </a>

      <a href="/developpement-web" class="box box-up orange">
        <h3>Développement Web</h3>
        <p>
          <strong>E-commerce</strong>, <strong>site vitrine</strong>,
          <strong>wordpress</strong>, des
          <strong>applications web</strong> adaptées à votre activité pour
          faciliter votre quotidien.
        </p>
        <div>
          <BtnCross text="En savoir plus >" color="#f27316" />
          <img src="@/assets/icons/web-development.webp" alt="icon-computer" />
        </div>
      </a>

      <a href="/application-mobile" class="box box-down purple">
        <h3>Application Mobile</h3>
        <p>
          Offrez à vos utilisateurs une expérience mobile unique et simplifier
          la communication entreprises-clients.
        </p>
        <div>
          <BtnCross text="En savoir plus >" color="hsl(263, 47%, 58%)" />
          <img src="@/assets/icons/app-mobile.webp" alt="icon-smartphone" />
        </div>
      </a>
    </div>

    <div class="row2-container image-fade-in">
      <a href="/marketing-digital" class="box box-up yellow">
        <h3>Marketing Digital</h3>
        <p>
          <strong>Gagnez en visibilité</strong>, développez votre stratégie de
          communication sur vos réseaux sociaux.
        </p>
        <div>
          <BtnCross text="En savoir plus >" color="hsl(59, 91%, 65%)" />
          <img
            src="@/assets/icons/social-media.webp"
            alt="icon-logo-social-media"
          />
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import BtnCross from "@/components/buttons/BtnCross.vue";
export default {
  name: "CrossCard",
  components: { BtnCross },
  /*mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },*/
};
</script>

<style lang="scss" scoped>
.crossed-card {
  font-size: 13px;
  padding: 5px 5px 50px;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 992px) {
    padding: 10px 50px;
  }
  & h2:first-of-type {
    font-weight: 200;
    color: $color-text-grey;
  }
  & h2:last-of-type {
    color: $bold-light-primary;
  }
  @media (max-width: 400px) {
    h2 {
      font-size: 1.5rem;
    }
  }
  & .header {
    text-align: center;
    line-height: 0.8;
    margin-bottom: 50px;
    margin-top: 100px;
    padding: 15px;
    & p {
      width: 70%;
      font-size: 1.5em;
      margin: 0 auto;
      line-height: 2;
      color: $bold-light;
      text-align: center;
    }
  }
  & .box {
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px $color-text-grey;
    display: block;
    max-width: 350px;
    margin: 30px;
    padding: 10px 20px;
    &:hover,
    a:hover {
      @include floating;
      & .btn-cross,
      .btn-cross:hover {
        visibility: visible;
      }
    }
    & h3 {
      color: $color-text-grey;
      font-size: 1.5em;
      font-weight: 100;
    }
    & p {
      color: $bold-light;
      text-align: left;
    }
    & div {
      display: flex;
      justify-content: space-between;
      align-items: end;
      & img {
        width: 80px;
      }
    }
  }
}

.cyan {
  border-top: 3px solid hsl(180, 62%, 55%);
}
.purple {
  border-top: 3px solid hsl(263, 47%, 58%);
}
.orange {
  border-top: 3px solid $color-primary;
}
.yellow {
  border-top: 3px solid hsl(59, 91%, 65%);
}
@media (min-width: 992px) {
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
  }

  .row2-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box-up {
    position: relative;
    top: -150px;
  }
  /*.box-down {
    position: relative;
    top: 150px;
  }*/
  .box {
    width: 30%;
    padding: 30px;
    margin: 20px;
  }
}
</style>
