<template>
  <TownRead v-if="Town" :town="Town" :key="townKey" />
</template>

<script>
import TownRead from "@/components/plugins/TownRead.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TownView",
  components: { TownRead },
  created() {
    let slug = this.$route.params.slug;
    this.$store.dispatch("GetTownBySlug", slug);
  },
  data() {
    return {
      townKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      Token: "StateToken",
      User: "StateUser",
      Town: "StateTown",
    }),
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  watch: {
    Town: {
      handler(newVal, oldVal) {
        // Vérifie si Town a effectivement changé
        if (newVal && newVal !== oldVal) {
          this.townKey += 1; // Change la clé pour forcer le re-rendu de TownRead
        }
      },
      deep: true, // Assure que les changements profonds sont détectés
    },
  },
  methods: {
    ...mapActions(["GetTowns", "GetTown", "GetTownBySlug"]),
  },
};
/*import TownRead from "@/components/plugins/TownRead.vue";
import { mapGetters, mapActions } from "vuex";

//import { useMeta } from "vue-meta";
export default {
  name: "TownView",
  components: { TownRead },
  created() {
    let slug = this.$route.params.slug;
    this.$store.dispatch("GetTownBySlug", slug);
    this.townKey += 1;
  },

  data() {
    return {
      form: {
        content: "",
        article: "",
      },
      formVisible: false,
      showError: false,
      townKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      Token: "StateToken",
      User: "StateUser",
      Town: "StateTown",
    }),
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    ...mapActions(["GetTowns", "GetTown", "GetTownBySlug"]),
  },
};*/
</script>
