<template>
  <main class="container-fluid">
    <div class="container pt-5 my-5">
      <!--Section: Content-->
      <section class="dark-grey-text text-center">
        <h2
          class="font-weight-normal text-uppercase font-small grey-text mb-4 fall-down"
        >
          Features
        </h2>
        <!-- Section heading -->
        <h3 class="font-weight-bold black-text mb-4 pb-2 fall-down">
          services
        </h3>
        <hr class="w-header" />
        <!-- Section description -->
        <p class="lead text-muted mx-auto mt-4 pt-2 mb-5">
          Cliquez sur le service web qui vous intéresse, on vous explique tout.
        </p>

        <!--First row-->
        <div class="row fall-down">
          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="/developpement-web"
              class="card hoverable service-card orange"
            >
              <!-- Card content -->
              <div class="card-body">
                <img
                  src="@/assets/icons/web-development.webp"
                  alt="picture-team"
                />
                <h4 class="black-text mb-0">Développement web</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="/application-mobile"
              class="card hoverable service-card purple"
            >
              <!-- Card content -->
              <div class="card-body">
                <img
                  src="@/assets/icons/app-mobile.webp"
                  alt="mobile-picture"
                />
                <h4 class="black-text mb-0">App mobile</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="/referencement-google"
              class="card hoverable service-card cyan"
            >
              <!-- Card content -->
              <div class="card-body">
                <img src="@/assets/icons/seo.webp" alt="hand-hold-earth" />
                <h4 class="black-text mb-0">Référencement</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="/marketing-digital"
              class="card hoverable service-card yellow"
            >
              <!-- Card content -->
              <div class="card-body">
                <img
                  src="@/assets/icons/social-media.webp"
                  alt="desk-with-paper"
                />
                <h4 class="black-text mb-0">Marketing digital</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->
        </div>
        <!--First row-->
      </section>
    </div>

    <div class="container my-5">
      <!--Section: Content-->
      <section class="dark-grey-text">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-5">
          <h2 class="text-left font-weight-bold mb-4">
            Digitaliser son entreprise<span class="span-ml">:</span>
          </h2>
          <p class="text-left mx-auto mb-5">
            Malaweb accompagne votre entreprise dans sa transition vers le monde
            <strong>numérique</strong> en offrant une gamme complète de services
            axés sur la <strong>digitalisation</strong>. Notre expertise en
            <strong>développement web</strong> assure la
            <strong>création de sites web</strong> modernes, d'<strong
              >applications mobile</strong
            >
            intuitives et de solutions
            (<strong>erp</strong>/<strong>crm</strong>) adaptées aux besoin de
            votre entreprise.
          </p>
        </div>
        <div class="block-row pt-5">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColored title="Développement Web" titleColor="#f27316" />
            <p class="text-left mx-auto w-responsive">
              Malaweb vous propose un <strong>développement web</strong> sur
              mesure pour votre entreprise. Nous concevons des
              <strong>sites vitrine</strong> élégants, des
              <strong>sites e-commerce</strong> performants et des
              <strong>solutions SaaS</strong> personnalisées, comme les
              <strong>ERP</strong> et <strong>CRM</strong>. Chaque projet est
              façonné selon les besoins spécifiques de nos clients, mettant
              l'accent sur la qualité, la fonctionnalité et l'<strong
                >expérience utilisateur</strong
              >
              pour garantir des plateformes en ligne optimales.
            </p>
            <ArrowBtn href="/developpement-web" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/pictures/service-site-internet.webp"
              alt="graphic-economy-energy"
              class="mockup-site-web"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->
    <section class="container my-5 pb-5">
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColored
            title="Développement Mobile"
            titleColor="hsl(263, 47%, 58%)"
            bgColor="hsl(263, 47%, 58%)"
          />
          <!-- Excerpt -->
          <p>
            Un <strong>développement mobile</strong> mettant l'accent sur des
            <strong>interfaces intuitives</strong> et une
            <strong>expérience utilisateur</strong> fluide. Notre approche vise
            à vous fournir des solutions adaptées à vos besoins à travers la
            <strong>création d'applications mobile</strong> pour les plateformes
            <strong>iOS</strong>, <strong>Android</strong> ou sous forme de
            <strong>PWA</strong> (<strong>Progressive Web Apps</strong>).
            <strong>Des applications mobile</strong> optimisées pour répondre
            aux exigences spécifiques des professionnels et offrir une
            <strong>expérience utilisateur intuitive</strong> et performante
            c'est l'engagement Malaweb.
          </p>
          <ArrowBtn
            href="/application-mobile"
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/service-app-mobile.webp"
              alt="graphic-economy-energy"
              class="mockup-mobile"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
    <ContactUsAction />
    <section class="container mt-5">
      <div class="block-row pt-5 mb-5">
        <!-- Grid column -->
        <div class="block-text col-lg-5 slide-left">
          <!-- Featured image -->

          <!-- Section description -->
          <TitleColored
            title="Référencement"
            titleColor="hsl(180, 62%, 55%)"
            bgColor="hsl(180, 62%, 55%)"
          />
          <p class="text-left mx-auto w-responsive">
            Le <strong>SEO</strong> et <strong>SEA</strong> vise à
            <strong
              >optimiser le positionnement de votre entreprise sur les moteurs
              de recherche</strong
            >. Nous concevons des <strong>campagnes Google</strong> ciblées pour
            garantir un <strong>positionnement en première page</strong>,
            assurant ainsi une visibilité maximale. Notre objectif est de placer
            votre entreprise au
            <strong>sommet des résultats de recherche</strong>, en mettant en
            œuvre des stratégies adaptées pour
            <strong>accroître votre visibilité</strong> et
            <strong>votre attractivité en ligne</strong>.
          </p>
          <ArrowBtn
            href="/referencement-google"
            whiteCircleBackground="hsl(180, 62%, 45%)"
            circleBoxShadow="0 0 2px 2px hsl(180, 62%, 45%)"
          />
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-12 col-lg-5 img-double slide-right">
          <img
            src="@/assets/pictures/service-marketing-digital.webp"
            alt="graphic-economy-energy"
            class="loupe"
          />
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColored
            title="Marketing Digital"
            titleColor="hsl(59, 91%, 65%)"
            bgColor="hsl(59, 91%, 65%)"
          />
          <!-- Excerpt -->
          <p>
            Notre service de <strong>conception graphique</strong> offre une
            gamme complète de créations telles que des <strong>logos</strong>,
            des <strong>flyers</strong>, des
            <strong>affiches publicitaires</strong>, des
            <strong>menus pour restaurants</strong>, des
            <strong>plaquettes commerciales</strong> et des
            <strong>cartes de visite</strong>. Chaque élément est conçu avec
            soin et créativité pour répondre aux besoins spécifiques de votre
            entreprise et captiver votre audience cible, renforçant ainsi votre
            <strong>identité visuelle</strong> et votre
            <strong>présence sur le marché</strong>.
          </p>
          <ArrowBtn
            href="/marketing-digital"
            whiteCircleBackground="hsl(56, 100%, 50%)"
            circleBoxShadow="0 0 2px 2px hsl(56, 100%, 50%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/service-seo.webp"
              alt="graphic-economy-energy"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <div class="container service-contact image-fade-in">
      <div class="row mb-5">
        <div class="col-md-4 mx-auto">
          <div class="view mb-lg-4 pb-2">
            <img
              src="@/assets/pictures/build-siteweb.webp"
              class="img-fluid"
              alt="statistic-paper"
            />
          </div>
        </div>
      </div>
      <div class="service-contact__text">
        <h3 class="text-center mb-4 pb-2">
          Réalisons ensemble votre projet numérique
        </h3>

        <p class="text-center mx-auto mb-4 pb-2">
          Sentez vous libre de nous contacter pour toute question concernant
          votre projet numérique, notre équipe vous apportera les réponses
          adaptées à votre projet web.
        </p>

        <router-link to="/contact" class="btn btn-secondary btn-rounded btn-md"
          ><i class="fas fa-clone left"></i> Digitaliser mon
          entreprise</router-link
        >
      </div>
    </div>
  </main>
</template>

<script>
import ArrowBtn from "@/components/partials/ArrowBtn.vue";
import TitleColored from "@/components/partials/TitleColored.vue";
import ContactUsAction from "@/components/partials/ContactUsAction.vue";
export default {
  name: "ServicesToggle",
  components: { ArrowBtn, TitleColored, ContactUsAction },
};
</script>

<style lang="scss" scoped>
.card-service:hover {
  @include grow;
}
.service-card {
  @include thumbnail;
  height: 100%;
  padding: 15px 15px 15px;
  & .card-body {
    padding: 0px;
    & h4 {
      font-size: 1.3em;
      margin: 15px 5px 0px;
      color: $color-text-grey;
    }
  }
}
.block-row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  & video,
  img {
    width: 500px;
    max-width: 90vw;
    margin-top: 30px;
    margin-bottom: 10px;
    @media screen and (min-width: 992px) {
      width: 500px;
      margin-bottom: 0px;
      margin-top: 30px;
    }
  }
  & .img-double {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .hr {
    width: 50px;
    height: 3px;
    background-color: $background;
  }
}

.reverse {
  flex-direction: column-reverse;
  @media screen and (min-width: 992px) {
    flex-direction: row-reverse !important;
  }
}
.ink {
  width: 200px;
  height: 200px;
  background-color: #000;
  border-radius: 50%;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 150px;
    background-color: #000;
    border-radius: 50%;
    transform: rotate(45deg);
  }

  &::before {
    top: -30px;
    left: 50px;
  }

  &::after {
    bottom: -60px;
    right: 50px;
  }
}
.service-contact {
  margin-top: 100px;
  margin-bottom: 200px;
  @media screen and (min-width: 992px) {
    margin-top: 200px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & h3 {
      color: $bold-light-primary;
    }
    & a {
      background: $bold-light-primary;
      width: fit-content;
      margin: auto;
      &:hover {
        background: $color-primary;
        border-color: $color-text;
      }
    }
  }
}
.cyan {
  border-top: 1.5px solid hsl(180, 62%, 55%);
}
.purple {
  border-top: 1.5px solid hsl(263, 47%, 58%);
}
.orange {
  border-top: 1.5px solid $color-primary;
}
.yellow {
  border-top: 1.5px solid hsl(59, 91%, 65%);
}
.span-ml {
  margin-left: 4px;
}
</style>
