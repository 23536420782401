<template>
  <main id="appMob">
    <NavOption navBorderColor="cyan" iconColorClass="cyan" />
    <div class="header">
      <h2>SEO & SEA</h2>
      <h2>Powered by Malaweb</h2>
    </div>
    <div class="toggle-section">
      <div class="toggle-content">
        <div class="toggle-item active" id="dev-home">
          <div class="container my-5">
            <section class="text-center">
              <div class="header-card pb-2 mb-4">
                <h3 class="">RÉFÉRENCEMENT GOOGLE</h3>

                <p class="text-center mb-5">
                  Découvrez comment nos <strong>stratégies SEO</strong>/<strong
                    >SEA</strong
                  >
                  propulsent votre entreprise vers la
                  <strong>première page de Google</strong>.
                </p>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-12 mb-4">
                  <div class="card pricing-card">
                    <div class="pricing-card__title">
                      <p class="option">SEO</p>
                    </div>

                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <div class="breakword">
                          <p>à partir de</p>
                        </div>
                        <div class="head-body mb-4">
                          <p class="price">200</p>
                          <span class="euro">€</span>
                        </div>
                        <div class="b-ttc">
                          <p class="ttc"><span>/</span>ttc</p>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iOrange"
                              aria-hidden="true"
                            ></i>
                            <span>GLOBAL :</span> <br />Inclut le
                            <strong>SEO</strong> à la conception du site,
                            intégré dans le prix final de votre projet
                            numérique.
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iOrange"
                              aria-hidden="true"
                            ></i>
                            <span><strong>AUDIT SEO</strong> :</span>
                            <br />Évaluation approfondie de l'état actuel d'un
                            site et de son référencement, avec une liste des
                            corrections ou modifications à apporter.
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iOrange"
                              aria-hidden="true"
                            ></i>
                            <span>PAGE :</span> <br />Coût basé sur l'<strong
                              >audit seo</strong
                            >
                            effectué et les ajustements par page nécessaires.
                          </p>
                          <div class="hr"></div>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <CyberBtn link="/prix-referencement-seo" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-12 mb-4">
                  <div class="card pricing-card">
                    <div class="pricing-card__second">
                      <p class="option">SEA</p>
                    </div>

                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <div class="breakword">
                          <p>à partir de</p>
                        </div>
                        <div class="head-body mb-4">
                          <p class="price">450</p>
                          <span class="euro">€</span>
                        </div>
                        <div class="b-ttc">
                          <p class="ttc"><span>/</span>ttc</p>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iBlack"
                              aria-hidden="true"
                            ></i>
                            <span>PARAMETRAGE :</span> <br />Configuration
                            précise de la
                            <strong>campagne google ads</strong> selon les
                            objectifs commerciaux définis.
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iBlack"
                              aria-hidden="true"
                            ></i>
                            <span>UTILISATION :</span> <br />Facturation
                            mensuelle pour l'administration continue, excluant
                            les frais Google.
                          </p>
                          <div class="hr"></div>
                        </li>
                        <li>
                          <p>
                            <i
                              class="fa fa-check iBlack"
                              aria-hidden="true"
                            ></i>
                            <span>SUIVIS :</span> <br />Ajustements réguliers
                            pour aligner la
                            <strong>campagne google ads</strong> avec les
                            objectifs, rapport KPI mensuel.
                          </p>
                          <div class="hr"></div>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <CyberBtn link="/prix-referencement-sea" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <article>
            <section class="container mt-5 mb-3">
              <div class="article-title container-lg">
                <h3>
                  Boostez votre présence sur la première page google grâce à nos
                  services SEO/SEA
                </h3>
              </div>
              <div class="block-row">
                <div class="block-text col-lg-5 slide-left">
                  <TitleColor title="Développement Web" titleColor="#f27316" />
                  <p class="text-left mx-auto w-responsive">
                    Le <strong>référencement (SEO/SEA)</strong> joue un rôle
                    crucial dans la <strong>visibilité en ligne</strong>. Les
                    stratégies de <strong>SEO</strong> visent à optimiser votre
                    <strong>positionnement organique</strong> (<strong
                      >référencement gratuit</strong
                    >) sur les moteurs de recherche, tandis que le
                    <strong>SEA</strong> (<strong
                      >référencement payant google</strong
                    >) permet des
                    <strong>campagnes publicitaires</strong> ciblées et
                    efficaces.
                  </p>
                </div>
                <div class="col-lg-6">
                  <div
                    class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left"
                  >
                    <img
                      src="@/assets/pictures/markettingSpeaker.webp"
                      alt="graphic-economy-energy"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="container mb-5">
              <div class="block-row reverse">
                <div class="block-text slide-right col-lg-5">
                  <TitleColor
                    title="Développement Mobile"
                    titleColor="hsl(263, 47%, 58%)"
                    bgColor="hsl(263, 47%, 58%)"
                  />
                  <p>
                    En mettant en œuvre des pratiques avancées de
                    <strong>SEO</strong>, l'objectif est d'améliorer votre
                    visibilité organique. Quant au <strong>SEA</strong>, il
                    offre une visibilité immédiate grâce à des
                    <strong>annonces sponsorisées</strong>. Notre équipe
                    expérimentée utilise des analyses et des ajustements
                    continus pour vous aider à atteindre des résultats de
                    recherche plus élevés.
                  </p>
                </div>

                <div class="col-lg-6">
                  <div
                    class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left"
                  >
                    <img
                      src="@/assets/pictures/sea-strategy.webp"
                      alt="graphic-economy-energy"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </section>
          </article>
          <CallActionService />
        </div>
      </div>
    </div>
    <FooterOption btnBorderColor="cyan" iconColorClass="cyan" />
  </main>
</template>

<script>
import { useMeta } from "vue-meta";
import CyberBtn from "@/components/partials/CyberBtn.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";

export default {
  name: "AppMobile",
  components: { CyberBtn, CallActionService, FooterOption, NavOption },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Référencement web",
      description: [
        {
          content:
            "Optimisez votre présence en ligne avec Malaweb. Exploitez notre expertise en référencement SEO pour améliorer votre visibilité organique sur les moteurs de recherche, et notre savoir-faire en SEA pour des campagnes publicitaires percutantes. Obtenez des résultats mesurables et atteignez vos objectifs digitaux grâce à nos stratégies personnalisées en SEO et SEA.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/referencement-google",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
#appMob {
  background: linear-gradient(to left, #0083b0, #00b4db);
}
.header {
  text-align: center;
  line-height: 0.8;
  margin-bottom: 50px;
  margin-top: 0px;
  padding: 50px 15px 15px;
  & h2:first-of-type {
    font-weight: 200;
    color: $color-text;
  }
  & h2:last-of-type {
    color: $color-darker;
    font-size: 1.5rem;
    opacity: 88%;
  }
  @media (max-width: 400px) {
    h2 {
      font-size: 1.5rem;
    }
  }
}
.toggle-section {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: start;
  flex-wrap: wrap;
  //background: #00B4DB;
  background: linear-gradient(to left, #0083b0, #00b4db);

  & .toggle-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 337px) {
      flex-direction: row;
      justify-content: space-around;
    }
    & .avatar {
      margin: 10px 0px -10px 0px;
      & img {
        width: 40px;
        @media screen and (min-width: 992px) {
          width: 50px;
        }
      }
    }
    & .card {
      background: linear-gradient(
          to bottom,
          #323232 0%,
          #3f3f3f 40%,
          #1c1c1c 150%
        ),
        linear-gradient(
          to top,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(0, 0, 0, 0.25) 200%
        );
      background-blend-mode: multiply;
      padding: 5px;
      height: 100%;
      min-width: 140px;
      border: none;
      border-radius: 15px;
      @media screen and (min-width: 992px) {
        min-width: 160px;
      }
      /*&:hover {
        background-color: $color-secondary;
      }*/
      & .card-body {
        & .hr {
          margin: 2px auto 5px;
          width: 75%;
          height: 1px;
          background-color: $color-secondary;
        }
        & h4 {
          color: $bold-light;
          font-size: 0.9em;
          margin: auto;
          @media screen and (min-width: 992px) {
            font-size: 1em;
          }
        }
      }
    }
  }
  & .toggle-btn {
    background: linear-gradient(
        to bottom,
        #323232 0%,
        #3f3f3f 40%,
        #1c1c1c 150%
      ),
      linear-gradient(
        to top,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.25) 200%
      );
    background-blend-mode: multiply;
    border: 0.5px $color-darker outset;
    border-radius: 15px;
    cursor: pointer;
    transition: scale 0.3s;
    transition: box-shadow 0.2s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  & .toggle-btn:hover {
    color: #ffffff;
    border: 0.5px $color-darker outset;
  }

  & .toggle-btn.active {
    background-image: linear-gradient(-60deg, #1f1e1ecc 0%, #242121 100%);
    color: #f8f9fa;
    border: 0.5px $color-secondary inset;
    scale: (96%);
    box-shadow: none;
    & .card {
      & h4 {
        color: $color-primary;
      }
      & .hr {
        background-color: $color-lighter;
      }
    }
  }

  & .toggle-content {
    width: 100%;
    margin-top: 20px;
  }

  & .toggle-item {
    display: none;
  }

  & .toggle-item.active {
    display: block;
  }
}

.header-card {
  & h3 {
    width: 100%;
    margin: auto;
    background-color: $color-logo;
    color: $cyan;
    border: 0.2px outset $cyan;
    border-radius: 20px;
    padding: 4px 50px;
    margin-bottom: 20px;
  }

  & p {
    color: $color-text;
  }
}
.card {
  min-height: 100%;
  border-radius: 8px 8px 0px 0px;
}
.pricing-card {
  background: $background;
  &__title {
    background: $cyan;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $color-lighter;
      text-align: center;
      margin-bottom: 0px;
    }
  }
  &__second {
    background: $color-darker;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    & .option {
      color: $cyan;
      text-align: center;
      margin-bottom: 0px;
    }
  }
  & .card-body {
    padding: 1rem 0rem;

    & .head-body {
      display: flex;
      //justify-content: center;
      align-items: flex-start;
      /*background-color: $color-darker;
      border: 0.3px outset $color-primary;
      border-radius: 10px 0px 10px 0px;
      padding: 5px 50px;
      width: fit-content;*/
      &__orange {
        /*background-color: $color-primary;
        border: 0.3px outset $color-darker;
        border-radius: 0px 10px 0px 10px;
        padding: 5px 50px;
        width: fit-content;*/
        margin: auto;
      }
    }
    & .price {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      font-size: 2em;
      font-weight: 100;
      color: $color-secondary;
      margin: 0px;
    }
    & .euro {
      font-size: 0.6em;
      line-height: -50px;
      color: $color-secondary;
    }
    & .b-ttc {
      position: relative;
      bottom: 1px;
      color: $bold-light;
      & .ttc {
        font-size: 0.8em;
        margin-bottom: 0px;
        line-height: 55px;
        margin-left: -5px;
        & span {
          font-size: 1.3em;
        }
      }
    }
    & ul {
      list-style-type: none;
      & li {
        & p {
          font-size: 1em;
          margin-bottom: 0px;
          color: $bold-light;
          & i {
            padding: 5px;
            border-radius: 100%;
            margin-right: 10px;
          }
          & span {
            color: $color-secondary;
          }
          & .iOrange {
            color: $color-lighter;
            background-color: $color-primary;
          }
          & .iBlack {
            color: $color-primary;
            background-color: $color-darker;
          }
        }
        & .hr {
          width: 80%;
          height: 1px;
          background-color: $bold-light;
          margin: 10px auto;
        }
      }
    }
  }
  & .card-footer {
    background: $color-darker;
    border: none;
    padding: 15px 0px;
  }
}
article {
  & h3 {
    text-align: center;
    color: $color-text;
    text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
    margin: 130px 10px 50px;
    font-size: 2em;
  }
  & .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        width: 500px;
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
}
.breakword {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 6px;
  & p {
    font-size: 0.7em;
  }
}
</style>
