<template>
  <footer class="container-fluid footer-option">
    <button
      id="buttonScrollToTheTop"
      title="scroll to the top"
      :style="{ borderColor: btnBorderColor }"
      class="scroll-to-top-btn"
      @click="scrollToTop"
    >
      <i :class="['fa', 'fa-arrow-up', iconColorClass]"></i>
    </button>
  </footer>
</template>

<script>
export default {
  name: "FooterOption",
  props: {
    btnBorderColor: {
      type: String,
      default: "#f27316", // Default white circle background color
    },
    iconColorClass: {
      type: String,
      default: "orange", // Default box shadow for the circle
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.footer-option {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 10px;
  margin-top: -110px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  .scroll-to-top-btn {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    border: 0.1px outset;
    box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
    cursor: pointer;

    i {
      font-size: 1em;
    }
    & .cyan {
      color: hsl(180, 62%, 55%);
    }
    & .purple {
      color: hsl(263, 47%, 58%);
    }
    & .orange {
      color: $color-primary;
    }
    & .yellow {
      color: hsl(59, 91%, 65%);
    }
  }
}
</style>
